import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import {Link as RouteLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import {  toast,ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


import themeDataCustom from '../../Theme/ThemeDataCustom';
import { Backdrop, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { register, sendOtp } from '../../apis/Auth';
import PublicLayout from '../../layouts/PublicLayout';

const theme = createTheme(themeDataCustom());

export default function Register() {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);

  const handleSubmit = (event) => {

    event.preventDefault();
    setLoading(true);
    
    const data = new FormData(event.currentTarget);
    
    if(data.get('firstname')==""){
      toast.error("Firstname is required");
      setLoading(false);
      return false;
      
    }
    else if(data.get('lastname')==""){
      toast.error("Lastname is required");
      setLoading(false);
      return false;
    }
    else if(data.get('email')==""){
      toast.error("Email is required");
      setLoading(false);
      return false;
    
    }
   
    else if(data.get('password')==""){
      toast.error("Password is required");
      setLoading(false);
      return false;
    }
   
     
    
    else{
    
        var validRegex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!data.get('email').match(validRegex)) {
        toast.error("Email is not valid");
        setLoading(false);
        return false;
      }

      if(data.get('password').length<6){
        toast.error("Min 6 letters required in password");
        setLoading(false);
        return false;
        }

    
    }
   



    let company = data.get('company');
    let email = data.get('email');
    let firstname = data.get('firstname');
    let lastname = data.get('lastname');

    let data_api = {
      email: data.get('email'),
      password: data.get('password'),
      c_password: data.get('password'),

      name:data.get('firstname')+' '+data.get('lastname'),

      };

      register(data_api)
      .then((data)=>{
        setLoading(false);

        if(data.success){
     
          let data_api_ufield = {
            company: company,
            firstname: firstname,
            lastname:lastname,
            user_id: data.data.id
          };
          //console.log(data_api_ufield)
            
            localStorage.setItem("user",data.data);
            
              sendOtp(email)
              .then((data)=>{
                localStorage.clear();
                localStorage.setItem("verify_username",data.data);
              navigate('/verify');
              
            });

        }else{
          if(data.data.email){
          toast.error(data.data.email[0])
          }
        }
    })
      


      
  };

  return (
    <PublicLayout>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstname"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // required
                  fullWidth
                  name="company"
                  label="Company"
                  type="text"
                  id="company"
                  autoComplete="company"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouteLink}to="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
        </Backdrop>
      <ToastContainer/>
 
    </PublicLayout>
  );
}