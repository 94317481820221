import {base_url} from '../baseurl';




export function getOverviewCharts(data){
    

    return  fetch(base_url+'charts/orderchartoverview',{
        method:'POST',
        body:JSON.stringify(data),
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token"),
            
         //    "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(res=>res.json())
   
}