import {base_url} from './baseurl';



export  function getApiUserStatistics(id){
    
    return  fetch(base_url+'apiUserStatistics/'+id,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export  function getShippableTenPerPage(id,page=1){
    
    return  fetch(base_url+'getShippablePagePerTen/'+id+'/'+page,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}



export  function getApiUserSerial(id,page,search='',type){
    
    return  fetch(base_url+'apiUserSerial/'+id+'?page='+page+'&search='+search+'&type='+type,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export  function getCustomerBBDAPI(id,page){
    
    return  fetch(base_url+'customerBBDAPI/'+id+'?page='+page,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}




