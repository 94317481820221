import React from 'react'
import { Outlet , Navigate } from "react-router-dom";
import { getUserRole, isAdmin } from '../apis/Auth';

const AdminRoute = () =>{
    let auth = {'token':localStorage.getItem('token'),'role':getUserRole()};
    return !auth.token ||  !isAdmin() ? <Navigate to="/access-denied" /> : <Outlet/>;
            
      
    
}

export default AdminRoute;