import {  Backdrop, Button, CircularProgress, Fab, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../layouts/DashboardLayout';
import CronTable from '../components/tables/CronTable';
import { useParams,useNavigate } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { getCronlogs } from '../apis/Cronlog';
import CronLogTable from '../components/tables/CronLogTable';

const CronSingle = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const [lock,set_lock] = useState(true);
    const [logs,set_logs] = useState([]);
    const [logs_data,set_logs_data] = useState([]);
    const [page,setpage] = useState(1);


    useEffect(()=>{
        getCronlogs(params.id,1)
        .then((data)=>{
            if(data.success){
                set_logs(data.data.data);
                set_logs_data(data.data);
            }else{
                navigate('/');
            }
            setLoading(false);


        })
    },[])

    const handleChangePage=(event,value)=>{ 
       
        setLoading(true);
        set_logs([]);
        set_logs_data([]);


        setpage(value);
        
        getCronlogs(params.id,value)
        .then(data=>{
            set_logs(data.data.data);
            set_logs_data(data.data);
            setLoading(false);
        })
        
    }


    const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
      };


    const iconStyle = {
        color: '#fff'
      };
      



 
    return (
        <DashboardContent title={params.name}>
            <Paper elevation={2} >
                <Box p={2}>
                 <CronTable lock={lock} id={params.id}/>
                </Box>
              
            </Paper>
            <Paper elevation={2} >
            <Box p={2} mt={2}>
                    <CronLogTable lock={lock} handleChangePage={handleChangePage} logs={logs} logs_data={logs_data} />
                </Box>
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Fab style={fabStyle} size="medium" color="secondary" aria-label="add">
            <IconButton onClick={()=>{set_lock(!lock)}}>{lock==1?(<><LockIcon style={iconStyle}/></>):(<><LockOpenIcon style={iconStyle}/></>)}</IconButton>
            </Fab>
       
          
            
        </DashboardContent>
    );
}

export default CronSingle;