import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Pagination,
    TableContainer,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import VisibilityIcon from '@mui/icons-material/Visibility';
import {Box} from "@mui/system";
import {getUserRole} from "../../apis/Auth";

export default function LogsTable(props) {

    const [loading,setLoading] = useState(false);
    const [dialogData,setDialogData] = useState("");
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const logs = props.logs;
    const logs_data = props.logs_data;
    const page = props.page;
    const buetify=(data)=>{
     var b=JSON.stringify(data);
    return b.replace(/\\/g, '');
    }
    return (

        <React.Fragment >

            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>LOG</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogData}
                    </DialogContentText>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                        }}
                    >


                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
                <ToastContainer/>
            
            
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                LOGS 
            </Typography>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            {
                                getUserRole()==0&&(<TableCell>URL</TableCell>)
                            }

                            <TableCell>Ref ID 1</TableCell>
                            <TableCell>Ref ID 2</TableCell>
                            <TableCell>Response</TableCell>
                            <TableCell>Date Created</TableCell>

                            


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                {
                                    getUserRole()==0&&( <TableCell>{row.url}</TableCell>)
                                }

                                <TableCell>{row.ref_id_1 ?? 'NA'}</TableCell>
                                <TableCell>{row.ref_id_2?? 'NA'}</TableCell>
                                <TableCell>{
                                    buetify(row.response.length <= 100?row.response:row.response.substring(1,100)+"...")}
                                    {row.response.length > 200 &&
                                        (<IconButton onClick={() => {
                                            setDialogData(buetify(row.response))
                                            handleClickOpen();
                                        }
                                        }><VisibilityIcon/></IconButton>)
                                    }
                                </TableCell>
                                <TableCell>{moment(row.created_at).format("DD/MM/YYYY hh:mm:ss a")}</TableCell>
                                
                              


                            </TableRow>
                        ))}
                      
                    </TableBody>
                    
                </Table>
                <Grid
                mt={3}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center" > 
                <Grid item >
                <Pagination
                  count= {logs_data.last_page}
                  page={page}
                  onChange={(event, pageNumber) => props.handleChangePage(event, pageNumber)}

                 color="primary" />
                </Grid>
                </Grid>
            </TableContainer>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}