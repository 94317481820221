import {base_url} from './baseurl';



export function getCronTypes(){
    
    
    return fetch(base_url+'crontypes',{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}