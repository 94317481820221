import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import { Backdrop, CircularProgress, Grid, IconButton, Pagination, TableContainer, Typography } from '@mui/material';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';


export default function CronLogTable(props) {

    const [loading,setLoading] = useState(false);
    
    
    const logs = props.logs;
    const logs_data = props.logs_data;
    const page = props.page;
    const buetify=(data)=>{
     var b=JSON.stringify(data);
    return b.replace(/\\/g, '');
    }

    


    return (

        <React.Fragment >
                <ToastContainer/>
            
            
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                CRON LOGS 
            </Typography>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>CRON</TableCell>
                            <TableCell>START</TableCell>
                            <TableCell>END</TableCell>
                            <TableCell>DURATION</TableCell>
                            <TableCell>LABEL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs.map((row) => {
                            var startDate = moment(row.created_at).format("DD-MM-YYYY hh:mm:ss");
                            var endDate = moment(row.updated_at).format("DD-MM-YYYY hh:mm:ss");
                            // var hoursDiff = endTime.diff(startTime, 'hours');
                            console.log(startTime);
                            // var minutesDiff = endTime.diff(startTime, 'minutes');
                            // var secondsDiff = endTime.diff(startTime, 'seconds');
                            var startTime = moment(moment(row.created_at).format("DD-MM-YYYY hh:mm:ss"), 'DD-MM-YYYY HH:mm:ss a');
                            var endTime = moment(moment(row.updated_at).format("DD-MM-YYYY hh:mm:ss"), 'DD-MM-YYYY HH:mm:ss a');
                            var duration = moment.duration(endTime.diff(startTime));
                            var hours = parseInt(duration.asHours());
                            var minutes = parseInt(duration.asMinutes()) % 60;
                            var seconds = parseInt(duration.asSeconds()) % 60;
                            var durations = "";
                            if(hours){
                                durations = durations+hours+"h ";
                            }
                            if(minutes){
                                durations = durations+minutes+"m ";
                            }
                            if(seconds){
                                durations = durations+seconds+"s ";
                            }
                            if(durations==""){
                                durations = '0';
                            }

                            return(
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.cron_name}</TableCell>
                                <TableCell>{startDate}</TableCell>
                                <TableCell>{endDate}</TableCell>
                                <TableCell>{durations}</TableCell>
                                <TableCell>{row.label}</TableCell>




                            </TableRow>
                        )})}
                      
                    </TableBody>
                    
                </Table>
                <Grid
                mt={3}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center" > 
                <Grid item >
                <Pagination
                  count= {logs_data.last_page}
                  page={page}
                  onChange={(event, pageNumber) => props.handleChangePage(event, pageNumber)}

                 color="primary" />
                </Grid>
                </Grid>
            </TableContainer>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}