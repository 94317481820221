import React, { useState } from "react";
import DashboardContent from "../layouts/DashboardLayout";
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import HubIcon from '@mui/icons-material/Hub';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteCompany } from "../apis/Company";
import { ToastContainer, toast } from 'react-toastify';



export default function CompanySetting (props){
    
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const params = useParams();
    return(
        <DashboardContent title="Company Settings">
                <ToastContainer/>
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={3} sm={3} md={3}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea onClick={(e)=>navigate('/company/edit/'+params.id)}>
                    
                            <CardContent>
                            <SettingsIcon color="success" fontSize="large"/>
                            <Typography gutterBottom variant="h5" component="div">
                                Settings
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Configure pakaneo settings, erp settings.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea onClick={(e)=>navigate('/products/'+params.id)}>
                    
                            <CardContent>
                            <HubIcon color="info" fontSize="large"/>
                            <Typography gutterBottom variant="h5" component="div">
                                Products & Inventory
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Search and see products from pakaneo.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                
                <Grid item xs={3} sm={3} md={3}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea onClick={(e)=>navigate('/orderssingle/'+params.id)}>
                    
                            <CardContent>
                            <Inventory2Icon color="secondary" fontSize="large"/>
                            <Typography gutterBottom variant="h5" component="div">
                                Orders
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Search and see products from pakaneo.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                    <Card sx={{ maxWidth: 345 }} onClick={(e)=>navigate('/logssingle/'+params.id)}>
                        <CardActionArea>
                    
                            <CardContent>
                            <SyncAltIcon color="error" fontSize="large"/>
                            <Typography gutterBottom variant="h5" component="div">
                                Logs
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                See the logs.<br/>
                               (apis to PAKANEO).
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={3} sm={3} md={3}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea onClick={(e)=>navigate('/company/false-orders/'+params.id)}>
                    
                            <CardContent>
                            <SmsFailedIcon color="error" fontSize="large"/>
                            <Typography gutterBottom variant="h5" component="div">
                                False Orders
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                To see the false order.<br/>
                               (from ERP).
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea onClick={(e)=>navigate('/erplogs/'+params.id)}>
                    
                            <CardContent>
                            <ErrorIcon color="error" fontSize="large"/>
                            <Typography gutterBottom variant="h5" component="div">
                                ERP Logs
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                To see the errors from ERP.<br/>
                               (from ERP).
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea onClick={()=>{
                                        let confirm_delete  =  window.confirm("Do you want to delete this record \n !! It will delete all orders and records from syncroneo for permanent !!");
                                        if(confirm_delete){
                                            setLoading(true);
                                            deleteCompany(params.id)
                                            .then((data)=>{
                                                setLoading(false);
                                                if(data.success){
                                                    window.location.href = "/company";
                                                }else{
                                                    toast.error(data.data.message);
                                                }
                                            })
                                        }
                                    }}>
                    
                            <CardContent>
                            <DeleteIcon color="error" fontSize="large"/>
                            <Typography gutterBottom variant="h5" color="error" component="div">
                                Delete Company
                            </Typography>
                            <Typography variant="body2" color="error">
                                Deletes all record orders for permanent<br/>
                               (Think Twice)
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </DashboardContent>
    )
}