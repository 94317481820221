import { Backdrop, Button, Card, CardActionArea, CardContent, CardMedia, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {React,useEffect,useState} from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { useNavigate } from 'react-router-dom';

import {  toast } from 'react-toastify';
import { saveCompany } from '../../apis/Company';
import { getSources } from '../../apis/Sources';
import billbee from '../../assets/images/sources/billbee.png';
import odoo from '../../assets/images/sources/odoo.png';
import json from '../../assets/images/sources/json.png';
import wh1 from '../../assets/images/sources/wh1+.png';
import xentral from '../../assets/images/sources/xentral.png';
import hamburger from '../../assets/images/sources/hamburger.png';
import linnworks from '../../assets/images/sources/linnworks.png';
import shopify from '../../assets/images/sources/shopify.jpeg';









const NewCompany = () => {
    const navigate = useNavigate();


    const [loading,setLoading] = useState(false);

    

    const [errors,setErrors] = useState([]);






    const [name,set_name] = useState('');
    const [url,set_url] = useState('');
    const [token,set_token] = useState("");
    const [source_id,set_source_id] = useState("");
    const [sources,set_sources] = useState("");








    const handleChange=(event)=>{
        event.preventDefault();
   
        switch(event.target.name){
            case "name":
                set_name(event.target.value);
                break;
            case "source_id":
                set_source_id(event.target.value);
                break;
            default: break;
        }
    }
    
    
    const handleSubmit = (event)=>{
        
        setLoading(true);
      
       let data = {
            name:name,
            source_id:source_id
        }

        saveCompany(data)
        .then((data)=>{
            
        setLoading(false);
        if(data.success){
            toast.success(data.message);
            navigate("/company/edit/"+data.data.id)
            
        }else{
            
            if(data.data!=undefined){
            setErrors(data.data);
            
            }else{
                toast.error(data.message);
            }

        }
    })

        
    }

    useEffect(()=>{
            getSources()
            .then(data=>{
                set_sources(data.data);
            })
    },[])

    return (
        <DashboardContent title="Company">


            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper elevation={2} >
                        <Box p={2}>
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={4}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                New Company
                            </Typography>

                         
                           

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>

                             
                                    <FormControl fullWidth>

                                    <TextField  required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name} 
                                        error={errors.name!=undefined?true:false}
                                        helperText={errors.name!==undefined?errors.name:''}
                                        />

                                    </FormControl>
                                   
                                    <Box mb={2} />
                                    <FormControl fullWidth>
                                
                                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }} alignItems={'center'}>

                                      {
                                            sources && sources.map((item)=>{
                                                let logo = '';
                                                if(item.code == "XENTRAL"){
                                                    logo = xentral;
                                                }
                                                else if(item.code =="ODOO"){
                                                    logo = odoo;
                                                }
                                                else if(item.code =="BILLBEE"){
                                                    logo = billbee;
                                                }
                                                else if(item.code =="JSON"){
                                                    logo = json;
                                                }
                                                else if(item.code =="HAMBURGER"){
                                                    logo = hamburger;
                                                }
                                                else if(item.code =="WH1"){
                                                    logo = wh1;
                                                }
                                                else if(item.code =="LINWORKS"){
                                                    logo = linnworks;
                                                }
                                                else if(item.code =="SHOPIFY"){
                                                    logo = shopify;
                                                }
                                                
                                                return (
                                                <Grid item xs={2} sm={2} md={2}>

                                                    <Card sx={{ maxWidth: 50 }} className={source_id==item.id?'selected source_select':'source_select'} onClick={(e)=>set_source_id(item.id)}>
                                                    <CardActionArea>
                                                      <CardMedia
                                                        component="img"
                                                        height="140"
                                                        image={logo}
                                                        alt="green iguana"
                                                        className='card-company-source-logo'
                                                      />
                                                     
                                                    </CardActionArea>
                                                  </Card>
                                                  </Grid>
                                                )
                                            })
                                        }
                                        </Grid>
                                        {errors.source_id!==undefined?(
                                            <p class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-1wc848c-MuiFormHelperText-root" id=":rt:-helper-text">{errors.source_id!==undefined?"Source is required":''}</p>
                                         ):''}
                                    </FormControl>
                                    <Box mb={2} />
                                

                                </Grid>
                             
                                </Grid>
                           
                            
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>
                                <FormControl >
                                            <Button variant="outlined"   onClick={handleSubmit} >Save Company</Button>
                                </FormControl>
                                </Grid>
                            </Grid>





                                   

                    

                        </Box>
                    </Paper>
                </Grid>

            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </DashboardContent>
    );
}

export default NewCompany;
