import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Pagination,
    TableContainer,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import { getShippableTenPerPage } from '../../apis/ApiUserStaistics';


export default function ShippableTable(props) {


    const [loading,setLoading] = useState(false);

    const [shippables,set_shippables] = useState([]);
    const [shippables_product,set_shippables_product] = useState([]);

    const[shippables_data,set_shippables_data] = useState([]);
    const [page,setPage] = useState(1);

    useEffect(()=>{
        getShippableTenPerPage(props.company_id,page)
        .then(data=>{
            set_shippables(data.data.data);
            set_shippables_product(data.data.data.products);
            
        })
    },[])

    const handleChangePage=(event,value)=>{
       
        setLoading(true);
        set_shippables([]);
        set_shippables_product([]);

        setPage(value);
      
        //console.log(filter);

        getShippableTenPerPage(props.company_id,value)
        .then(data=>{
            set_shippables(data.data.data);
            set_shippables_product(data.data.data.products);
          setLoading(false);
            
        })
        
    }
    
   
    return (

        <React.Fragment >

           
                <ToastContainer/>
            
            
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                Stock List 
            </Typography>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>

                            <TableCell>EAN</TableCell>
                            <TableCell>Identifier 1</TableCell>
                            <TableCell>Identifier 2</TableCell>
                            <TableCell>Identifier 3</TableCell>
                            <TableCell>Identifier 4</TableCell>
                            <TableCell>Fulfillable</TableCell>

                            


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { shippables_product.map((row) => {
                            return (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                               

                                <TableCell>{row.ean ?? 'NA'}</TableCell>
                                <TableCell>{row.identifier1 ?? 'NA'}</TableCell>
                                <TableCell>{row.identifier2 ?? 'NA'}</TableCell>
                                <TableCell>{row.identifier3 ?? 'NA'}</TableCell>
                                <TableCell>{row.identifier4 ?? 'NA'}</TableCell>

                                <TableCell>{row.fulfillable?? 'NA'}</TableCell>
                               

                            </TableRow>
                        )})}
                      
                    </TableBody>
                    
                </Table>
                <Grid
                mt={3}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center" > 
                <Grid item >
                <Pagination
                  count= {shippables.pages}
                  page={page}
                  onChange={(event, pageNumber) => handleChangePage(event, pageNumber)}

                 color="primary" />
                </Grid>
                </Grid>
            </TableContainer>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}