import {base_url} from '../baseurl';




export function getCarrierChart(company_id,days){
    

    return  fetch(base_url+'chart/carrier_chart/'+company_id+'/'+days,{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token"),
            
         //    "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(res=>res.json())
   
}

export function getSellerPlatformChart(company_id,days){
    

    return  fetch(base_url+'chart/seller_platform_chart/'+company_id+'/'+days,{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token"),
            
         //    "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(res=>res.json())
   
}

export function getAnalytice(){
    
    return  fetch(base_url+'chart/analytics_data/',{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token"),
            
         //    "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(res=>res.json())

}
