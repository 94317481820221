import {  Backdrop, Button, Card, CardActions, CardContent, CircularProgress, Fab, FormControl, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { getCompanies } from '../../apis/Company';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { getSearchedProduct } from '../../apis/ProductApi';
import { getCompanyID, getUserRole } from '../../apis/Auth';
import AppWidgetSummary from '../../components/Shared/AppWidgetSummary';
import { getApiUserStatistics, getShippableTenPerPage } from '../../apis/ApiUserStaistics';
import ShippableTable from '../../components/tables/ShippablesTable';

const Products = (props) => {

    const params = useParams();
    const [products,set_products] = useState([]);
    const [search,set_search] = useState("");
    const [products_count,set_products_count]=useState(0);
    const [stock_count,set_stock_count]=useState(0);
    const [volume_count,set_volume_count]=useState(0);
    const [article_count,set_article_count]=useState(0);

    const [loading,setLoading] = useState(false);


    useEffect(()=>{
        if(getUserRole() ==1){
            params.id = getCompanyID();
        }

        
    
        getApiUserStatistics(params.id)
        .then(data=>{
            data = data.data;
            if(data.products){
                set_products_count(data.products);
            }

            if(data.stock){
                set_stock_count(data.stock);
            }

            if(data.stored_volume){
                set_volume_count(data.stored_volume);
            }

            if(data.stored_articles){
                set_article_count(data.stored_articles);
            }
            
        })

    },[])
    const handleSearch=(event,value)=>{
       
        setLoading(true);
        set_products([]);

        if(getUserRole() ==1){
            params.id = getCompanyID();
        }
        getSearchedProduct(params.id,search)
        .then(data=>{
            //console.log(data.data);
            console.log(data);
            if(data.success){
            set_products(data.data);
            }else{
                set_products([]);
            }
            setLoading(false);
        })
        
    }

    const handleChange =(e)=>{
        set_search(e.target.value);
    }
    const navigate=  useNavigate();
    
 
    return (
        <DashboardContent title="Your Products">
                    <Grid container spacing={2} mb={3}>
                        <Grid item xs={3} md={3}>
                            <AppWidgetSummary title="Products" total={products_count} icon={'material-symbols:order-approve-outline-sharp'} />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <AppWidgetSummary title="Stock" total={stock_count} color="info" icon={'nimbus:box-unpacked'} />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <AppWidgetSummary title="Volume Yesteday" total={volume_count} color="warning" icon={'mdi:company'} />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <AppWidgetSummary title="Stored Articles Last Month" total={article_count} color="error" icon={'ant-design:bug-filled'} />
                        </Grid>

                    </Grid>
                <Box p={2} className="company-tbl">
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }} alignItems="center">
                        <Grid item xs={11} sm={11} md={11}>

                            <FormControl fullWidth mt={3}>

                            <TextField  required label="Search Product By Name / Identifiers / EAN" variant="outlined" InputLabelProps={{ shrink: true }} name="search" onChange={handleChange} value={search}
                            />

                            </FormControl>
                            </Grid>

                        <Grid item xs={1} sm={1} md={1}>
            
                            <Button  variant="contained"  onClick={handleSearch}>Search</Button>
                        </Grid>
                        </Grid>
                </Box>
             

                <Grid container rowSpacing={4} mt={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }} mb={2} >
                    {
                        products && products.map(item=>{
                            return(
                                <Grid item xs={12} sm={6} md={3} style={{display: 'flex'}}>
                                 <Card sx={{ minWidth: 275 }} p={2} style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {item.ean}
                                        </Typography>
                                        <Typography variant="h5" component="div">
                                        {item.name}
                                        </Typography>
                                      
                                        <Typography variant="p">
                                        Dimensions : W{item.width} X L{item.length} X H{item.height}
                                        <br />
                                        Weight : {item.weight} g
                                        <br />
                                        FIFO : {item.fifo}   |   Serial : {item.serial}
                                        <br />
                                        COO : {item.coo ? item.coo:'NA'}
                                        <br />
                                        Orderable : {item.orderable ? item.orderable:'NA'}

                                      
                                    

                                        
                                        </Typography>
                                    </CardContent>
                                    {/* <CardActions>
                                        <Button size="small">Learn More</Button>
                                    </CardActions> */}
                                    </Card>
                            </Grid>
        
                            )
                        })
                    }
                   
                </Grid>
                
                <ShippableTable company_id={params.id}  />

               
                
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
          
        </DashboardContent>
    );
}

export default Products;