import { Backdrop, Button, CircularProgress, FormControl, FormHelperText, Grid, Link, Paper, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React,{useEffect, useState} from 'react';
import { getUser, updateAvatar,getUserAvatar, updateUser, getWarehouseImage, updateWarehouseImage, getUserRole, saveUserWebUrl } from '../../apis/Auth';
import { useNavigate } from 'react-router-dom';
import DashboardContent from '../../layouts/DashboardLayout';
import {  toast,ToastContainer } from 'react-toastify';

const Account = () => {
    
    const navigate = useNavigate();
    const [email,setEmail] = useState("");
    const [error,setError] = useState([]);
    const [errors,setErrors] = useState([]);

  


    const [new_password,set_new_password] = useState("");
    const [confirm_new_password,set_confirm_new_password] = useState("");

    const [avatar,set_avatar] = useState({});
    const [warehouse_image,set_warehouse_image] = useState({});



     

    const [loading,setLoading] = useState(true);
    const [url_name,set_url_name] = useState("");
    const [url,set_url] = useState("");
    
    const handleChange=(event)=>{
        event.preventDefault();
   
        switch(event.target.name){
            case "email":
                setEmail(event.target.value);
                break;
            case "new_password":
                set_new_password(event.target.value);
                break;
            case "confirm_new_password":
                set_confirm_new_password(event.target.value);   
                break;
            case "avatar":
                set_avatar(event.target.files[0]);
                // //console.log(avatar);
                break;
            case "warehouse_logo":
                set_warehouse_image(event.target.files[0]);
                break;
            case "url":
                set_url(event.target.value);
                break;
            case "url_name":
                set_url_name(event.target.value);
                set_url("https://"+event.target.value+".pakaneo.com")
                break;
            default:break;

        }
    }

  
    useEffect(()=>{
        getUser()
        .then((data)=>{
            setLoading(false);
            if(data.success){

                set_url(data.data.url);
                set_url_name(data.data.url_name);
                setEmail(data.data.email);

            }else{
                localStorage.clear();
                navigate('/')
            }
        })
       
    },[])
    const handleSubmit=(event)=>{
        event.preventDefault();
        if(confirm_new_password !== new_password){
            toast.error("password and password confirmation doesn't match!")
            return false;
        }

        let password_new = "";
        if(confirm_new_password !="" || new_password!=""){
             password_new = confirm_new_password;
        }


    
        if(email==""){
          toast.error("Email is required");
          setLoading(false);
          return false;
        }
     
        else if(email){
          var validRegex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
          if (!email.match(validRegex)) {
            toast.error("Email is not valid");
            setLoading(false);
            return false;
          }
        }
        
     

        let data={
            new_password : password_new,
            email : email
        }
        setLoading(true)
        updateUser(data)
        .then((data)=>{
        setLoading(true)

            setLoading(false);
            if(data.success){
                if(data.data.no_changes!= undefined && data.data.no_changes){
                    toast.success('No changes found!')
                }else{
                localStorage.clear();
                localStorage.setItem("verify_username",email);
                navigate('/verify');
                }
            }else{
                if(data.data.email!=undefined){
                toast.error(data.data.email[0])
                }else{
                    toast.error(data.message)
                }

            }
        })



        
    }

    const handleSubmitAvatar = (event)=>{
        
        setLoading(true);
        setErrors("");
      
  
        var data = new FormData();
        data.append('avatar', avatar)


        updateAvatar(data)
        .then((data)=>{
            
        setLoading(false);
        if(data.success){
            toast.success(data.message);
            localStorage.setItem("avatar",data.data);
    

         
        }else{
            
            if(data.data!=undefined){
             setErrors(data.data);
            
            }else{
                toast.error(data.message);
            }

        }
    })

        
    }

    const handleUpdateWarehouseImage = (event)=>{
        
        setLoading(true);
        setErrors("");
      
  
        var payload = new FormData();
        payload.append('warehouse_image', warehouse_image)


        updateWarehouseImage(payload)
        .then((data)=>{
            
        setLoading(false);

        if(data.success){
            toast.success(data.message);
            localStorage.setItem("warehouse_image",data.data);
    

         
        }else{
            
            if(data.data!=undefined){
             setErrors(data.data);
            
            }else{
                toast.error(data.message);
            }

        }
    })

        
    }

    const handleSubmitWarehouseURL =()=>{
        let data ={
            url : url,
            url_name:url_name
        }
        saveUserWebUrl(data)
        .then(res=>{
            //console.log(res.json())
        })
    }

    return (
        <DashboardContent title="Your Account">

        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
        </Backdrop>
        <ToastContainer/>
            <Grid  container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={12} sm={12} md={6}>
                    <Paper elevation={2} >
                        <Box p={2} className="profile-block">
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={4}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Account Credentials
                            </Typography>
                            <FormControl fullWidth >

                                <TextField label="Email" type="email" variant="outlined" onChange={handleChange} value={email} name="email" InputLabelProps={{ shrink: true }} />

                            </FormControl>
                            <Box mb={2} />

                            <FormControl fullWidth>

                                <TextField label="Password" type="password" name="new_password" value={new_password} variant="outlined" onChange={handleChange} InputLabelProps={{ shrink: true }} />

                            </FormControl>
                            <Box mb={2} />

                            <FormControl fullWidth>

                                <TextField label="Password Confirmation" name="confirm_new_password" onChange={handleChange} value={confirm_new_password} type="password" variant="outlined" InputLabelProps={{ shrink: true }} />

                            </FormControl>
                            <Box mb={2} />

                            <FormControl >

                                <Button onClick={handleSubmit} variant="contained">Update</Button>

                            </FormControl>

                        </Box>
                    </Paper>
                </Grid>
{ getUserRole() == 0 && (
                <Grid item xs={12} sm={12} md={6}>
                    <Paper elevation={2} >
                        <Box p={2}>
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={1}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Warehouse Information
                            </Typography>

                         
                           

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>

                             
                              
                                    <Box mb={2} />
                                    <img src={getWarehouseImage()} alt="No Image"/>
                                    <Box mb={2} />

                                    <FormControl>
                                        <Button variant="contained"  onChange={handleChange} component="label">
                                            Upload
                                        <input hidden accept="image/*"  name="warehouse_logo" type="file" />
                                        </Button>
                                    </FormControl>
                                    <FormHelperText  error={true}>{errors.warehouse_image!==undefined?errors.warehouse_image:''}</FormHelperText>

                                   



                                    
                                   
                                    
                                

                                </Grid>
                             
                                </Grid>
                           
                            
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>
                                <FormControl >
                                            <Button variant="outlined"   onClick={handleUpdateWarehouseImage} >Save Warehouse Logo</Button>
                                </FormControl>
                                </Grid>
                            </Grid>


                            <Box mb={2} />

                            <Typography
                            component="h2"
                            variant="h6"
                            color="inherit"
                            mb={1}
                            noWrap
                            sx={{ flexGrow: 1 }}
                            >
                                Warehouse URL
                            </Typography>
                            <Box mb={2} />

                            <FormControl fullWidth>

                                <TextField label="URL Name" name="url_name" type="text" value={url_name} onChange={handleChange} variant="outlined"  InputLabelProps={{ shrink: true }} />

                            </FormControl>
                            <Box mb={2} />
                            <FormControl fullWidth>

                            <TextField label="URL" name="url"  disabled type="text" value={url} onChange={handleChange} variant="outlined"  InputLabelProps={{ shrink: true }} />

                            </FormControl>
                            <Box mb={2} />

                            <FormControl >

                                <Button onClick={handleSubmitWarehouseURL} variant="contained">Update</Button>

                            </FormControl>




                                   

                    

                        </Box>
                    </Paper>
                </Grid>
)
}               
                <Grid item xs={12} sm={12} md={6}>
                    <Paper elevation={2} >
                        <Box p={2}>
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={1}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Avatar
                            </Typography>

                         
                           

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>

                             
                              
                                    <Box mb={2} />
                                    <img src={getUserAvatar()} alt="No Image"/>
                                    <Box mb={2} />
                                    <FormControl>
                                        <Button variant="contained"  onChange={handleChange} component="label">
                                            Upload
                                        <input hidden accept="image/*"  name="avatar" type="file" />
                                        </Button>
                                    </FormControl>
                                    <FormHelperText  error={true}>{errors.file!==undefined?errors.file:''}</FormHelperText>

                                    <Box mb={2} />
                                    
                                   
                                    
                                

                                </Grid>
                             
                                </Grid>
                           
                            
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>
                                <FormControl >
                                            <Button variant="outlined"   onClick={handleSubmitAvatar} >Save Avatar</Button>
                                </FormControl>
                                </Grid>
                            </Grid>





                                   

                    

                        </Box>
                    </Paper>
                </Grid>
             
            </Grid>


        </DashboardContent>
    );
}

export default Account;