import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { getLastInvoice } from './apis/invoice';
import { useState } from 'react';
import { Card, CardHeader, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Link } from 'react-router-dom';


export default function InvoiceTable(props) {
    const [invoices,set_invoices] = useState([]);
    const [loading,set_loading] = useState(true);
    let total = 0;
    

    useEffect(()=>{
      getLastInvoice(props.company)
        .then(data=>{
            if(data.success){
            set_invoices(data.data.data);
            set_loading(false);
            }
        })

    },[])
  return (
    !loading && (
   
      <>
        {
          invoices && invoices.map(invoice=>{
            return (
              <Card>
    
              <CardHeader title={"Last Invoice"}   subheader={"Invoice: "+invoice.id+" | Alias: "+invoice?.alias +" | Status: "+invoice?.status}/>
              
              <CardHeader    subheader={invoice.from_date+"-"+invoice.to_date}/>
          
          
              <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell >Name</TableCell>
            <TableCell >Price</TableCell>
            <TableCell >Quantity</TableCell>
            <TableCell >Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoice.invoice_list.map((row,index) => (
        
          (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell >{index+1}</TableCell>

              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{Number(row.price).toFixed(2)}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>{Number(row.price*row.quantity).toFixed(2)}</TableCell>
            </TableRow>
          )
          ))}
           <TableRow
                         sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell ></TableCell>

              <TableCell component="th" scope="row">
             
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{invoice.total}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell >File Title</TableCell>
            <TableCell >Download</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {invoice.files.map((row,index) => (
        
          (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell >{index+1}</TableCell>

              <TableCell component="th" scope="row">
                {row.title}
              </TableCell>
              
              <TableCell>     
                <IconButton  target="__blank" download  href={row.path_name}>

                                        <DownloadIcon color="primary"/>
                                    </IconButton>
                                    
              </TableCell>
            </TableRow>
          )
          ))}
          
        </TableBody>
      </Table>
    </TableContainer>
    </Card>
            )
          })
        }
        </>
      
    )
  );
}