import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import { useEffect } from 'react';
import { getOverviewCharts } from '../apis/charts/OverviewChart';
import { Backdrop, CircularProgress } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const OverviewChart=(props)=>{

  const [chart, setChart] = useState({})
  const [loading,setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    let data = {
      days: props.parameter
    }

    getOverviewCharts(data)
    .then(data=>{
      //console.log(data.data);
        setChart(data.data);
        setLoading(false);
    })
  }, [])
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' ,
          },
          title: {
            display: true,
            text: '',
          },
        },
      };
      
      const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

       const data = {
        labels:chart?.total_orders?.labels,
        datasets: [
          {
            label: 'Total Orders',
            data: chart?.total_orders?.data,
            borderColor: 'rgba(147, 153, 66, 1)',
            backgroundColor: 'rgba(147, 153, 66, 0.5)',
          },
          {
            label: 'Shippable Orders',
            data: chart?.total_order_shippable?.data,
            borderColor: 'rgba(215, 65, 65, 1)',
            backgroundColor: 'rgba(215, 65, 65, 0.5)',
          },
        ],
      };
      return (
        <>     
        <div className="relative">
          <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => { return; }}
      >
          <CircularProgress color="inherit" />
        </Backdrop>
      <Line options={options} data={data} />
      </div>
      </>

      )
}

export default OverviewChart;
