import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Dashboard from  './Pages/home/Dashboard';
import Company from './Pages/company/Company';
import NewCompany from './Pages/company/NewCompany';
import Account from './Pages/account/Account';

import Login from './Pages/auth/Login';
import Forget from './Pages/auth/Forget';
import ResetPassword from './Pages/auth/ResetPassword';
import Register from './Pages/auth/Register';
import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';

import Verify from './Pages/auth/Verify';
import EditCompany from './Pages/company/EditCompany';
import StoreXml from './Pages/company/StoreXml';

import Xmls from './Pages/company/Xmls';
import Logs from './Pages/logs/Log';
import Orders from './Pages/order/Orders';
import FalseOrders from './Pages/company/FalseOrder'; 
import EditCompanyClient from './Pages/company/EditCompanyClient';
import WarehouseRoute from './utils/WarehouseRoute';
import AccessDenied from './Pages/access-denied/AccessDenied';
import PageNotFound from './Pages/404/PageNotFound';
import AdminRoute from './utils/AdminRoute';
import WarehouseUser from './Pages/warehouse/WarehouseUser';

import { Joyride } from "./utils/Joyride";
import React, {useState} from "react";
import Json from './Pages/company/Json';
import StoreJson from './Pages/company/StoreJson';
import ErpLogTable from './Pages/ErpLogTable';
import Products from './Pages/company/Products';
import CompanySetting from './Pages/CompanySetting';
import OrdersSingleCompany from './Pages/order/OrdersSingleCompany';
import LogsSingleCompany from './Pages/order/LogsSingleCompany';
import CronSingle from './Pages/CronSingle';
import CronLogSingle from './Pages/CronLogSingle';
import APIAccess from './Pages/APIAccess';
import CreateOrder from './Pages/order/CreateOrder';
import DirectLogin from './Pages/auth/DirectLogin';
import SerialTable from './components/tables/SerialTable';
import BBDTable from './components/tables/BBDTable';

function App() {
  const [run, setRun] = useState(true);
  const [stepIndexState, setStepIndexState] = useState(0);

  const CustomTooltip = ({
                           index,
                           size,
                           step,
                           skipProps,
                           primaryProps,
                           tooltipProps,
                           isLastStep
                         }) => (
      <div {...tooltipProps} style={{ background: "blue", padding: "20px" }}>
        <button {...skipProps}>Skip Tour</button>
        <button {...primaryProps}>{isLastStep ? "Close" : "Next"}</button>
      </div>
  );

  return (
    <Router>
      {/* <Joyride
      run={run}
      setRun={setRun}
      stepIndexState={stepIndexState}
      setStepIndexState={setStepIndexState}
      /> */}
        <Routes>


         
        <Route path="/verify" element={<PublicRoute/>}>
            <Route  path="/verify" element={<Verify/>}/>
          </Route>


          <Route  path="/auth-direct/email/:email/password/:password" element={<DirectLogin/>}/>

          <Route path="/login" element={<PublicRoute/>}>
            <Route  path="/login" element={<Login/>}/>
          </Route>

          <Route path="/register" element={<PublicRoute/>}>
            <Route  path="/register" element={<Register/>}/>  
          </Route>

          
          <Route path="/forget" element={<PublicRoute/>}>
            <Route  path="/forget" element={<Forget/>}/>      
          </Route>

          <Route path="/reset" element={<PublicRoute/>}>
            <Route  path="/reset" element={<ResetPassword/>}/>      
          </Route>

     




          <Route path="/warehouse-users" element={<AdminRoute/>}>
            <Route  path="/warehouse-users" element={<WarehouseUser/>}/>      
          </Route>

         
           

      
            <Route  path="/access-denied" element={<AccessDenied/>}/>      
            {/* <Route  path="*" element={<PageNotFound/>}/>       */}

         

          

          <Route path="/" element={<PrivateRoute/>}>
            <Route  path="/" element={<Dashboard/>}/> 
          </Route>   
          
         

          <Route path="/account" element={<PrivateRoute/>}>
            <Route  path="/account" element={<Account/>}/>    
          </Route>


          <Route path="/orders" element={<PrivateRoute/>}>
            <Route  path="/orders" element={<Orders/>}/>    
          </Route>


          
          <Route path="/erplogs/:id" element={<PrivateRoute/>}>
            <Route  path="/erplogs/:id" element={<ErpLogTable/>}/>    
          </Route>
          
          
        

          <Route path="/company" element={<WarehouseRoute/>}>
            <Route  path="/company" element={<Company/>}/>    
          </Route>

        

          <Route path="/company/add" element={<WarehouseRoute/>}>
            <Route  path="/company/add" element={<NewCompany/>}/>    
          </Route>

        
          <Route path="/company/edit/:id" element={<WarehouseRoute/>}>
            <Route  path="/company/edit/:id" element={<EditCompany/>}/>    
          </Route>


          <Route path="/orderssingle/:id" element={<WarehouseRoute/>}>
            <Route  path="/orderssingle/:id" element={<OrdersSingleCompany/>}/>    
          </Route>

          <Route path="/orderssingle/add/:id" element={<PrivateRoute/>}>
            <Route  path="/orderssingle/add/:id" element={<CreateOrder/>}/>    
          </Route>

          <Route path="/logssingle/:id" element={<WarehouseRoute/>}>
            <Route  path="/logssingle/:id" element={<LogsSingleCompany/>}/>    
          </Route>

          <Route path="/company/xml/cp/:id" element={<PrivateRoute/>}>
            <Route  path="/company/xml/cp/:id" element={<Xmls/>}/>    
          </Route>

          <Route path="/company/json/cp/:id" element={<WarehouseRoute/>}>
            <Route  path="/company/json/cp/:id" element={<Json/>}/>    
          </Route>

          
          <Route path="/company/json/:id" element={<PrivateRoute/>}>
            <Route  path="/company/json/:id" element={<StoreJson/>}/>    
          </Route>

          <Route path="/company-client/" element={<PrivateRoute/>}>
            <Route  path="/company-client/" element={<EditCompanyClient/>}/>    
          </Route>

          <Route path="/company/xml/:id" element={<PrivateRoute/>}>
            <Route  path="/company/xml/:id" element={<StoreXml/>}/>    
          </Route>

          
          <Route path="/logs" element={<PrivateRoute/>}>
            <Route  path="/logs" element={<Logs/>}/>    
          </Route>


          <Route path="/company/false-orders/:id" element={<PrivateRoute/>}>
            <Route  path="/company/false-orders/:id" element={<FalseOrders/>}/>    
          </Route>


          <Route path="/company/cron/:id/:name" element={<PrivateRoute/>}>
            <Route  path="/company/cron/:id/:name" element={<CronSingle/>}/>    
          </Route>


          <Route path="/company/cron_log/:id/:name" element={<PrivateRoute/>}>
            <Route  path="/company/cron_log/:id/:name" element={<CronLogSingle/>}/>    
          </Route>


          <Route path="/company/api_access/:id" element={<PrivateRoute/>}>
            <Route  path="/company/api_access/:id/" element={<APIAccess/>}/>    
          </Route>
    

          <Route path="/company/false-orders" element={<PrivateRoute/>}>
            <Route  path="/company/false-orders" element={<FalseOrders/>}/>    
          </Route>
       
          <Route path="/products/:id" element={<PrivateRoute/>}>
            <Route  path="/products/:id" element={<Products/>}/>    
          </Route>


     
          <Route path="/orders/serial/:id" element={<PrivateRoute/>}>
            <Route  path="/orders/serial/:id" element={<SerialTable/>}/>    
          </Route>

          <Route path="/orders/serial" element={<PrivateRoute/>}>
            <Route  path="/orders/serial" element={<SerialTable/>}/>    
          </Route>

          <Route path="/orders/bbd/:id" element={<PrivateRoute/>}>
            <Route  path="/orders/bbd/:id" element={<BBDTable/>}/>    
          </Route>



          
          <Route path="/company/:id" element={<PrivateRoute/>}>
            <Route  path="/company/:id" element={<CompanySetting/>}/>    
          </Route>



         

       
        </Routes>
   
    </Router>
  );
}

export default App;

