import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import { Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, Pagination, TableContainer, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { deleteFtp, getFtps, saveFtp } from '../../apis/Ftp';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteFalseOrder, getFalseOrder, getFalseOrderById, processFalseOrder } from '../../apis/FalseOrder';
export default function FalseOrderTable(props) {

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [false_order,set_false_order] = useState([]);
    const [false_order_data,set_false_order_data] = useState([]);
    const [page,setpage] = useState(1);
    const [order_json,set_order_json] = useState({});

    const [order_external_order_id,set_order_external_order_id] = useState("");
    const [order_company,set_order_company] = useState("");
    const [order_country,set_order_country] = useState("");
    const [order_first_name,set_order_first_name] = useState("");
    const [order_last_name,set_order_last_name] = useState("");
    const [order_date,set_order_date] = useState("");
    const [order_payment_date,set_order_payment_date] = useState("");
    const [order_phone,set_order_phone] = useState("");
    const [order_state,set_order_state] = useState("");
    const [order_street,set_order_street] = useState("");
    const [order_street_no,set_order_street_no] = useState("");
    const [order_tags,set_order_tags] = useState("");
    const [order_zip,set_order_zip] = useState("");
    const [order_city,set_order_city] = useState("");
    const [order_id,set_order_id] = useState("");
    const [care_of,set_care_of] = useState("");
    const [order_delivery_note,set_order_delivery_note] = useState([]);
    const [order_invoice_note,set_order_invoice_note] = useState([]);

    const [search_field,set_search_field] = useState("");













    
    const company_id = props.company;

    useEffect(() => {
        setLoading(true);

        getFalseOrder(company_id)
            .then(data => {
                set_false_order(data.data.data);
                set_false_order_data(data.data);
                
            })
        setLoading(false);
    }, [])

    const [errors, set_errors] = useState([]);



   



    const handleChangePage=(event,value)=>{ 
       
        setLoading(true);
        set_false_order([]);
        set_false_order_data([]);

  

        setpage(value);
        
        getFalseOrder(company_id,value)
        .then(data=>{
            set_false_order(data.data.data);
            set_false_order_data(data.data);
            setLoading(false);
        })
        
    }



    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };
    const handleClickOpen = (id) => {
        
        set_order_id(id);
        setLoading(true);
        getFalseOrderById(company_id,id)
        .then((data)=>{
            const order  = data.data;
            set_order_external_order_id(order.external_order_id);
            if(!order.external_order_id){
                errors.external_order_id=true;
            }
            set_order_company(order.company);
            if(!order.company){
                errors.order_company=true;
            }
            set_order_country(order.country);
            if(!order.country){
                errors.order_country=true;
            }
            set_order_first_name(order.first_name);
            if(!order.first_name){
                errors.first_name=true;
            }
            set_order_last_name(order.last_name);
            if(!order.last_name){
                errors.last_name=true;
            }
            set_order_date(order.order_date);
            if(!order.order_date){
                errors.order_date=true;
            }
            set_order_payment_date(order.payment_date);
            if(!order.payment_date){
                errors.payment_date=true;
            }
            set_order_phone(order.phone);
            if(!order.phone){
                errors.order_phone=true;
            }
            set_order_state(order.state);
            if(!order.order_state){
                errors.order_state=true;
            }
            set_order_street(order.street);
            if(!order.street){
                errors.order_street=true;
            }
            set_order_street_no(order.street_no);
            if(order.street_no==""){
                errors.order_street_no=true;
            }
            set_order_tags(order.tags);
            if(!order.tags){
                errors.order_tags=true;
            }
            set_order_zip(order.zip);
            if(!order.zip){
                errors.order_zip=true;
            }
            set_order_city(order.city);
            if(!order.city){
                errors.order_city=true;
            }
            set_care_of(order.care_of);
            if(!order.care_of){
                errors.care_of=true;
            }
            
            set_errors(errors);
            setLoading(false);


        
        })

        setOpen(true);
      };
    
      const handleClose = () => {
        set_order_id("");
        set_order_external_order_id("");
        set_order_company("");
        set_order_country("");
        set_order_first_name("");
        set_order_last_name("");
        set_order_date("");
        set_order_payment_date("");
        set_order_phone("");
        set_order_state("");
        set_order_street("");
        set_order_street_no("");
        set_order_tags("");
        set_order_zip("");
        set_order_city("");
        set_care_of("");
        set_order_delivery_note([]);
        set_order_invoice_note([]);
        setOpen(false);

      };
      const handleProcessOrder=()=>{
        setLoading(true);

        let data = new FormData();
        data.append('order_id', order_id)
        data.append('external_order_id', order_external_order_id)
        data.append('company', order_company)
        data.append('country', order_country)
        data.append('first_name', order_first_name)
        data.append('last_name', order_last_name)
        data.append('order_date', order_date)
        data.append('payment_date', order_payment_date)
        data.append('phone', order_phone)
        data.append('state', order_state)
        data.append('street', order_street)
        data.append('street_no', order_street_no)
        data.append('tags', order_tags)
        data.append('zip', order_zip)
        data.append('city', order_city)
        data.append('care_of', care_of)
        data.append('invoice_note', order_invoice_note)
        data.append('delivery_note', order_delivery_note)


        console.log(data);
        processFalseOrder(data,company_id,order_id)
        .then(data=>{
            handleClose();
            toast.success(data.message);
            setLoading(true);
            set_false_order([]);
            set_false_order_data([]);

    

            getFalseOrder(company_id,page)
            .then(data=>{
                set_false_order(data.data.data);
                set_false_order_data(data.data);
                setLoading(false);
            })
            setOpen(false);
            
            setLoading(false);
        })

      }

      const handlechange=(e)=>{
        set_search_field(e.target.value);
      }
      const handleOrderChange=(e)=>{
        // console.log(e.target.name)
            switch(e.target.name){
                case "order_external_order_id":
                    set_order_external_order_id(e.target.value);
                    if(e.target.value==""){
                        errors.external_order_id = true;
                    }else{
                        errors.external_order_id = false;
                    }
                    set_errors(errors);
                    break;
                case "order_company":
                    set_order_company(e.target.value);
                    if(e.target.value==""){
                        errors.order_company = true;
                    }else{
                        errors.order_company = false;
                    }
                    set_errors(errors);
                    break;
                case "order_country":
                    set_order_country(e.target.value);
                    if(e.target.value==""){
                        errors.order_country = true;
                    }else{
                        errors.order_country = false;
                    }
                    set_errors(errors);
                    break;
                case "order_first_name":
                    set_order_first_name(e.target.value);
                    if(e.target.value==""){
                        errors.order_first_name = true;
                    }else{
                        errors.order_first_name = false;
                    }
                    set_errors(errors);
                    break;
                case "order_last_name":
                    set_order_last_name(e.target.value);
                    if(e.target.value==""){
                        errors.order_last_name = true;
                    }else{
                        errors.order_last_name = false;
                    }
                    set_errors(errors);
                    break;
                case "order_date":
                    set_order_date(e.target.value);
                    if(e.target.value==""){
                        errors.order_date = true;
                    }else{
                        errors.order_date = false;
                    }
                    set_errors(errors);
                    break;
                case "order_payment_date":
                    set_order_payment_date(e.target.value);
                    if(e.target.value==""){
                        errors.order_payment_date = true;
                    }else{
                        errors.order_payment_date = false;
                    }
                    set_errors(errors);
                    break;
                case "order_phone":
                    set_order_phone(e.target.value);
                    if(e.target.value==""){
                        errors.order_phone = true;
                    }else{
                        errors.order_phone = false;
                    }
                    set_errors(errors);
                    break;
                case "order_state":
                    set_order_state(e.target.value);
                    if(e.target.value==""){
                        errors.order_state = true;
                    }else{
                        errors.order_state = false;
                    }
                    set_errors(errors);
                    break;          
                case "order_street":
                    set_order_street(e.target.value);
                    if(e.target.value==""){
                        errors.order_street = true;
                    }else{
                        errors.order_street = false;
                    }
                    set_errors(errors);
                    
                    break;           
                case "order_street_no":
                    set_order_street_no(e.target.value);
                    if(e.target.value==""){
                        errors.order_street_no = true;
                    }else{
                        errors.order_street_no = false;
                    }
                    set_errors(errors);
                    
                    break;              
                case "order_tags":
                    set_order_tags(e.target.value);
                    if(e.target.value==""){
                        errors.order_tags = true;
                    }else{
                        errors.order_tags = false;
                    }
                    set_errors(errors);
                    
                    break;     
                case "order_zip":
                    set_order_zip(e.target.value);
                    if(e.target.value==""){
                        errors.order_zip = true;
                    }else{
                        errors.order_zip = false;
                    }
                    set_errors(errors);
                    
                    break;  
                case "order_city":
                    set_order_city(e.target.value);
                    if(e.target.value==""){
                        errors.order_city = true;
                    }else{
                        errors.order_city = false;
                    }
                    set_errors(errors);
                    
                    break;  
                case "care_of":
                    set_care_of(e.target.value);
                    if(e.target.value==""){
                        errors.care_of = true;
                    }else{
                        errors.care_of = false;
                    }
                    set_errors(errors);
                    
                    break;  
                case "order_invoice_note":
                    set_order_invoice_note(e.target.files[0]);
                    break;
                case "order_delivery_note":
                    set_order_delivery_note(e.target.files[0]);
                    break;
                
                default:
                    break;
            }
      }


      const handleSearch=(e)=>{

        setLoading(true);
        set_false_order([]);
        set_false_order_data([]);

  

        setpage(page);
        
        getFalseOrder(company_id,page,search_field)
        .then(data=>{
            set_false_order(data.data.data);
            set_false_order_data(data.data);
            setLoading(false);
        })
      }
    return (

        <React.Fragment >

            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose=""
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={() => { return; }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <DialogTitle>False Order</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Box mb={2} />
                        <FormControl fullWidth>

                            <TextField required label="External Order ID" value={order_external_order_id} variant="outlined" InputLabelProps={{ shrink: true }} name="order_external_order_id" onChange={handleOrderChange} 
                            error={errors.external_order_id}
                            />

                        </FormControl>
                        <Box mb={2} />


                        <FormControl fullWidth>

                            <TextField  label="Company" value={order_company} variant="outlined" InputLabelProps={{ shrink: true }} name="order_company" onChange={handleOrderChange}   
                            // error={errors.order_company}
                            
                            />

                        </FormControl>

                        
                        <Box mb={2} />
                        <FormControl fullWidth>

                            <TextField required label="First Name" value={order_first_name} variant="outlined" InputLabelProps={{ shrink: true }} name="order_first_name" onChange={handleOrderChange}  
                            error={errors.order_first_name }
                            />

                        </FormControl>

                        <Box mb={2} />
                        <FormControl fullWidth>

                            <TextField required label="Last Name" value={order_last_name} variant="outlined" InputLabelProps={{ shrink: true }} name="order_last_name" onChange={handleOrderChange} 
                            error={errors.order_last_name}
                            />

                        </FormControl>
                        <Box mb={2} />
                        <FormControl fullWidth>

                        <TextField  type="text" label="Care Of" value={care_of} variant="outlined" InputLabelProps={{ shrink: true }} name="care_of"  onChange={handleOrderChange} />

                        </FormControl>
                        <Box mb={2} />
                        
                        <FormControl fullWidth>

                        <TextField required type="text" label="Street" value={order_street} variant="outlined" InputLabelProps={{ shrink: true }} name="order_street"  onChange={handleOrderChange} 
                            error={errors.order_street}
                        />

                        </FormControl>

                        <Box mb={2} />
                        <FormControl fullWidth>

                        <TextField required type="text" label="Street No" value={order_street_no} variant="outlined" InputLabelProps={{ shrink: true }} name="order_street_no" onChange={handleOrderChange}  
                            error={errors.order_street_no }
                        />

                        </FormControl>

                        <Box mb={2} />
                         <FormControl fullWidth>

                            <TextField required type="text" label="ZIP" value={order_zip} variant="outlined" InputLabelProps={{ shrink: true }} name="order_zip"  onChange={handleOrderChange} 
                            error={errors.order_zip}
                            />

                        </FormControl>
                        

                        <Box mb={2} />

                           <FormControl fullWidth>

                            <TextField required  type="text" label="City" value={order_city} variant="outlined" InputLabelProps={{ shrink: true }} name="order_city"  onChange={handleOrderChange} 
                            error={errors.order_city }
                            />

                        </FormControl>

                        <Box mb={2} />
                        <FormControl fullWidth>

                            <TextField  type="text" label="State" value={order_state} variant="outlined" InputLabelProps={{ shrink: true }} name="order_state"  onChange={handleOrderChange} />

                        </FormControl>

                                           
                        <Box mb={2} />
                        <FormControl fullWidth>

                            <TextField required label="Country" value={order_country} variant="outlined" InputLabelProps={{ shrink: true }} name="order_country" onChange={handleOrderChange}  
                            error={errors.order_country}
                            
                            />

                        </FormControl>            
                     
                        <Box mb={2} />

                        <FormControl fullWidth>

                            <TextField  type="text" label="Phone" value={order_phone} variant="outlined" InputLabelProps={{ shrink: true }} name="order_phone"  onChange={handleOrderChange} 
                            //  error={errors.order_phone}
                             />

                        </FormControl>

                        

                        <Box mb={2} />
       
                 

                     
                       
                        <FormControl fullWidth>

                            <TextField  type="text" label="Tags" value={order_tags} variant="outlined" InputLabelProps={{ shrink: true }} name="order_tags"  onChange={handleOrderChange} 
                            />

                        </FormControl>

                        <Box mb={2} />

                        <FormControl fullWidth>

                            <TextField required type="date" label="Order Date" value={order_date} variant="outlined" InputLabelProps={{ shrink: true }} name="order_date"  onChange={handleOrderChange} 
                            error={errors.order_date}
                            
                            />

                        </FormControl>
                        <Box mb={2} />

                        <FormControl fullWidth>

                            <TextField required type="date" label="Payment Date" value={order_payment_date} variant="outlined" InputLabelProps={{ shrink: true }} name="order_payment_date"  onChange={handleOrderChange} 
                            error={errors.order_payment_date}
                            
                            />

                        </FormControl>

                        <Box mb={2} />

                        <FormControl fullWidth>

                            <TextField  type="file" label="Delivery Note"  accept="application/pdf" variant="outlined" InputLabelProps={{ shrink: true }} name="order_delivery_note"  onChange={handleOrderChange} 
                            error={errors.order_delivery_note}
                            
                            />

                        </FormControl>

                        
                        <Box mb={2} />

                        <FormControl fullWidth>

                            <TextField  type="file" label="Invoice Note"  accept="application/pdf" variant="outlined" InputLabelProps={{ shrink: true }} name="order_invoice_note"  onChange={handleOrderChange} 
                            error={errors.order_invoice_note}
                            
                            />

                        </FormControl>
                        <Box mb={2} />
                        
                    </DialogContentText>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                        }}
                    >

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleProcessOrder}>Process</Button>
                    <Button onClick={()=>{
                        handleClose()
                    }}>Close</Button>

                </DialogActions>
            </Dialog>
            <ToastContainer />
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                False Order 
            </Typography>


            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }} alignItems="left"   justifyContent="left"> 
            <Grid item xs={12} sm={12} md={3}>
                <Box p={2}>
                <FormControl fullWidth>

                <TextField   label="Search" type="text" variant="outlined" InputLabelProps={{ shrink: true }} name="search" onChange={handlechange} value={search_field}  />

                </FormControl>
                </Box>
            </Grid>
            
            <Grid item xs={12} sm={12} md={2} alignItems="center"   justifyContent="center">
                 <Box p={2}>
                <FormControl fullWidth>

                <Button type="submit" variant="outlined" onClick={handleSearch} >SEARCH</Button>
                </FormControl>
                </Box>
            </Grid>
            </Grid>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Order ID</TableCell>
                            <TableCell>External Order ID</TableCell>
                            <TableCell>Errors</TableCell>
                            <TableCell>Created Date</TableCell>

                            <TableCell>Actions</TableCell>






                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {false_order.map((row) => {

                            let error = JSON.parse(JSON.parse(row.json));
                            // console.log(error);
                            
                            return (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.order_id}</TableCell>
                                    <TableCell>{row.external_order_id}</TableCell>
                                    <TableCell>
                                        <ul>
                                        {
                                            error.data && Object.keys(error.data).map(key => {
                                                    
                                                    return (<li>{error.data[key]}</li>)
                                                
                                              })
                                        }
                                        
                                        {
                                         !error.data  && (
                                                <li>{error.message}</li>
                                            )
                                        }
                                        </ul>
                                        </TableCell>
                                    <TableCell>{moment(row.created_at).format("DD/MM/YYYY hh:mm:ss a")}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={()=>{
                                            // console.log(row.id)
                                            handleClickOpen(row.order_id);
                                        }}>
                                            <EditIcon/>
                                        </IconButton>
                                        <IconButton onClick={() => {
                                            let confirm_delete = window.confirm("Do you want to delete this record");
                                            if (confirm_delete) {
                                                setLoading(true);
                                                deleteFalseOrder(row.id)
                                                    .then((data) => {
                                                        setLoading(false);
                                                        if (data.success) {
                                                            toast.success(data.message);
                                                            getFalseOrder(company_id,page)
                                                                .then(data => {
                                                                    set_false_order(data.data.data)
                                                                    set_false_order_data(data.data)
                                                                    // console.log(data);
                                                                    // toast.success(data.data.message)
                                                                })
                                                        } else {
                                                            toast.error(data.data.message);
                                                        }
                                                    })
                                            }
                                        }}>
                                            <DeleteIcon color="warning" />
                                        </IconButton>
                                      

                                    </TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>

                </Table>
                <Grid
                        mt={3}
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center" >
                        <Grid item >
                            <Pagination
                                count={false_order_data.last_page}
                                page={page}
                                onChange={(event, pageNumber) => handleChangePage(event, pageNumber)}

                                color="primary" />
                        </Grid>
                    </Grid>

            </TableContainer>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => { return; }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}
