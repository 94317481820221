import { AppBar, Backdrop, Button, Checkbox, CircularProgress, Dialog, Fab, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {  useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import { toast } from 'react-toastify';
import { getCompanyById, saveFtpCron, updateCompany } from '../../apis/Company';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FtpTable from '../../components/tables/FtpTable';
import { getFtps, getFtpsAll } from '../../apis/Ftp';
import { getAllOrders, syncOrdersWithPakaneo } from '../../apis/Orders';
import { getWebservices } from '../../apis/Webservices';
import { getFilesFromFtp, getFulfillables, uploadXmlToFTP } from '../../apis/Xml';
import exportFromJSON from 'export-from-json';
import { Buffer } from 'buffer';
import { getCronTypes } from '../../apis/CronTypes';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { getCronByCompany, saveCron } from '../../apis/Crons';
import CronTable from '../../components/tables/CronTable';
import AddIcon from '@mui/icons-material/Add';
import CronLogTable from '../../components/tables/CronLogTable';
import { getCronlogs } from '../../apis/Cronlog';
import LogsTable from '../../components/tables/LogTable';
import { getFieldKeys } from '../../apis/FieldKeys';
import { getSettings, saveSettings } from '../../apis/Settings';
import { processOrder, processProducts, updateStock } from '../../apis/Billbee';
import ClientUserTable from '../../components/tables/ClientUsersTable';
import Multiselect from 'multiselect-react-dropdown';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const EditCompanyClient = () => {
    const navigate = useNavigate();
    const params = useParams();
    params.id = localStorage.getItem('company_id');
    const [loading, setLoading] = useState(false);
    const [lock,set_lock] = useState(true);


    const [errors, setErrors] = useState([]);
    const [orders, setOrders] = useState([]);
    const [open, setOpen] = React.useState(false);




    const [name, set_name] = useState('');
    const [url_name, set_url_name] = useState('');
    const [url, set_url] = useState('');
    const [token, set_token] = useState("");
    const [company_ftp, set_company_ftp] = useState("");
    const [source,set_source] = useState([]);
    const [value, setValue] = useState(0);
    const [ftps, setFtps] = useState([]);
    const [webservices, setWebservices] = useState([]);

    const [ftp, setFtp] = useState("");
    const [webservice, setWebservice] = useState("");
    const [crontype, setCronType] = useState("");
    const [times, setTimes] = useState("");
    const [delay, setDelay] = useState("");
    const [time, setTime] = useState("");
    const [logs,set_logs] = useState([]);
    const [logs_data,set_logs_data] = useState([]);
    const [page,setpage] = useState(1);
    const[source_fields,set_source_fields] = useState([]);

    const [street, set_street] = useState('');
    const [street_number, set_street_number] = useState('');
    const [city, set_city] = useState('');
    const [country, set_country] = useState('');
    const [zip, set_zip] = useState('');
    const [tin_number, set_tin_number] = useState('');
    const [phone_number, set_phone_number] = useState('');




    


    const [password, setPassword] = useState("");


  

    const handleChangeSettings=(event)=>{
        //console.log(source_fields);
        source_fields.map(item=>{
            if(item.key == event.target.name){
                item.values= event.target.value;

            }
        })
        set_source_fields(source_fields);
        

    }

    const handleSaveSourceSetting=()=>{
        // //console.log(source_fields);
        source_fields.map(item=>{
            if(item.values!=""){
            saveSettings(JSON.stringify(item));
            }

        })
        toast.success("Setting Saved")

    }
    const handleChangePage=(event,value)=>{ 
       
        setLoading(true);
        set_logs([]);
        set_logs_data([]);


        setpage(value);
        
        getCronlogs(params.id,value)
        .then(data=>{
            //console.log(data.data);
            set_logs(data.data.data);
            set_logs_data(data.data);
            setLoading(false);
        })
        
    }
      
    const handleChangeTabs = (event, newValue) => {

        setValue(newValue);

        if(source.code == "ODOO"){
            if(newValue==2){
                setLoading(true);




                getCompanyById(params.id)
                .then((data) => {

                    if (data.success) {

                    set_company_ftp(data.data.ftp_id); 
                    set_name(data.data.name);
                    set_url(data.data.url);
                    if(data.data.token){
                    set_token(data.data.token);
                    }else{
                        set_token("save company and klick generate token")
                    }
                    set_source(data.data.source);
                    // set_company_email(data.data.company_email);

                    if(data.data.source_id){
                        let settings_data_obj = "";
                        getSettings(params.id)
                        .then(settings_data=>{

                            if(settings_data.data){

                            settings_data_obj =settings_data.data;

                                getFieldKeys(data.data.source.id)
                                    .then(data_source=>{

                                        set_source_fields(data_source.data);




                                        data_source.data.map(item=>{
                                            if(!settings_data_obj){
                                                settings_data_obj = data_source.data;
                                                settings_data_obj.map(item_settings=>{
                                                    // item.id = undefined;
                                                    // item_settings.values = "";
                                                })
                                            }

                                            settings_data_obj.map(item_settings=>{
                                                if(item_settings.key == item.key){

                                                    item.values=item_settings.values;

                                                    // //console.log(document.querySelector(`input[name=${item.key}]`));
                                                    if(item.values!==undefined && document.querySelector(`input[name=${item.key}]`)){
                                                        document.querySelector(`input[name=${item.key}]`).value=item_settings.values;

                                                      }

                                                }
                                            });

                                            // item.values = "";

                                            item.company_id=params.id;

                                        })




                                    })
                            }
                            setLoading(false);
                            
                        });




                    }
                    



                  
                } else {
                    // toast.error(data.message);
                    navigate('/')
                }
            })
            }
        }


    };


    const handleExportStockFtp = () => {
        setLoading(true)
        // //console.log(json)
        const fileName = "export"
        const fields = []
        const exportType = 'json';
        let data;
        let payload = {
            company_id: params.id
        }


        getFulfillables(payload)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    data = JSON.parse(JSON.stringify(JSON.parse(data.data)))
                    const output = exportFromJSON({ data, fileName, fields, exportType, processor: content => content })
                    // //console.log(output);
                    // return;
                    let data_api = {
                        ftp_id: ftp,
                        xml_content: output,
                        password: password,
                        is_for_orders: 0

                    }
                    uploadXmlToFTP(JSON.stringify(data_api))
                        .then(data => {
                            if (data.success) {
                                toast.success(data.message);
                            } else {
                                toast.error(data.message);
                            }
                        })

                } else {
                    toast.error(data.message);
                }
            })


        setLoading(false);
    }

    const handleDownloadStock = () => {
        let data = {
            company_id: params.id
        }
        setLoading(true);
        getFulfillables(data)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    // //console.log();
                    download(JSON.stringify(JSON.parse(data.data)))
                } else {
                    toast.error(data.message);
                }
            })
    }
    function download(json) {
        // //console.log(json)
        const fileName = "export"
        const data = JSON.parse(json)
        const fields = []
        const exportType = 'json';

        exportFromJSON({ data, fileName, fields, exportType })
    }

    useEffect(() => {
        setLoading(true);


 
        getWebservices()
            .then(data => {
                setWebservices(data.data);
            })

        getFtps(params.id)
            .then(data => {
                setFtps(data.data);
            })


        getCompanyById(params.id)
            .then((data) => {

                if (data.success) {

                    set_company_ftp(data.data.ftp_id); 
                    set_name(data.data.name);
                    set_url(data.data.url);
                    //console.log(data.data);
                    set_url_name(data.data.url_name);
                    set_token(data.data.token);
                    set_source(data.data.source);
                    set_street(data.data.street);
                    set_street_number(data.data.street_number);
                    set_country(data.data.country);
                    set_city(data.data.city);
                    set_zip(data.data.zip);
                    set_tin_number(data.data.tin_number);
                    set_phone_number(data.data.phone_number);

                    //console.log(data.data.source_id);
                    if(data.data.source_id){
                        let settings_data_obj = "";
                        getSettings(params.id)
                        .then(settings_data=>{
                            if(settings_data.data){
                            settings_data_obj =settings_data.data; 
                            }
                            
                        });
                        getFieldKeys(data.data.source.id)
                        .then(data_source=>{
                            // //console.log(data);
                            set_source_fields(data_source.data);

                           
                         
                           
                                data_source.data.map(item=>{
                                    if(!settings_data_obj){
                                        settings_data_obj = data_source.data;
                                        settings_data_obj.map(item=>{
                                            item.id = undefined;
                                            item.values = "";
                                        })
                                    }
                                    // //console.log(data_source);

                                    // //console.log(settings_data_obj);
                                    settings_data_obj.map(item_settings=>{
                                        
                                        if(item_settings.key == item.key){
                                            
                                            item.values=item_settings.values;
                                            // //console.log(document.querySelector(`input[name=${item.key}]`));
                                            if(item.values!==undefined && document.querySelector(`input[name=${item.key}]`)){
                                                //console.log(item);
                                            document.querySelector(`input[name=${item.key}]`).value=item.values;
                                            }
                                            // //console.log(item_dom);
                                            // item_dom.value(item.values)
                                        }else{
                                            item.values = "";
                                        }
                                    });
                                    item.values = "";
                                    item.company_id=params.id;
                                    
                                })
                            
                            
                        })
                    }
                    


                    setLoading(false);
                  
                } else {
                    // toast.error(data.message);
                    navigate('/')
                }
            })
          

        getCronlogs(params.id,1)
        .then((data)=>{
            if(data.success){
                set_logs(data.data.data);
                set_logs_data(data.data);
            }else{
                navigate('/');
            }
            setLoading(false);


        })

    }, []);











    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


       
   
    function getSettingField(item) {

        switch (item.type) {

            case "text":

                if(item.values=="" && document.querySelector(`input[name=${item.key}]`)){


                    document.querySelector(`input[name=${item.key}]`).value=item.default_values;

                }

                return (
                    <>
                        <TextField   required label={item.name} variant="outlined" InputLabelProps={{ shrink: true }} name={item.key} type={item.type} onChange={handleChangeSettings}
                                    error={errors.name != undefined ? true : false}
                                    helperText={errors.name !== undefined ? errors.name : ''}
                        />
                    </>
                );

                break;
            case "password":
                if(item.values=="" && document.querySelector(`input[name=${item.key}]`)){


                    document.querySelector(`input[name=${item.key}]`).value=item.default_values;

                }
                return (
                    <>
                        <TextField  required label={item.name} variant="outlined" InputLabelProps={{ shrink: true }} name={item.key} type={item.type} onChange={handleChangeSettings}
                                    error={errors.name != undefined ? true : false}
                                    helperText={errors.name !== undefined ? errors.name : ''}
                        />
                    </>
                );
                break;
            case "number":
                if(item.values=="" && document.querySelector(`input[name=${item.key}]`)){


                    document.querySelector(`input[name=${item.key}]`).value=item.default_values;

                }
                return (
                    <>
                        <TextField    required label={item.name} variant="outlined" InputLabelProps={{ shrink: true }} name={item.key} type={item.type} onChange={handleChangeSettings}
                                    error={errors.name != undefined ? true : false}
                                    helperText={errors.name !== undefined ? errors.name : ''}
                        />
                    </>
                );
                break;
            case "date":
                if(item.values=="" && document.querySelector(`input[name=${item.key}]`)){


                    document.querySelector(`input[name=${item.key}]`).value=item.default_values;

                }
                return (<>
                    <TextField   required label={item.name} variant="outlined" InputLabelProps={{ shrink: true }} name={item.key} type={item.type} onChange={handleChangeSettings}
                                error={errors.name != undefined ? true : false}
                                helperText={errors.name !== undefined ? errors.name : ''}
                    />
                </>)
                break;
            case "multiselect":

                let select_values = JSON.parse(item.default_values);
                let selected_values =[];
                if(item.key=="ORDER_DOCUMENT_CHOOSE"){
                    if(item.values){
                        selected_values = JSON.parse(item.values)
                    }else{
                        selected_values = [{key:2,name:"DELIVERY NOTE"}];
                    }
                }
                if(item.key=="ORDER_STATE_ID"){
                    if(item.values){
                        selected_values = JSON.parse(item.values)
                    }else{
                        selected_values = [{key:3,name:"PAID"}];
                    }
                }






                return (<>
                    <label className="form-label"  disabled={true} >{item.name}</label >
                    <Multiselect
                        options={select_values} // Options to display in the dropdown
                        selectedValues={selected_values} // Preselected value to persist in dropdown
                        onSelect={(e)=>{
                            selected_values = e;
                            item.values = JSON.stringify(selected_values);
                            console.log(selected_values);
                        }
                        } // Function will trigger on select event
                        onRemove={(e)=>{
                            selected_values = e;
                            item.values = JSON.stringify(selected_values);
                        }
                        } // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                    />
                </>);
                break;


            default:
                break;

        }

    }

    return (
        <DashboardContent title="Company">
                     
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper elevation={2} >
                        <Box p={2}>
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={4}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Edit  Company 
                            </Typography>




                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                            {source.code =='ODOO' &&(

                                <Grid item xs={12} sm={12} md={12}>
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={value}  variant="scrollable" scrollButtons="auto" onChange={handleChangeTabs} aria-label="basic tabs example">
                                                <Tab label="General" {...a11yProps(0)} />
                                                <Tab label="Pakaneo Settings" {...a11yProps(1)} />

                                                {source.code =='ODOO' &&(
                                                <Tab label="Odoo Settings" {...a11yProps(2)} />

                                                )}
                                                <Tab label="Crons LOGS" {...a11yProps(3)} />


                                            </Tabs>
                                        </Box>
                                        <TabPanel value={value} index={0}>
                                            <FormControl fullWidth>

                                                <TextField  disabled required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name"  value={name}
                                                    error={errors.name != undefined ? true : false}
                                                    helperText={errors.name !== undefined ? errors.name : ''}
                                                />

                                            </FormControl>
                                            <Box mb={2} />
                                                {/* {Additional Fields} */}
                                                <FormControl fullWidth>

                                                    <TextField disabled={lock}  label="Street" variant="outlined" InputLabelProps={{ shrink: true }} name="street"  value={street}
                                                        error={errors.street != undefined ? true : false}
                                                        helperText={errors.street !== undefined ? errors.street : ''}
                                                    />

                                                    </FormControl>
                                                    <Box mb={2} />
                                                    <FormControl fullWidth>

                                                    <TextField disabled={lock}  label="Street Number" variant="outlined" InputLabelProps={{ shrink: true }} name="street_number"  value={street_number}
                                                        error={errors.street_number != undefined ? true : false}
                                                        helperText={errors.street_number !== undefined ? errors.street_number : ''}
                                                    />

                                                    </FormControl>
                                                    <Box mb={2} />

                                                    <FormControl fullWidth>

                                                    <TextField disabled={lock}  label="City" variant="outlined" InputLabelProps={{ shrink: true }} name="city"  value={city}
                                                        error={errors.city != undefined ? true : false}
                                                        helperText={errors.city !== undefined ? errors.city : ''}
                                                    />

                                                    </FormControl>
                                                    <Box mb={2} />

                                                    <FormControl fullWidth>

                                                    <TextField disabled={lock}  label="Country" variant="outlined" InputLabelProps={{ shrink: true }} name="country" value={country}
                                                        error={errors.country != undefined ? true : false}
                                                        helperText={errors.country !== undefined ? errors.country : ''}
                                                    />

                                                    </FormControl>
                                                    <Box mb={2} />


                                                    <FormControl fullWidth>

                                                    <TextField disabled={lock}  label="Zip" variant="outlined" InputLabelProps={{ shrink: true }} name="zip"  value={zip}
                                                        error={errors.zip != undefined ? true : false}
                                                        helperText={errors.zip !== undefined ? errors.zip : ''}
                                                    />

                                                    </FormControl>
                                                    <Box mb={2} />

                                                    <FormControl fullWidth>

                                                    <TextField disabled={lock}  label="Tax Identification Number" variant="outlined" InputLabelProps={{ shrink: true }} name="tin_number" value={tin_number}
                                                        error={errors.tin_number != undefined ? true : false}
                                                        helperText={errors.tin_number !== undefined ? errors.tin_number : ''}
                                                    />

                                                    </FormControl>
                                                    <Box mb={2} />


                                                    <FormControl fullWidth>

                                                    <TextField disabled={lock}  label="Phone Number" variant="outlined" InputLabelProps={{ shrink: true }} name="phone_number" value={phone_number}
                                                        error={errors.phone_number != undefined ? true : false}
                                                        helperText={errors.phone_number !== undefined ? errors.phone_number : ''}
                                                    />

                                                    </FormControl>
                                                    <Box mb={2} />

                                                    {/* {Additional Fields} */}
                                        
                                        </TabPanel>

                                        
                                        <TabPanel value={value} index={1}>
                                        <FormControl fullWidth>
                                            <TextField required   disabled type="text" label="URL NAME" variant="outlined" InputLabelProps={{ shrink: true }} name="url_name"  value={url_name}
                                                    error={errors.url_name != undefined ? true : false}
                                                    helperText={errors.url_name !== undefined ? errors.url_name : ''}
                                                />
                                            </FormControl>
                                            <Box mb={2} />

                                            <FormControl fullWidth>
                                                <TextField required  disabled type="text"  disabled label="URL" variant="outlined" InputLabelProps={{ shrink: true }} name="url" value={url}
                                                    error={errors.url != undefined ? true : false}
                                                    helperText={errors.url !== undefined ? errors.url : ''}
                                                />

                                            </FormControl>
                                            
                                            <Box mb={2} />
                                            
                                            
                                        </TabPanel>

                                        {source.code =='ODOO' &&(
                                        <TabPanel value={value} index={2}>
                                            <Box p={2}>
                                                {
                                                source_fields && source_fields.map(item=>(
                                                <div>
                                                    <FormControl fullWidth >
                                                        {
                                                            getSettingField(item)
                                                        }


                                                </FormControl>
                                                <Box p={2}/>
                                                </div>
                                                ))
                                                }    

                                            <Button  variant="outlined" onClick={handleSaveSourceSetting}>Save Settings </Button>

                                           




                                            </Box>  
                                            </TabPanel>)}

                                      
                                    
                                     
                                    
                                        <TabPanel value={value} index={3}>
                                                <CronLogTable handleChangePage={handleChangePage} logs={logs} logs_data={logs_data} />
                                                
                                        </TabPanel>
                                       


                                    </Box>
                                </Grid>
                            )}

                            {source.code !='ODOO' &&(

                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value}  variant="scrollable" scrollButtons="auto" onChange={handleChangeTabs} aria-label="basic tabs example">
                                            <Tab label="General" {...a11yProps(0)} />
                                            <Tab label="Pakaneo Settings" {...a11yProps(1)} />

                                           
                                            <Tab label="Crons LOGS" {...a11yProps(2)} />


                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <FormControl fullWidth>

                                            <TextField  disabled required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name"  value={name}
                                                error={errors.name != undefined ? true : false}
                                                helperText={errors.name !== undefined ? errors.name : ''}
                                            />

                                        </FormControl>
                                        <Box mb={2} />
                                        {/* {Additional Fields} */}
                                        <FormControl fullWidth>

<TextField disabled={lock}  label="Street" variant="outlined" InputLabelProps={{ shrink: true }} name="street"  value={street}
    error={errors.street != undefined ? true : false}
    helperText={errors.street !== undefined ? errors.street : ''}
/>

</FormControl>
<Box mb={2} />
<FormControl fullWidth>

<TextField disabled={lock}  label="Street Number" variant="outlined" InputLabelProps={{ shrink: true }} name="street_number"  value={street_number}
    error={errors.street_number != undefined ? true : false}
    helperText={errors.street_number !== undefined ? errors.street_number : ''}
/>

</FormControl>
<Box mb={2} />

<FormControl fullWidth>

<TextField disabled={lock}  label="City" variant="outlined" InputLabelProps={{ shrink: true }} name="city"  value={city}
    error={errors.city != undefined ? true : false}
    helperText={errors.city !== undefined ? errors.city : ''}
/>

</FormControl>
<Box mb={2} />

<FormControl fullWidth>

<TextField disabled={lock}  label="Country" variant="outlined" InputLabelProps={{ shrink: true }} name="country" value={country}
    error={errors.country != undefined ? true : false}
    helperText={errors.country !== undefined ? errors.country : ''}
/>

</FormControl>
<Box mb={2} />


<FormControl fullWidth>

<TextField disabled={lock}  label="Zip" variant="outlined" InputLabelProps={{ shrink: true }} name="zip"  value={zip}
    error={errors.zip != undefined ? true : false}
    helperText={errors.zip !== undefined ? errors.zip : ''}
/>

</FormControl>
<Box mb={2} />

<FormControl fullWidth>

<TextField disabled={lock}  label="Tax Identification Number" variant="outlined" InputLabelProps={{ shrink: true }} name="tin_number" value={tin_number}
    error={errors.tin_number != undefined ? true : false}
    helperText={errors.tin_number !== undefined ? errors.tin_number : ''}
/>

</FormControl>
<Box mb={2} />


<FormControl fullWidth>

<TextField disabled={lock}  label="Phone Number" variant="outlined" InputLabelProps={{ shrink: true }} name="phone_number" value={phone_number}
    error={errors.phone_number != undefined ? true : false}
    helperText={errors.phone_number !== undefined ? errors.phone_number : ''}
/>

</FormControl>
<Box mb={2} />

{/* {Additional Fields} */}
                                    
                                    </TabPanel>

                                    
                                    <TabPanel value={value} index={1}>
                                    <FormControl fullWidth>
                                        <TextField required   disabled type="text" label="URL NAME" variant="outlined" InputLabelProps={{ shrink: true }} name="url_name"  value={url_name}
                                                error={errors.url_name != undefined ? true : false}
                                                helperText={errors.url_name !== undefined ? errors.url_name : ''}
                                            />
                                        </FormControl>
                                        <Box mb={2} />

                                        <FormControl fullWidth>
                                            <TextField required  disabled type="text"  disabled label="URL" variant="outlined" InputLabelProps={{ shrink: true }} name="url" value={url}
                                                error={errors.url != undefined ? true : false}
                                                helperText={errors.url !== undefined ? errors.url : ''}
                                            />

                                        </FormControl>
                                        
                                        <Box mb={2} />
                                       
                                    </TabPanel>

                                    

                                
                                
                                
                                
                                    <TabPanel value={value} index={2}>
                                            <CronLogTable handleChangePage={handleChangePage} logs={logs} logs_data={logs_data} />
                                            
                                    </TabPanel>
                                


                                </Box>
                            </Grid>
                            )}

                            </Grid>











                        </Box>
                    </Paper>
                </Grid>

            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => { return; }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </DashboardContent>
    );
}

export default EditCompanyClient;
