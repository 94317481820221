import {  Backdrop, Button, CircularProgress, Fab, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { getAllCompanies, getCompanies } from '../../apis/Company';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CompaniesTable from '../../components/tables/CompaniesTable';
import { getLogs } from '../../apis/Log';
import LogsTable from '../../components/tables/LogTable';
import { getOrders } from '../../apis/Orders';
import { getWebservices } from '../../apis/Webservices';
import { getUserRole } from '../../apis/Auth';

const LogsSingleCompany = (props) => {
    const params = useParams();

    const [logs,set_logs] = useState([]);
    const [logs_data,set_logs_data] = useState([]);
    const [ref_id_1,set_ref_id_1] = useState("");
    const [ref_id_2,set_ref_id_2] = useState("");
    const [response,set_response] = useState("");



    const [loading,setLoading] = useState(true);
    const [page,setpage] = useState(1);
    const [from_date, set_from_date] = useState("");
    const [to_date, set_to_date] = useState("");
    const [companies, set_companies] = useState([]);
    const [company, set_company] = useState([]);
    const [webservices, set_webservices] = useState([]);
    const [webservice, set_webservice] = useState([]);
    const [filter, set_filter] = useState([]);

    const handleChangePage=(event,value)=>{ 
       
        setLoading(true);
        set_logs([]);
        set_logs_data([]);

        getAllCompanies()
        .then(data => {
            set_companies(data.data)
        })

        setpage(value);
        let data = {
            from_date: from_date,
            to_date: to_date,
            company_id: params.id,
            webservice_id : webservice,
            ref_id_1:ref_id_1,
            ref_id_2:ref_id_2,
            response: response
        }
        set_filter(data);
        getLogs(value,data)
        .then(data=>{
            //console.log(data.data);
            set_logs(data.data.data);
            set_logs_data(data.data);
            setLoading(false);
        })
        
    }
    const navigate=  useNavigate();
    useEffect(()=>{
        let data = {
            from_date: from_date,
            to_date: to_date,
            company_id: params.id,
            webservice_id : webservice,
            ref_id_1:ref_id_1,
            ref_id_2:ref_id_2,
            response: response
        }
        set_filter(data);
        getAllCompanies()
        .then(data => {
            set_companies(data.data)
        })
        getWebservices()
        .then(data=>{
            set_webservices(data.data);
        })
        getLogs(1,data)
        .then((data)=>{
            if(data.success){
                set_logs(data.data.data);
                set_logs_data(data.data);
            }else{
                navigate('/');
            }
            setLoading(false);


        })
    },[]);

    const handleChange = (event) => {
        event.preventDefault();

        switch (event.target.name) {
            case "from_date":
                set_from_date(event.target.value);
                break;
            case "to_date":
                set_to_date(event.target.value);
                break;
            case "company":
                set_company(event.target.value);
                break;
            case "webservice":
                set_webservice(event.target.value);
                break;
            case "ref_id_1":
                set_ref_id_1(event.target.value);
                break;
            case "ref_id_2":
                set_ref_id_2(event.target.value);
                break;
            case "response":
                set_response(event.target.value);
                break;
            default: break;
        }
    }

    const handleSubmit = () => {
        setLoading(true);
        set_logs([]);
        set_logs_data([]);
        let data = {
            from_date: from_date,
            to_date: to_date,
            company_id: params.id,
            webservice_id : webservice,
            ref_id_1:ref_id_1,
            ref_id_2:ref_id_2,
            response: response
        }
        set_filter(data);
        getLogs(1, data)
            .then(data => {
                set_logs(data.data.data);
                set_logs_data(data.data);
                setLoading(false);

            })
    }
 
    return (
        <DashboardContent title="Logs">
            <Paper elevation={2} >
                <Box p={2}>
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }} alignItems="center" justifyContent="center">
                    {/*<Grid item xs={12} sm={12} md={3}>*/}
                    {/*    <Box p={2}>*/}
                    {/*        <FormControl fullWidth>*/}

                    {/*            <TextField label="From Date" type="date" variant="outlined" InputLabelProps={{ shrink: true }} name="from_date" onChange={handleChange} value={from_date} />*/}

                    {/*        </FormControl>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={12} md={3}>*/}
                    {/*    <Box p={2}>*/}
                    {/*        <FormControl fullWidth>*/}

                    {/*            <TextField label="To Date" type="date" variant="outlined" InputLabelProps={{ shrink: true }} name="to_date" onChange={handleChange} value={to_date} />*/}

                    {/*        </FormControl>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={12} md={3}>*/}
                    {/*    <Box p={2}>*/}
                    {/*        <FormControl fullWidth>*/}

                    {/*            <TextField label="Ref ID 1" type="text" variant="outlined" InputLabelProps={{ shrink: true }} name="ref_id_1" onChange={handleChange} value={ref_id_1} />*/}

                    {/*        </FormControl>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={12} md={3}>*/}
                    {/*    <Box p={2}>*/}
                    {/*        <FormControl fullWidth>*/}

                    {/*            <TextField label="Ref ID 2" type="text" variant="outlined" InputLabelProps={{ shrink: true }} name="ref_id_2" onChange={handleChange} value={ref_id_2} />*/}

                    {/*        </FormControl>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}

                    <Grid item xs={12} sm={12} md={3}>
                        <Box p={2}>
                            <FormControl fullWidth>

                                <TextField label="Search" type="text" variant="outlined" InputLabelProps={{ shrink: true }} name="response" onChange={handleChange} value={response} />

                            </FormControl>
                        </Box>
                    </Grid>
                   
                   
                    {/*<Grid item xs={12} sm={12} md={3}>*/}
                    {/*    <Box p={2}>*/}
                    {/*        <FormControl fullWidth>*/}

                    {/*            <InputLabel id="demo-simple-select-label">WebService</InputLabel>*/}
                    {/*            <Select*/}
                    {/*                labelId="demo-simple-select-label"*/}
                    {/*                id="demo-simple-select"*/}
                    {/*                value={webservice}*/}
                    {/*                name="webservice"*/}
                    {/*                onChange={handleChange}*/}
                    {/*                label="WebService"*/}
                    {/*            >*/}
                    {/*                {*/}
                    {/*                    webservices && webservices.map((item) => {*/}

                    {/*                        return (<MenuItem value={item.id}>{item.name}</MenuItem>)*/}
                    {/*                    })*/}
                    {/*                }*/}
                    {/*            </Select>*/}
                    {/*        </FormControl>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={12} md={3}>
                        <Box p={2}>
                            <FormControl fullWidth>

                                <Button type="submit" variant="outlined" onClick={handleSubmit} >SEARCH</Button>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <div className='logs-tbl'>
                <LogsTable logs={logs} logs_data={logs_data} handleChangePage={handleChangePage}/>
                </div>
                 
                </Box>
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            
        </DashboardContent>
    );
}

export default LogsSingleCompany;