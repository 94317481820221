import React from 'react'
import { Outlet , Navigate } from "react-router-dom";

const PrivateRoute = () =>{
    let auth = {'token':localStorage.getItem('token')};
    
    return !auth.token ? <Navigate to="/login" /> : <Outlet/>;
            
      
    
}

export default PrivateRoute;