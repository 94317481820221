import { getUserCompany, getUserRole } from './Auth';
import {base_url} from './baseurl';



export function getXmls(page,id,data=""){
    let company_id = id;
    if(getUserRole() == 3){
        company_id = getUserCompany(); 
    }
    
    return fetch(base_url+'xml/'+company_id+'?page='+page+'&'+new URLSearchParams(data),{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export function saveXml(data){

    
    return fetch(base_url+'xml',{
       method:'POST',
       headers:{
        //    "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:data

      
   })
   .then(res=>res.json())
  
}
export function deleteXml(id){

    
    return fetch(base_url+'xml/'+id,{
       method:'DELETE',
       headers:{
        //    "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       }

      
   })
   .then(res=>res.json())
  
}

export function processXml(data){
    
    
    return fetch(base_url+'processXml',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
       body:data
   })
   .then(res=>res.json())
  
}



export function uploadXmlToFTP(data){
    
    
    return fetch(base_url+'uploadXml',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
       body:data
   })
   .then(res=>res.json())
  
}

export function getFilesFromFtp(data){
    
    
    return fetch(base_url+'getFilesFromFtp',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
       body:JSON.stringify(data)
   })
   .then(res=>res.json())
  
}


export function getFulfillables(data){
    
    
    return fetch(base_url+'getFulfillableXml',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
       body:JSON.stringify(data)
   })
   .then(res=>res.json())
  
}




export function getXmlContent(id){

    
    return fetch(base_url+'getxmlcontent/'+id,{
       method:'GET',
       headers:{
        //    "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       }

      
   })
   .then(res=>res.json())
  
}


export function saveXmlContent(data){

    // //console.log(data)
    return fetch(base_url+'savexmlcontent',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:JSON.stringify(data)

      
   })
   .then(res=>res.json())
  
}

export function processBulkXml(data){

    // //console.log(data)
    return fetch(base_url+'process-bulk-xmls',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:JSON.stringify(data)

      
   })
   .then(res=>res.json())
  
}
