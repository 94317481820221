import {  Backdrop, Button, CircularProgress, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import XmlsTable from '../../components/tables/XmlTable';
import { getUserCompany, getUserRole } from '../../apis/Auth';
import { getJson } from '../../apis/Json';
import JsonTable from '../../components/tables/JsonTable';

const Json = (props) => {
    const params = useParams();
    const [xmls,set_xmls] = useState([]);
    const [xmls_data,set_xmls_data] = useState([]);
    const [page,setpage] = useState(1);
    const [loading,setLoading] = useState(true);
    const [processed,setProcessed] = useState("");
    const [filter,set_filter] = useState([]);
    const navigate=  useNavigate();

    

    const handleChangePage=(event,value)=>{
       
        setLoading(true);
        
        set_xmls([]);
        set_xmls_data([]);
        let data = {
            processed: processed,
        }
        set_filter(data);
        setpage(value);
        
        getJson(value,params.id,filter)
        .then(data=>{
            // //console.log(data.data);
            set_xmls(data.data.data);
            set_xmls_data(data.data);
            setLoading(false);
        })
        
    }
    useEffect(()=>{
        if(getUserRole() == 3){
            params.id = getUserCompany();
        }
        //console.log(params.id)
        
        getJson(1,params.id)
        .then((data)=>{
            if(data.success){
                set_xmls(data.data.data);
                set_xmls_data(data.data);
            }else{
                navigate('/');
            }
            setLoading(false);


        })
    },[]);

    const handleChange = (e)=>{
        switch(e.target.name){
            case "processed":
                setProcessed(e.target.value);
                break;
            default:break;
        }
    }
    const handleClick = ()=>{
        setLoading(true);
        set_xmls([]);
        set_xmls_data([]);
        let data = {
            processed: processed,
        }
        set_filter(data);
        getJson(1, params.id,data)
            .then(data => {
                set_xmls(data.data.data);
                set_xmls_data(data.data);
                setLoading(false);

            })
        
    }

 
    return (
        <DashboardContent title="Your XMLS">
            <Paper elevation={2} >
                <Box p={2}>
                    <Typography
                    component="h2"
                    variant="h6"
                    color="inherit"
                    mb={4}
                    noWrap
                    sx={{ flexGrow: 1 }}
                    
                >
                    Filter 
                </Typography>
                
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
                    <Grid item xs={12} sm={12} md={3}>
                        <Box p={2}>
                            <FormControl fullWidth>

                            <InputLabel id="demo-simple-select-label">Processed</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={processed}
                                    name="processed"
                                    onChange={handleChange}
                                    label="Processed"
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="1">Processed</MenuItem>
                                    <MenuItem value="0">Unprocessed</MenuItem>
                                    
                                </Select>

                            </FormControl>
                        </Box>
                        <Box p={2}>
                        <FormControl >
                                <Button variant="outlined" onClick={handleClick} >Search</Button>
                        </FormControl>
                        </Box>
                    </Grid>
                </Grid>

                </Box>
            </Paper>   
            <Box p={2}/>
            <Paper elevation={2} >
                <Box p={2}>
                 <JsonTable xmls={xmls} xmls_data={xmls_data} handleChangePage={handleChangePage} filter={filter}/>
                </Box>
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Fab color="secondary" aria-label="edit"  onClick={()=>{navigate('/company/json/'+params.id)}} 
            sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(5)
            }}>
                <AddIcon />
            </Fab>
        </DashboardContent>
    );
}

export default Json;