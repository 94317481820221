import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Backdrop, Card, CardHeader, CircularProgress } from '@mui/material';
// utils
import { fNumber } from '../components/utils/formatNumber';
// components
import { useChart } from '../components/chart';
import { getOrderCharts, getOrderShippedCharts } from '../apis/charts/OrdersChartApi';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 600;
const LEGEND_HEIGHT = 100;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------




export default function ShippedOrdersApexChart(props) {
  const theme = useTheme();

  const [chart, setChart] = useState({})
  const [loading,setLoading] = useState(true);
  const [chartData,setChartData] = useState([]);
  useEffect(() => {
    setLoading(true);
    let data = {
      days: props.parameter
    }

    getOrderShippedCharts(data)
    .then(data=>{
        setChart(data);
        setLoading(false);
    })
  }, [])
  const chartLabels = chart?.data?.map(x => x.name);

  const chartSeries = chart?.data?.map(x => x.orders_shipped?.length);



  const chartOptions = useChart({
    colors:  [
        'rgba(55, 80, 93, 1)',
        'rgba(91, 39, 52, 1)',
        'rgba(190, 144, 99, 1)',
        'rgba(147, 154, 67, 1)',
        'rgba(215, 65, 65, 1)',
        'rgba(241, 177, 30, 1)',
        'rgba(203, 114, 52, 1)',
        'rgba(190, 144, 99, 1)',
        'rgba(91, 39, 52, 1)',
        'rgba(190, 144, 99, 1)'
      ],
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card >
        
       
      <CardHeader title={"Packed Orders"} subheader={"Company Wise"} />
    
      <StyledChartWrapper dir="ltr">
        {
            !loading &&(
                <ReactApexChart type="pie" series={chartSeries} options={chartOptions} height={500} />
            )
        }
      </StyledChartWrapper>
    </Card>
  );
}