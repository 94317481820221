import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import { Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, Pagination, TableContainer, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {  deleteFtp, getFtps, saveFtp } from '../../apis/Ftp';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
export default function FtpTable(props) {

    const lock = props.lock;
    const [loading,setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [host,set_host] = useState("");
    const [username,set_username] = useState("");
    const [password,set_password] = useState("");
    const [secure,set_secure] = useState(0);
    const [port,set_port] = useState(22);
    const [ftps, set_ftps] = useState([]);

    useEffect(()=>{
        setLoading(true);

        getFtps(company_id)
        .then(data=>{
            set_ftps(data.data);
        })
        setLoading(false);
    },[])

    const [errors,set_errors] = useState([]);
    
  
    const company_id = props.company;

    const page = props.page;

    const handleChange=(event)=>{
        event.preventDefault();
   
        switch(event.target.name){
            case "host":
                set_host(event.target.value);
                break;
            case "username":
                set_username(event.target.value);
                break;
            case "port":
                set_port(event.target.value);
                break;
            case "secure":
                set_secure(!secure);
                break;
            case "password":
                set_password(event.target.value);
                break;
            default: break;
        }
    }
    

    const handleFtpSave=()=>{
        set_errors([]);
        setLoading(true);

       const data = {
            host:host,
            username:username,
            port:port,
            password:password,
            secure:secure,
            company_id:company_id

       }
       saveFtp(JSON.stringify(data))
       .then(data=>{
        
        if(!data.success){
            set_errors(data.data)
        }
        else{
            toast.success(data.message);
            set_port(22);
            set_username("");
            set_secure(false);
            set_host("");
            getFtps(company_id)
            .then(data=>{
                set_ftps(data.data);
            })

        }
        setTimeout(function(){
            setLoading(false);
            handleClose();

        },1000)
       })
    }

  
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
      const handleMaxWidthChange = (event) => {
        setMaxWidth(
          // @ts-expect-error autofill of arbitrary value is not handled.
          event.target.value,
        );
      };
    
      const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
      };

  
    return (

        <React.Fragment >

<Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
         <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        <DialogTitle>FTP</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Box mb={2} />
            <FormControl fullWidth>

            <TextField  required label="Host" variant="outlined" InputLabelProps={{ shrink: true }} name="host" onChange={handleChange} value={host} 
                error={errors.host!=undefined?true:false}
                helperText={errors.host!==undefined?errors.host:''}
                />

            </FormControl>
            <Box mb={2} />
            <FormControl fullWidth>

            <TextField  required label="Username" variant="outlined" InputLabelProps={{ shrink: true }} name="username" onChange={handleChange} value={username} 
                error={errors.username!=undefined?true:false}
                helperText={errors.username!==undefined?errors.username:''}
                />

            </FormControl>
            <Box mb={2} />
            <FormControl fullWidth>

            <TextField  required type="password" label="Password" variant="outlined" InputLabelProps={{ shrink: true }} name="password" onChange={handleChange} value={password} 
                error={errors.password!=undefined?true:false}
                helperText={errors.password!==undefined?errors.password:''}
                />

            </FormControl>
            <Box mb={2} />
            <FormControl fullWidth>

            <TextField   label="Port" variant="outlined" type="number" InputLabelProps={{ shrink: true }} name="port" onChange={handleChange} value={port} 
                error={errors.port!=undefined?true:false}
                helperText={errors.port!==undefined?errors.port:''}
                />

            </FormControl>
            <Box mb={2} />
            <FormControl fullWidth>
            <FormControlLabel
                value="end"
                checked={secure}
                onChange={handleChange}
                control={<Checkbox  />}
                label="Secure"
                name="secure"
                labelPlacement="end"
                />
            </FormControl>
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
          
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFtpSave}>Save</Button>
          <Button onClick={handleClose}>Close</Button>

        </DialogActions>
      </Dialog>
                <ToastContainer/>
                <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                FTPS &nbsp;
                <Button variant="outlined"   onClick={handleClickOpen} >ADD</Button>

            </Typography>
         
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Host</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Port</TableCell>
                            <TableCell>Secure</TableCell>
                            <TableCell>Date Created</TableCell>
                            <TableCell>Actions</TableCell>



                            


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ftps.map((row) => {
                           

                            return(
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.host}</TableCell>
                                <TableCell>{row.username}</TableCell>
                                <TableCell>{row.port}</TableCell>
                                <TableCell>{row.secure}</TableCell>
                                <TableCell>{moment(row.created_at).format("DD/MM/YYYY hh:mm:ss a")}</TableCell>
                                <TableCell>
                                <IconButton disabled={lock} onClick={()=>{
                                        let confirm_delete  =  window.confirm("Do you want to delete this record");
                                        if(confirm_delete){
                                            setLoading(true);
                                            deleteFtp(row.id)
                                            .then((data)=>{
                                                setLoading(false);
                                                if(data.success){
                                                    getFtps(company_id)
                                                    .then(data=>{
                                                        set_ftps(data.data)
                                                        toast.success(data.message)
                                                    })
                                                }else{
                                                    toast.error(data.data.message);
                                                }
                                            })
                                        }
                                    }}>
                                        <DeleteIcon color="warning" />
                                    </IconButton>

                                    </TableCell>
                            </TableRow>
                        )})}
                      
                    </TableBody>
                    
                </Table>
            
            </TableContainer>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}
