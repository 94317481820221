import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import { Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, Pagination, TableContainer, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import LoginIcon from '@mui/icons-material/Login';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { getWarehouseUser, loginByUserID } from '../../apis/Auth';
import { useNavigate } from 'react-router-dom';

export default function WarehouseUserTable(props) {

  const navigate = useNavigate();

    const [loading,setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [host,set_host] = useState("");
    const [username,set_username] = useState("");
    const [password,set_password] = useState("");
    const [secure,set_secure] = useState(0);
    const [port,set_port] = useState(22);
    const [warehouse_users, set_warehouse_users] = useState([]);

    useEffect(()=>{
        setLoading(true);

        getWarehouseUser()
        .then(data=>{
            set_warehouse_users(data.data);
        })
        setLoading(false);
    },[])



    const [errors,set_errors] = useState([]);
    
  

    const page = props.page;

  
  
    return (

        <React.Fragment >


                <ToastContainer/>
                <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                Warehouse Users &nbsp;
               

            </Typography>
         
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Date Created</TableCell>
                            <TableCell>Actions</TableCell>



                            


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {warehouse_users.map((row) => {
                           

                            return(
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{moment(row.created_at).format("DD/MM/YYYY hh:mm:ss a")}</TableCell>
                                <TableCell>
                                        <IconButton onClick={()=>{
                                            if(!window.confirm("Are you sure to logout from this account and login into "+row.email+' account.')){
                                                return false;
                                            }

                                            setLoading(true);
                                            alert(row.id)
                                            loginByUserID(row.id)
                                            .then(data=>{
                                                console.log(data);

                                                setLoading(false);
                                                if(data.success){
                                                if(data.data.user.email_verified_at!=null){

                                                    localStorage.clear();
                                                    localStorage.setItem("token","Bearer "+data.data.token);
                                                    localStorage.setItem("user",data.data.user);
                                                    localStorage.setItem("username",data.data.user.name);
                                                    localStorage.setItem("role",data.data.user.role);
                                                    localStorage.setItem("company_id",data.data.user.company_id);
                                                    localStorage.setItem("avatar",data.data.user.avatar);
                                                    localStorage.setItem("warehouse_image",data.data.user.warehouse_image);
                                                    localStorage.setItem("is_admin",data.data.user.is_admin);




                                                

                                                    navigate("/");
                                                }else{
                                                    toast.error("Account is not verified yet!");
                                                }
                                                }
                                            })
                                        }}>  <LoginIcon color="warning" /></IconButton>
                                 
                                
                                <IconButton onClick={()=>{
                                        let confirm_delete  =  window.confirm("Do you want to delete this record");
                                        if(confirm_delete){
                                            setLoading(true);
                                            // deleteFtp(row.id)
                                            // .then((data)=>{
                                            //     setLoading(false);
                                            //     if(data.success){
                                            //         getFtps(company_id)
                                            //         .then(data=>{
                                            //             set_ftps(data.data)
                                            //             toast.success(data.message)
                                            //         })
                                            //     }else{
                                            //         toast.error(data.data.message);
                                            //     }
                                            // })
                                        }
                                    }}>
                                        <DeleteIcon color="warning" />
                                    </IconButton>

                                    </TableCell>
                                  
                            </TableRow>
                        )})}
                      
                    </TableBody>
                    
                </Table>
            
            </TableContainer>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}
