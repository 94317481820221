import * as React from 'react';
import {  toast,ToastContainer } from 'react-toastify';
import { login } from '../../apis/Auth';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Alert, Avatar, Box, CssBaseline, Grid, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PublicLayout from '../../layouts/PublicLayout';
import { useEffect } from 'react';


const DirectLogin = ()=>{
    const params = useParams();

   useEffect(()=>{

    localStorage.clear();
   
   },[])

 


    const navigate = useNavigate();

   
        if(params.email==""){
            toast.error("Email is required");
            return false;
          }
          else if(params.password==""){
            toast.error("Password is required");
            return false;
          }
          else{
      
          
          let data_api = {
            email: params.email,
            password: params.password,
      
          };

          console.log(data_api);
          
         
         login(data_api)
         .then((data)=>{
          if(data.success){
             if(data.data.user.email_verified_at!=null){
      
              localStorage.clear();
              localStorage.setItem("token","Bearer "+data.data.token);
              localStorage.setItem("user",data.data.user);
              localStorage.setItem("username",data.data.user.name);
              localStorage.setItem("role",data.data.user.role);
              localStorage.setItem("company_id",data.data.user.company_id);
              localStorage.setItem("avatar",data.data.user.avatar);
              localStorage.setItem("warehouse_image",data.data.user.warehouse_image);
              localStorage.setItem("is_admin",data.data.user.is_admin);
              localStorage.setItem("source",data.data.user.source?.code);
      
      
      
      
      
            
      
              navigate("/");
             }else{
              toast.error("Account is not verified yet!");
             }
              
          }else{
            toast.error("Email/Password are wrong!");
          }
        });
    }
        
    

    return(
        <PublicLayout>
        
        <ToastContainer/>
        <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >

        <Alert severity="info">We are logging you to syncroneo. Please wait for 5 seconds</Alert>




          
          
          </Box>
        
      </PublicLayout>
    )

}

export default DirectLogin;