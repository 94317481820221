import {base_url,base_web_url} from './baseurl';
import warehouse_placeholder from '../assets/images/warehouse-image.png';
import avatar_placeholder from '../assets/images/avatar-default.png';


const auth_cred ={
    email: "admin@gmail.com",
    password: "123456"
}
export function login(data){
    
    return fetch(base_url+'login',{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            "accept":"application/json"
        },
        body:JSON.stringify(data)
    })
    .then(res=>res.json())
   
}
export function register(data){
    
    return fetch(base_url+'register',{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
        },
        body:JSON.stringify(data)
    })
    .then(res=>res.json())
   
}

export function sendOtp(email){
    
    return fetch(base_url+'request_otp?email='+email,{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
        },
    
       
    })
    .then(res=>res.json())
   
}
export function verifyOtp(data){
    
    return fetch(base_url+'verify_otp',{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
        },
        body:JSON.stringify(data)
    
       
    })
    .then(res=>res.json())
   
}
export function getUser(){
    
    return fetch(base_url+'getUser',{
        method:'GET',
        headers:{
            "Authorization":localStorage.getItem("token"),
            "Content-Type":"application/json",
        },
    
    
       
    })
    .then(res=>res.json())
   
}
export function updateUser(data){
    
    return fetch(base_url+'updateUser',{
        method:'PUT',
        headers:{
            "Authorization":localStorage.getItem("token"),
            "Content-Type":"application/json",
        },
        body:JSON.stringify(data)
    
       
    })
    .then(res=>res.json())
   
}

export function createClientUser(data,company_id){

    return fetch(base_url+'auth/client_user/'+company_id,{
        method:'POST',
        headers:{
            "Authorization":localStorage.getItem("token"),
            "Content-Type":"application/json",
        },
        body:JSON.stringify(data)
    
       
    })
    .then(res=>res.json())
}

export function getCompanyID(){
    return localStorage.getItem("company_id");
}

export function getClientUser(company_id){

    return fetch(base_url+'auth/client_user/'+company_id,{
        method:'GET',
        headers:{
            "Authorization":localStorage.getItem("token"),
            "Content-Type":"application/json",
        },
    
       
    })
    .then(res=>res.json())
}

export function getWarehouseUser(company_id){

    return fetch(base_url+'getWarehouseUser/',{
        method:'GET',
        headers:{
            "Authorization":localStorage.getItem("token"),
            "Content-Type":"application/json",
        },
    
       
    })
    .then(res=>res.json())
}

export function deleteClientUser(user_id){

    return fetch(base_url+'auth/client_user/'+user_id,{
        method:'DELETE',
        headers:{
            "Authorization":localStorage.getItem("token"),
            "Content-Type":"application/json",
        },
    
       
    })
    .then(res=>res.json())
}


export function updateAvatar(data){
    return fetch(base_url+'updateAvatar',{
        method:'POST',
        headers:{
            "Authorization":localStorage.getItem("token"),
            // "Content-Type":"application/json",
        },
        body:data
    
       
    })
    .then(res=>res.json())
 }

 export function updateWarehouseImage(data){
    return fetch(base_url+'updateWarehouseImage',{
        method:'POST',
        headers:{
            Authorization:localStorage.getItem("token")
        },
        body:data
    })
    .then(res=>res.json())
 }
export function getUserRole(){
    return localStorage.getItem('role');
}
export function getUserCompany(){
    return localStorage.getItem('company_id');
}

export function getUserAvatar(){
    if(localStorage.getItem('avatar') && localStorage.getItem('avatar')!="null"){
        // //console.log(base_web_url+""+localStorage.getItem('avatar'));
        
        let url = localStorage.getItem('avatar');
        url = url.substring(url.lastIndexOf('/', url.lastIndexOf('/')-1));
        
            return base_web_url+'public/storage/avatars'+url;
        
    }
    else{
        return avatar_placeholder;
    }
}


export function getCompanyAvatar(url){
    if(localStorage.getItem('avatar') && localStorage.getItem('avatar')!="null"){
        // //console.log(base_web_url+""+localStorage.getItem('avatar'));
        
        url = url.substring(url.lastIndexOf('/', url.lastIndexOf('/')-1));
        
            return base_web_url+'storage/company/avatars'+url;
        
    }
    else{
        return avatar_placeholder;
    }
}

export function getWarehouseImage(){
    if(localStorage.getItem('warehouse_image') && localStorage.getItem('warehouse_image')!="null"  ){
        // //console.log(base_web_url+""+localStorage.getItem('avatar'));
        let url = localStorage.getItem('warehouse_image');
        url = url.substring(url.lastIndexOf('/', url.lastIndexOf('/')-1));
         return base_web_url+'public/storage/warehouse_images'+url;
    }
    else{
        return warehouse_placeholder;
    }   
}

export function isAdmin(){
    // //console.log("IS_ADMIN: "+localStorage.getItem('is_admin'));
    return localStorage.getItem('is_admin')==1?1:0;
}



export function loginByUserID(id){
    
    return fetch(base_url+'loginuserbyid/'+id,{
        method:'GET',
        headers:{
            Authorization:localStorage.getItem("token"),
            "Content-Type":"application/json",
            "accept":"application/json"
        },
    })
    .then(res=>res.json())
   
}

export function saveUserWebUrl(data){
    
    return fetch(base_url+'saveUserWebUrl/',{
        method:'POST',
        headers:{
            Authorization:localStorage.getItem("token"),
            "Content-Type":"application/json",
            "accept":"application/json"
        },
        body:JSON.stringify(data)
    })
    .then(res=>res.json())
   
}

export function getPakaneoToken(id){
    return fetch(base_url+'generatePakaneoToken/'+id,{
        method:"POST",
        headers:{
            Authorization:localStorage.getItem("token"),
            "Content-Type":"application/json",
            "accept":"application/json"
        }

    })
        .then(res=>res.json());
}

export function createAPIUser(data,company_id){

    return fetch(base_url+'auth/api_user/'+company_id,{
        method:'POST',
        headers:{
            "Authorization":localStorage.getItem("token"),
            "Content-Type":"application/json",
        },
        body:JSON.stringify(data)
    
       
    })
    .then(res=>res.json())
}


export function getAPIUser(company_id){

    return fetch(base_url+'auth/api_user/'+company_id,{
        method:'GET',
        headers:{
            "Authorization":localStorage.getItem("token"),
            "Content-Type":"application/json",
        },
    
       
    })
    .then(res=>res.json())
}
