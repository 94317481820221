import {  Backdrop, Button, CircularProgress, Fab, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { createOrder, getOrders } from '../../apis/Orders';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import OrdersTable from '../../components/tables/OrdersTable';
import { getAllCompanies, getCompanies } from '../../apis/Company';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'; 
import $ from 'jquery';
import { toast } from 'react-toastify';
const CreateOrder = (props) => {
    
    const params = useParams()

    const [orders,set_orders] = useState([]);
    const [orders_data,set_orders_data] = useState([]);
    const [from_date,set_from_date] = useState("");
    const [to_date,set_to_date] = useState("");
    const [companies,set_companies] = useState([]);
    const [search,set_search] = useState("");
    const [exported,set_exported] = useState("");

    const [company,set_company] = useState([]);
    const [filter,set_filter]=useState([]);


    let id = params.id;


    const [loading,setLoading] = useState(false);
    const [page,setpage] = useState(1);

    $('.add-product-row').off('click').on('click',function(e){
        console.log(e.target)
        console.log('ss');
           e.preventDefault();     
        $('#product_row').append(
                `<div  class='product-row'  >
                    <div class='flex'>
                        <input type="number" name="items[][ean]" placeholder="EAN"/>
                        <input type="number" name="items[][qty]" placeholder="Qty"/>
                        <input type="number" name="items[][price]" placeholder="Price"/>
                        <input type="number" name="items[][vat]" placeholder="Vat"/>
                        <a class="add-product-row"  href="#!">+</a>
                        <a class="remove-product-row" href="#!">-</a>

                    </div>


            </div>`
        )
    })

    $('.remove-product-row').off('click').on('click',function(e){
           e.preventDefault();     
            $(this).parent().parent().remove();    
    })
               
            
   

   
    const submitForm=(e)=>{
        e.preventDefault();
        setLoading(true);

        let data = new FormData();

        let external_order_id = $('input[name=external_order_id').val()
        console.log(external_order_id);
        data.append('external_order_id', external_order_id)

        let company = $('input[name=company').val()
        data.append('company', company)

        let first_name = $('input[name=first_name').val()
        data.append('first_name', first_name)

        let last_name = $('input[name=last_name').val()
        data.append('last_name', last_name)

        let street = $('input[name=street').val()
        data.append('street', street)

        let street_no = $('input[name=street_no').val()
        data.append('street_no', street_no)

        let city = $('input[name=city').val()
        data.append('city', city)

        let zip = $('input[name=zip').val()
        data.append('zip', zip)

        let country = $('input[name=country').val()
        data.append('country', country)

        let order_date = $('input[name=order_date').val()
        data.append('order_date', order_date)

        let payment_date = $('input[name=payment_date').val()
        data.append('payment_date', payment_date)
        
        if($('input[name="invoice_note"]').val()){
        var invoice_note = document.querySelector('input[name="invoice_note"]')
        data.append('invoice_note', invoice_note.files[0])
        }

        if($('input[name="delivery_note"]').val()){
        var delivery_note = document.querySelector('input[name="delivery_note"]')
        data.append('delivery_note', delivery_note.files[0])
        }

    
        let eans = []
        $("input[name='items[][ean]']").each(function(index){
            eans[index] = $(this).val()

        })
        data.append('eans',eans);

        let qty = []
        $("input[name='items[][qty]']").each(function(index){
            qty[index] = $(this).val()

        })
        data.append('qty',qty);

        let price = []
        $("input[name='items[][price]']").each(function(index){
            price[index] = $(this).val()

        })
        data.append('price',price);


        let vat = []
        $("input[name='items[][vat]']").each(function(index){
            vat[index] = $(this).val()

        })
        data.append('vat',vat);

        createOrder(data,params.id)
        .then(data=>{
          if(data.success){
            setLoading(false);
            toast.success(data.message);

          }else{
            setLoading(false);
            toast.error(data.message)
          }
        })


    




    

    }    
    
    
    
    

  
 

 
    return (
        <DashboardContent title="Create Order">
            <Paper elevation={2} > 
            <form   id="create_order_form">
            <Box noValidate  sx={{ mt: 3 }} p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="external_order_id"
                  
                  fullWidth
                  id="external_order_id"
                  label="External Order ID"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="company"
                  
                  fullWidth
                  id="company"
                  label="Company"
                  autoFocus
                />
              </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="first_name"
                  
                  fullWidth
                  id="last_name"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="last_name"
                  
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  autoFocus
                />
              </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={10}>
                <TextField
                  autoComplete="given-name"
                  name="street"
                  
                  fullWidth
                  id="street"
                  label="Street"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  autoComplete="given-name"
                  name="street_no"
                  
                  fullWidth
                  id="street_no"
                  label="Street Number"
                  autoFocus
                />
              </Grid>
              </Grid>

              <Grid container spacing={2} mt={2}>
             
              <Grid item xs={12} sm={4}>
                <TextField
                  autoComplete="given-name"
                  name="city"
                  
                  fullWidth
                  id="city"
                  label="City"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  autoComplete="given-name"
                  name="zip"
                  
                  fullWidth
                  id="zip"
                  label="Zip"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  autoComplete="given-name"
                  name="country"
                  
                  fullWidth
                  id="country"
                  label="Country"
                  autoFocus
                />
              </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={6}>
                Order Date
                <TextField
                  type='date'
                  autoComplete="given-name"
                  name="order_date"
                  
                  fullWidth
                  id="order_date"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Payment Date
                <TextField
                    type='date'
                  autoComplete="given-name"
                  name="payment_date"
                  
                  fullWidth
                  id="payment_no"
                  autoFocus
                />
              </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={6}>
                Invoice Note
                <TextField
                  type='file'
                  autoComplete="given-name"
                  name="invoice_note"
                  
                  fullWidth
                  id="invoice_note"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Delivery Note
                <TextField
                    type='file'
                  autoComplete="given-name"
                  name="delivery_note"
                  
                  fullWidth
                  id="delivery_note"
                  autoFocus
                />
              </Grid>
              </Grid>

            
              <Grid container spacing={2} mt={2} id="product_row" p={2}>
              <div  className='product-row' >
                    <div className='flex'>
                        <input type="number" name="items[][ean]" placeholder="EAN"/>
                        <input type="number" name="items[][qty]" placeholder="Qty"/>
                        <input type="number" name="items[][price]" placeholder="Price"/>
                        <input type="number" name="items[][vat]" placeholder="Vat"/>
                        <a class="add-product-row"  href="#!">+</a>

                    </div>
                </div>
          

              </Grid>

              <Button type="submit" variant="outlined" onClick={e=>submitForm(e)}  >Save Order</Button>

            </Box>
            </form>

            
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        
            
        </DashboardContent>
    );
}

export default CreateOrder;
