import React,{useEffect, useState} from 'react';
import {  toast,ToastContainer } from 'react-toastify';
import '../../access-denied.css';

const AccessDenied = () => {
    
   return(
    <>
    <div className="w3-display-middle">
    <h1 className="w3-jumbo w3-animate-top w3-center"><code>Access Denied</code></h1>
    <h3 className="w3-center w3-animate-right">You dont have permission to view this site.</h3>
    <h3 className="w3-center w3-animate-zoom">🚫🚫🚫🚫</h3>
    <h6 className="w3-center w3-animate-zoom">error code:403 forbidden</h6>
    </div>
    </>
    );
}

export default AccessDenied;