import {base_url} from './baseurl';



export function getSettings(company_id){
    
    
    return fetch(base_url+'settings/'+company_id,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
   
}


export function saveSettings(data){
    
    
    return fetch(base_url+'settings',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
       body:data
   })
   .then(res=>res.json())
  
   
}