import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Backdrop, CircularProgress } from '@mui/material';
// components
import { useChart } from '../components/chart';
import { getOverviewCharts } from '../apis/charts/OverviewChart';
import { useEffect, useState } from 'react';
import { getCarrierChart } from '../apis/charts/CarrierOrderApi';

// ----------------------------------------------------------------------

export default function CarrierOrdersChart(props) {
    const [chart, setChart] = useState({})
    const [loading,setLoading] = useState(true);
    const [chartData,setChartData] = useState([]);
    useEffect(() => {
      setLoading(true);
      let data = {
        days: props.parameter,
        company:props.company,
      }
  
      getCarrierChart(props.company,props.days)
      .then(data=>{
        //console.log(data.data);
        
          setChart(data.data.data);
          let chartData=[
            {
              name: 'Total Orders',
              type: 'column',
              fill: 'solid',
              data: data.data.data.count,
            },
            {
              name: 'Packed Orders',
              type: 'area',
              fill: 'gradient',
              data: data.data.data.count_packed,
            },
          
          ]
          setChartData(chartData)
          setLoading(false);
      }).catch(err=>{
        setLoading(false);
      })
      

    
    }, [])
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '20%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chart?.labels,
    xaxis: { type: 'text' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} orders`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card >
        
      <CardHeader title={"Carriers"}  />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr" className="relative" >
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => { return; }}
      >
          <CircularProgress color="inherit" />
        </Backdrop>
        {
            !loading &&(

                <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
            )
        }
      </Box>
    </Card>
  );
}