import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Backdrop, CircularProgress } from '@mui/material';
// components
import { useChart } from '../components/chart';
import { getOverviewCharts } from '../apis/charts/OverviewChart';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function OverviewApexChart(props) {
    const [chart, setChart] = useState({})
    const [loading,setLoading] = useState(true);
    const [chartData,setChartData] = useState([]);
    useEffect(() => {
      setLoading(true);
      let data = {
        days: props.parameter
      }
  
      getOverviewCharts(data)
      .then(data=>{
        //console.log(data.data);
        
          setChart(data.data);
          let chartData=[
            {
              name: 'Total Orders',
              type: 'column',
              fill: 'solid',
              data: data.data.total_orders.data,
            },
            {
              name: 'Packed Orders',
              type: 'area',
              fill: 'gradient',
              data: data.data.total_order_shippable.data,
            },
          
          ]
          setChartData(chartData)
          setLoading(false);
      })
    }, [])
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chart?.total_orders?.labels,
    xaxis: { type: 'text' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} orders`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card >
        
      <CardHeader title={"Overview"} subheader={"For Orders."} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr" className="relative" >
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => { return; }}
      >
          <CircularProgress color="inherit" />
        </Backdrop>
        {
            !loading &&(

                <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
            )
        }
      </Box>
    </Card>
  );
}