import {  Backdrop, CircularProgress, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { getCompanies } from '../../apis/Company';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CompaniesTable from '../../components/tables/CompaniesTable';

const Company = (props) => {

    const [companies,set_companies] = useState([]);
    const [companies_data,set_companies_data] = useState([]);

    const [loading,setLoading] = useState(true);
    const [page,setpage] = useState(1);


    const handleChangePage=(event,value)=>{
       
        setLoading(true);
        set_companies([]);
        set_companies_data([]);

        setpage(value);
        
        getCompanies(value)
        .then(data=>{
            //console.log(data.data);
            set_companies(data.data.data);
            set_companies_data(data.data);
            setLoading(false);
        })
        
    }
    const navigate=  useNavigate();
    useEffect(()=>{
        getCompanies(1)
        .then((data)=>{
            if(data.success){
                set_companies(data.data.data);
                set_companies_data(data.data);
            }else{
                navigate('/');
            }
            setLoading(false);


        })
    },[]);

 
    return (
        <DashboardContent title="Your Companies">
            <Paper elevation={2} className='card-secondary'>
                <Box p={2} className="company-tbl">
                 <CompaniesTable companies={companies} companiesData={companies_data} handleChangePage={handleChangePage}/>
                </Box>
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Fab className="add-company" color="secondary" aria-label="edit"  onClick={()=>{navigate('/company/add')}}
            sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(5)
            }}>
                <AddIcon />
            </Fab>
        </DashboardContent>
    );
}

export default Company;