import {base_url} from './baseurl';

export function getCronlogs(id,page,data=""){
    
    
    return fetch(base_url+'cronlogs/'+id+'?page='+page+'&'+new URLSearchParams(data),{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}
