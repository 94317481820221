import React, { useEffect, useState ,createContext,useContext} from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Deposits from '../../components/Deposits';
import { Backdrop, Box, Card, CardHeader, CircularProgress, FormControl, InputLabel, MenuItem, NativeSelect, Select, Tab, Tabs } from '@mui/material';
import OrdersChart from '../../charts/OrdersChart';
import ShippedOrdersChart from '../../charts/ShippedOrderChart';
import OverviewChart from '../../charts/OverviewChart';
import Joyride from 'react-joyride';
import AppWidgetSummary from '../../components/Shared/AppWidgetSummary';
import OverviewApexChart from '../../charts/OverviewApexChart';
import OrdersApexChart from '../../charts/OrdersApexChart';
import ShippedOrdersApexChart from '../../charts/ShippedOrderApexChart';
import CarrierOrdersChart from '../../charts/CarrierOrdersChart';
import { getAllCompanies } from '../../apis/Company';
import SellerPlatformOrdersChart from '../../charts/SellerPlatformOrdersChart';
import { getCompanyID, getUserRole } from '../../apis/Auth';
import { getAnalytice } from '../../apis/charts/CarrierOrderApi';
import InvoiceTable from '../../InvoiceTable';


const Dashboard =(props)=>{


    const ChartContext = createContext();


  
  const [loading,setLoading]=useState(false)
  const [value, setValue] = useState(0);
  const [parameter,setParameter] = useState(7);
  const [company_selective,set_company_selective] = useState(0);
  const [company_token,set_company_token] = useState("");
  const [company_url,set_company_url] = useState("");
  const [analytics,set_analytics] = useState([]);


  const [companies,set_companies] = useState([]);
  const [parameter_data,set_parameter_data] = useState({
    parameter:7,
    company_id:0
  })

 
  const handleChangeTabsCompanies=(e,newvalue)=>{
   
            
            companies.map((item,index)=>{
                set_company_selective(newvalue);
               
                if(index == newvalue){
                    
                    parameter_data.company_id = item.id;
                    set_parameter_data(parameter_data);
                }
                console.log(parameter_data);
            })
   

  }
  useEffect(()=>{
    getAnalytice()
    .then(data=>{
        // console.log(data);
        set_analytics(data.data);
    })
    if(getUserRole()==0){
    getAllCompanies()
    .then(data=>{
        set_companies(data.data);
        data.data.map((item,index)=>{
            if(index==0){
                parameter_data.company_id=item.id;
                set_parameter_data(parameter_data);
            }
        })

    })
    }else{
        console.log(getCompanyID());
        parameter_data.company_id = getCompanyID();
        set_parameter_data(parameter_data);
    }
  },[])
  const renderCompanyCharts = ()=>{
        return(
            <Grid container spacing={10} mt={1} alignItems="center">
                <Grid item xs={12} md={6}>
                    <CarrierOrdersChart days={parameter_data.days} company={parameter_data.company_id}  />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CarrierOrdersChart days={parameter_data.days} company={parameter_data.company_id}  />
                </Grid>
            </Grid>
        )
  }
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
    
    switch(newValue){
        case 0:
            setParameter(7);
            parameter_data.parameter = 7;
            set_parameter_data(parameter_data)
            break;
        case 1:
            setParameter(0);
            parameter_data.parameter = 0;
            set_parameter_data(parameter_data)

            break;
        case 2:
            setParameter(1);
            parameter_data.parameter = 1;
            set_parameter_data(parameter_data)

            break;
        case 3:
            setParameter(14);
            parameter_data.parameter = 14;
            set_parameter_data(parameter_data)

            break;
        case 4:
            setParameter(30);
            parameter_data.parameter = 30;
            set_parameter_data(parameter_data)

            break;
        case 5:
            setParameter(360);
            parameter_data.parameter = 360;
            set_parameter_data(parameter_data)

            break;
        default:
            setParameter(7);
            parameter_data.parameter = 7;
            set_parameter_data(parameter_data)

            break;

    }
    //console.log(parameter);
    
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    function a111yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }



  
    return (
        <DashboardContent title="Dashboard">
             <Grid container spacing={2} mb={3}>
                <Grid item xs={3} md={3}>
                    <AppWidgetSummary title="Total Orders" total={analytics.total_orders} icon={'material-symbols:order-approve-outline-sharp'} className="card-dashboard" />
                </Grid>
                <Grid item xs={3} md={3}>
                    <AppWidgetSummary title="Packed Orders" total={analytics.total_orders_packed} color="info" icon={'nimbus:box-unpacked'} className="card-dashboard"/>
                </Grid>
                <Grid item xs={3} md={3}>
                    <AppWidgetSummary title="Total Companies" total={analytics.total_companies} color="warning" icon={'mdi:company'} className="card-dashboard"/>
                </Grid>
                <Grid item xs={3} md={3}>
                    <AppWidgetSummary title="Logs" total={analytics.total_logs} color="error" icon={'ant-design:bug-filled'} className="card-dashboard"/>
                </Grid>

            </Grid>
             <Grid item xs={12} sm={12} md={12}>
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={value}  variant="scrollable" scrollButtons="auto" onChange={handleChangeTabs} aria-label="basic tabs example">
                                                <Tab label="7 Days" className="my-first-step" {...a11yProps(0)} />
                                                <Tab label="Yesterday" className="my-first-step" {...a11yProps(1)} />
                                                <Tab label="Today" className="my-first-step" {...a11yProps(2)} />
                                                <Tab label="2 Weeks" className="my-other-step" {...a11yProps(3)} />
                                                <Tab label="Month" {...a11yProps(4)} />
                                                <Tab label="Year" {...a11yProps(5)} />
                                                
                                            </Tabs>
                                        </Box>
                        </Box>
            </Grid>
            
            
            <ChartContext.Provider value={parameter}>
            <Grid container spacing={10}>
          
                    <Grid item xs={12}>
                        
                        {/* <OverviewChart parameter={parameter}/> */}
                        <OverviewApexChart className='chart' parameter={parameter_data.parameter}/>

                    </Grid>

                    {
                        getUserRole()==0 && (
                            < >
                                 <Grid item xs={12} md={6}>
                        {/* <OrdersChart parameter={parameter}/> */}
                                    <OrdersApexChart parameter={parameter_data.parameter}/>
                                </Grid>
                                <Grid item xs={6}>
                            {/* <ShippedOrdersChart parameter={parameter}/> */}
                                <ShippedOrdersApexChart parameter={parameter_data.parameter}/>

                                 </Grid>
                            </>
                        )
                    }
                   
                    
                    

             </Grid>

             {
                getUserRole() == 0 &&(
             
             <Grid container spacing={10} mt={1} alignItems="center">
                <Grid item xs={12} md={12}>
                    <Card>
                    <Grid container    direction="row" alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <CardHeader title={"Company Charts "} subheader={"Select a company to generate chart"} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                        
                              <Box sx={{ width: '100%' }}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={company_selective}  variant="scrollable" scrollButtons="auto" onChange={handleChangeTabsCompanies} >
                                              
                                                {
                                                    companies.map((item,index)=>{
                                                        return(
                                                        <Tab label={item.name}  {...a111yProps(item.id)} />
                                                        )
                                                    })
                                                }
                                                
                                            </Tabs>
                                        </Box>
                                </Box>
                        </Grid>
                     

                        
                    </Grid>
                    <ChartContext.Provider  >
                    <Grid container spacing={10}  p={2} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <CarrierOrdersChart days={parameter_data.parameter} company={parameter_data.company_id}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SellerPlatformOrdersChart days={parameter_data.parameter} company={parameter_data.company_id}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={10} mt={1}  p={2} alignItems="center">
                        <Grid item xs={12} md={12}>
                                <InvoiceTable company={parameter_data.company_id}/>
                        </Grid>
                        </Grid>

                       </ChartContext.Provider>
                    </Card>


                </Grid>
                
             </Grid>
                )
                        }
                  {
                getUserRole() == 1 &&(
             
             <Grid container spacing={10} mt={1} alignItems="center">
                <Grid item xs={12} md={12}>
                    <Card>
                    <Grid container spacing={10}   direction="row" alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <CardHeader title={"Company Charts "} subheader={"Select a company to generate chart"} />
                        </Grid>
                      
                     

                        
                    </Grid>
                    <ChartContext.Provider  >
                    <Grid container spacing={10} mt={1}  p={2} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <CarrierOrdersChart days={parameter_data.parameter} company={parameter_data.company_id}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SellerPlatformOrdersChart days={parameter_data.parameter} company={parameter_data.company_id}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={10} mt={1}  p={2} alignItems="center">
                            
                            <Grid item xs={12} md={12}>
                                <InvoiceTable company={parameter_data.company_id}/>
                            </Grid>
                        </Grid>
                       </ChartContext.Provider>
                    </Card>


                </Grid>
             </Grid>
                )
                        }
            
           

             </ChartContext.Provider>
            
        </DashboardContent>
    );
}
export default Dashboard;
