import {  Backdrop, Button, CircularProgress, Fab, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../layouts/DashboardLayout';
import CronTable from '../components/tables/CronTable';
import { useParams,useNavigate } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { getCronlogs } from '../apis/Cronlog';
import CronLogTable from '../components/tables/CronLogTable';
import { getAPIUser } from '../apis/Auth';
import APIUserTable from '../components/tables/APIUserTable';

const APIAccess = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const [lock,set_lock] = useState(true);


  

    


    const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
      };


    const iconStyle = {
        color: '#fff'
      };
      



 
    return (
        <DashboardContent title="API Clients">

            
            <Paper elevation={2} >
                <Box p={2}>
                 <APIUserTable lock={lock} id={params.id}  />
                </Box>
            </Paper>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

     
          
            
        </DashboardContent>
    );
}

export default APIAccess;