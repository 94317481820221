import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';


import { Pie } from 'react-chartjs-2';
import { getOrderCharts } from '../apis/charts/OrdersChartApi';
import { Backdrop, CircularProgress } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);



const OrdersChart = (props) => {
  const [chart, setChart] = useState({})
  const [loading,setLoading] = useState(true);


  useEffect(() => {
    let data = {
      days: props.parameter
    }

    getOrderCharts(data)
    .then(data=>{
        setChart(data);
        setLoading(false);
    })
  }, [])

  //console.log("chart", chart);
  var data = {
    labels: chart?.data?.map(x => x.name),
    datasets: [{
      label: `${chart?.data?.length} Companies Available`,
      data: chart?.data?.map(x => x.orders.length),
      backgroundColor: [
        'rgba(55, 80, 93, 0.5)',
        'rgba(91, 39, 52, 0.5)',
        'rgba(190, 144, 99, 0.5)',
        'rgba(147, 154, 67, 0.5)',
        'rgba(215, 65, 65, 0.5)',
        'rgba(241, 177, 30, 0.5)',
        'rgba(203, 114, 52, 0.5)',
        'rgba(190, 144, 99, 0.5)',
        'rgba(91, 39, 52, 0.5)',
        'rgba(190, 144, 99, 0.5)'
      ],
      borderColor: [
        'rgba(55, 80, 93, 1)',
        'rgba(91, 39, 52, 1)',
        'rgba(190, 144, 99, 1)',
        'rgba(147, 154, 67, 1)',
        'rgba(215, 65, 65, 1)',
        'rgba(241, 177, 30, 1)',
        'rgba(203, 114, 52, 1)',
        'rgba(190, 144, 99, 1)',
        'rgba(91, 39, 52, 1)',
        'rgba(190, 144, 99, 1)'
      ],
      borderWidth: 1
    }]
  };

  var options = {
    maintainAspectRatio: false,
    scales: {
    },
    legend: {
      labels: {
        fontSize: 25,
      },
    },
  }

  return (
    <div className="relative">
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={loading}
    onClick={() => { return; }}
>
    <CircularProgress color="inherit" />
  </Backdrop>
      <Pie
        data={data}
        height={400}
        options={options}

      />
    </div>
  )
}

export default OrdersChart