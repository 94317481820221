
import { Backdrop, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {React,useEffect,useState} from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { useNavigate, useParams } from 'react-router-dom';

import {  toast } from 'react-toastify';
import { getWebservices } from '../../apis/Webservices';
import { saveXml } from '../../apis/Xml';
import { saveJson } from '../../apis/Json';

const StoreJson = () => {
    const navigate = useNavigate();
    const params = useParams();


    const [loading,setLoading] = useState(false);

    

    const [errors,setErrors] = useState([]);






    const [webservices,set_webservices] = useState([]);
    const [webservice_id,set_webservice_id] = useState("");
    const [file,set_file] = useState({});




    useEffect(()=>{
        setLoading(true);
       


        getWebservices()
        .then((data)=>{
            
            if(data.success){
                
              set_webservices(data.data)

            }else{
                navigate('/')
            }
            setLoading(false);
        })

        

    },[]);




    const handleChange=(event)=>{
        event.preventDefault();
   
        switch(event.target.name){
            case "webservice_id":
                set_webservice_id(event.target.value);
                break;
            case "file":
                set_file(event.target.files[0]);
                //console.log(event.target.files);
                break;
            default: break;
        }
    }
    
    
    const handleSubmit = (event)=>{
        setLoading(true);
        setErrors("");
      
  
        var data = new FormData();
        data.append('file', file)
        data.append('webservice_id', webservice_id)
        data.append('company_id', params.id)

        saveJson(data)
        .then((data)=>{
            
        setLoading(false);
        if(data.success){
            toast.success(data.message);
            navigate('/company/json/cp/'+params.id);

         
        }else{
            
            if(data.data!=undefined){
             setErrors(data.data);
            
            }else{
                toast.error(data.message);
            }

        }
    })

        
    }

    return (
        <DashboardContent title="Company">


            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper elevation={2} >
                        <Box p={2}>
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={4}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                New JSON
                            </Typography>

                         
                           

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>

                             
                                <FormControl fullWidth>
                                    <InputLabel id="webservice_id">Webservice</InputLabel>
                                    <Select
                                        labelId="webservice_id"
                                        id="webservice_id"
                                        value={webservice_id}
                                        name="webservice_id"
                                        label="Webservice"
                                        error={errors.webservice_id!=undefined?true:false}
                                        helperText={errors.webservice_id!==undefined?errors.webservice_id:''}

                                        onChange={handleChange}
                                    >
                                     {
                                        webservices.map((item)=>{
                                            return(
                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                            );
                                        })
                                     }
                                     
                                    </Select>
                                    <FormHelperText  error={true}>{errors.webservice_id!==undefined?errors.webservice_id:''}</FormHelperText>
                                    </FormControl>
                                    <Box mb={2} />
                                    <FormControl>
                                        <Button variant="contained"  onChange={handleChange} component="label">
                                            Upload
                                        <input hidden accept="text/json" multiple name="file" type="file" />
                                        </Button>
                                    </FormControl>
                                    <FormHelperText  error={true}>{errors.file!==undefined?errors.file:''}</FormHelperText>

                                    <Box mb={2} />
                                    
                                   
                                    
                                

                                </Grid>
                             
                                </Grid>
                           
                            
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>
                                <FormControl >
                                            <Button variant="outlined"   onClick={handleSubmit} >Save JSON</Button>
                                </FormControl>
                                </Grid>
                            </Grid>





                                   

                    

                        </Box>
                    </Paper>
                </Grid>

            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </DashboardContent>
    );
}

export default StoreJson;