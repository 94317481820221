import {base_url} from './baseurl';



export function getOrders(page,data=""){
    
    
    return fetch(base_url+'orders/?page='+page+'&'+new URLSearchParams(data),{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}
export function getAllOrders(data=""){
    
    
    return fetch(base_url+'allorders?'+new URLSearchParams(data),{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export function syncOrdersWithPakaneo(data){

    
    return fetch(base_url+'syncorders',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:JSON.stringify(data)

      
   })
   .then(res=>res.json())
  
}

export function setOrdersExportFlag(data){
    
    return fetch(base_url+'setOrdersExportFlag',{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token")
        },
        body:JSON.stringify(data)
 
       
    })
    .then(res=>res.json())
   
}

export function deleteOrder(id){
    
    return fetch(base_url+'orders/'+id,{
        method:'DELETE',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token")
        },
 
       
    })
    .then(res=>res.json())
   
}

export function getOrderFromPakaneo(company_id,id){

    return fetch(base_url+'getOrderByIDFromPakaneo/'+company_id+'/'+id,{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token")
        },
 
       
    })
    .then(res=>res.json())
    
}


export function createOrder(data,id){

    
    return fetch(base_url+'createorders/'+id,{
       method:'POST',
       headers:{
           "Authorization":localStorage.getItem("token")
       },
       body:data

      
   })
   .then(res=>res.json())
  
}