import {
    AppBar,
    Backdrop,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    Fab,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText, FormLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem, OutlinedInput,
    Paper,
    Select, Switch,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import {  useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import { toast } from 'react-toastify';
import { checkBillbeeConnection, checkPakaneoConnection, getCompanyById, saveFtpCron, updateCompany } from '../../apis/Company';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PropTypes from 'prop-types';
import $ from "jquery";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FtpTable from '../../components/tables/FtpTable';
import { getFtps, getFtpsAll } from '../../apis/Ftp';
import { getAllOrders, syncOrdersWithPakaneo } from '../../apis/Orders';
import { getWebservices } from '../../apis/Webservices';
import { getFilesFromFtp, getFulfillables, uploadXmlToFTP } from '../../apis/Xml';
import exportFromJSON from 'export-from-json';
import { Buffer } from 'buffer';
import { getCronTypes } from '../../apis/CronTypes';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { getCronByCompany, saveCron } from '../../apis/Crons';
import CronTable from '../../components/tables/CronTable';
import AddIcon from '@mui/icons-material/Add';
import CronLogTable from '../../components/tables/CronLogTable';
import { getCronlogs } from '../../apis/Cronlog';
import LogsTable from '../../components/tables/LogTable';
import { getFieldKeys } from '../../apis/FieldKeys';
import { getSettings, saveSettings } from '../../apis/Settings';
import { processOrder, processProducts, updateStock } from '../../apis/Billbee';
import ClientUserTable from '../../components/tables/ClientUsersTable';
import {Multiselect} from "multiselect-react-dropdown";
import { getPakaneoToken} from '../../apis/Auth';
import { wh1_checkConnection } from '../../apis/Wh1';



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const EditCompany = () => {
    const navigate = useNavigate();
    const params = useParams();

    const [loading, setLoading] = useState(false);

    const [lock,set_lock] = useState(true);
    const [errors, setErrors] = useState([]);
    const [orders, setOrders] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [fifo, setFifo] = React.useState(0);
    const [serial, setSerial] = React.useState(0);
    const [best_before_date, setBest_before_date] = React.useState(0);
    const [commit_zero_stock, setCommit_zero_stock] = React.useState(0);
    const [address_correction, setAddress_correction] = React.useState(0);
    const [company_username,setCompany_username] = useState("");
    const [company_password,setCompany_password] = useState("");
    const [company_email,set_company_email] = useState("");


    const [sync_order_duration, setSync_order_duration] = React.useState(0);



    const [name, set_name] = useState('');
    const [url_name, set_url_name] = useState('');
    const [url, set_url] = useState('');
    const [token, set_token] = useState("save company and klick generate token");
    const [company_ftp, set_company_ftp] = useState("");
    const [source,set_source] = useState([]);
    const [value, setValue] = useState(0);
    const [ftps, setFtps] = useState([]);
    const [webservices, setWebservices] = useState([]);

    const [ftp, setFtp] = useState("");
    const [webservice, setWebservice] = useState("");
    const [crontype, setCronType] = useState("");
    const [times, setTimes] = useState("");
    const [delay, setDelay] = useState("");
    const [time, setTime] = useState("");
    const [logs,set_logs] = useState([]);
    const [logs_data,set_logs_data] = useState([]);
    const [page,setpage] = useState(1);
    const[source_fields,set_source_fields] = useState([]);


    const [street, set_street] = useState('');
    const [street_number, set_street_number] = useState('');
    const [city, set_city] = useState('');
    const [country, set_country] = useState('');
    const [zip, set_zip] = useState('');
    const [tin_number, set_tin_number] = useState('');
    const [phone_number, set_phone_number] = useState('');
    const [company_name, set_company_name] = useState('');
    const [avatar,set_avatar] = useState({});



    


    const [password, setPassword] = useState("");


  

    const handleChangeSettings=(event)=>{
        //console.log(source_fields);
        source_fields.map(item=>{
            if(item.key == event.target.name){
                item.values= event.target.value;

            }
        })
        set_source_fields(source_fields);
        

    }

    const handleSaveSourceSetting=()=>{
        //console.log(source_fields);

        source_fields.map(item=>{

            saveSettings(JSON.stringify(item));

        })
        toast.success("Setting Saved")

    }

    const handleCheckBillbeeConnection = ()=>{
        setLoading(true);
        checkBillbeeConnection(params.id)
        .then(data=>{
            setLoading(false);
            if(data.success){
                toast.success(data.message)
            }else{
                toast.error(data.message);
            }
        })
    }

    const handleCheckWh1Connection=()=>{
        setLoading(true);
        wh1_checkConnection(params.id)
        .then(data=>{
            setLoading(false);
            if(data.success){
                toast.success(data.message)
            }else{
                toast.error(data.message);
            }
        })
    }
    const handleChangePage=(event,value)=>{ 
       
        setLoading(true);
        set_logs([]);
        set_logs_data([]);


        setpage(value);
        
        getCronlogs(params.id,value)
        .then(data=>{
            set_logs(data.data.data);
            set_logs_data(data.data);
            setLoading(false);
        })
        
    }
      
    const handleChangeTabs = (event ,newValue) => {

        setValue(newValue);

        if(source.code == "BILLBEE" ){
            if(newValue==2){
                setLoading(true);




                getCompanyById(params.id)
                .then((data) => {

                    if (data.success) {

                    set_company_ftp(data.data.ftp_id); 
                    set_name(data.data.name);
                    set_url(data.data.url);
                    if(data.data.token){
                    set_token(data.data.token);
                    }else{
                        set_token("save company and klick generate token")
                    }
                    set_source(data.data.source);
                    set_company_email(data.data.company_email);

                    if(data.data.source_id){
                        let settings_data_obj = "";
                        getSettings(params.id)
                        .then(settings_data=>{

                            if(settings_data.data){

                            settings_data_obj =settings_data.data;

                                getFieldKeys(data.data.source.id)
                                    .then(data_source=>{

                                        set_source_fields(data_source.data);




                                        data_source.data.map(item=>{
                                            if(!settings_data_obj){
                                                settings_data_obj = data_source.data;
                                                settings_data_obj.map(item_settings=>{
                                                    // item.id = undefined;
                                                    // item_settings.values = "";
                                                })
                                            }

                                            settings_data_obj.map(item_settings=>{
                                                if(item_settings.key == item.key){

                                                    item.values=item_settings.values;

                                                    // //console.log(document.querySelector(`input[name=${item.key}]`));
                                                    if(item.values!==undefined && document.querySelector(`input[name=${item.key}]`)){
                                                        document.querySelector(`input[name=${item.key}]`).value=item_settings.values;

                                                      }

                                                }
                                            });

                                            // item.values = "";

                                            item.company_id=params.id;

                                        })




                                    })
                            }
                            setLoading(false);
                            
                        });




                    }
                    



                  
                } else {
                    // toast.error(data.message);
                    navigate('/')
                }
            })
            }
        }
        if(source.code == "HAMBURGER" || source.code == "ODOO" || source.code == "WH1" || source.code == "SHOPIFY"){
            if(newValue==2){
                setLoading(true);




                getCompanyById(params.id)
                .then((data) => {

                    if (data.success) {

                    set_company_ftp(data.data.ftp_id); 
                    set_name(data.data.name);
                    set_url(data.data.url);
                    if(data.data.token){
                    set_token(data.data.token);
                    }else{
                        set_token("save company and klick generate token")
                    }
                    set_source(data.data.source);
                    set_company_email(data.data.company_email);

                    if(data.data.source_id){
                        let settings_data_obj = "";
                        getSettings(params.id)
                        .then(settings_data=>{

                            if(settings_data.data){

                            settings_data_obj =settings_data.data;

                                getFieldKeys(data.data.source.id)
                                    .then(data_source=>{

                                        set_source_fields(data_source.data);




                                        data_source.data.map(item=>{
                                            if(!settings_data_obj){
                                                settings_data_obj = data_source.data;
                                                settings_data_obj.map(item_settings=>{
                                                    // item.id = undefined;
                                                    // item_settings.values = "";
                                                })
                                            }

                                            settings_data_obj.map(item_settings=>{
                                                if(item_settings.key == item.key){

                                                    item.values=item_settings.values;

                                                    // //console.log(document.querySelector(`input[name=${item.key}]`));
                                                    if(item.values!==undefined && document.querySelector(`input[name=${item.key}]`)){
                                                        document.querySelector(`input[name=${item.key}]`).value=item_settings.values;

                                                      }

                                                }
                                            });

                                            // item.values = "";

                                            item.company_id=params.id;

                                        })




                                    })
                            }
                            setLoading(false);
                            
                        });




                    }
                    



                  
                } else {
                    // toast.error(data.message);
                    navigate('/')
                }
            })
            }
        }


    };


    const handleExportStockFtp = () => {
        setLoading(true)
        // //console.log(json)
        const fileName = "export"
        const fields = []
        const exportType = 'json';
        let data;
        let payload = {
            company_id: params.id
        }


        getFulfillables(payload)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    data = JSON.parse(JSON.stringify(JSON.parse(data.data)))
                    const output = exportFromJSON({ data, fileName, fields, exportType, processor: content => content })
                    // //console.log(output);
                    // return;
                    let data_api = {
                        ftp_id: ftp,
                        xml_content: output,
                        password: password,
                        is_for_orders: 0

                    }
                    uploadXmlToFTP(JSON.stringify(data_api))
                        .then(data => {
                            if (data.success) {
                                toast.success(data.message);
                            } else {
                                toast.error(data.message);
                            }
                        })

                } else {
                    toast.error(data.message);
                }
            })


        setLoading(false);
    }

    const handleDownloadStock = () => {
        let data = {
            company_id: params.id
        }
        setLoading(true);
        getFulfillables(data)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    // //console.log();
                    download(JSON.stringify(JSON.parse(data.data)))
                } else {
                    toast.error(data.message);
                }
            })
    }
    function download(json) {
        // //console.log(json)
        const fileName = "export"
        const data = JSON.parse(json)
        const fields = []
        const exportType = 'json';

        exportFromJSON({ data, fileName, fields, exportType })
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    useEffect(() => {
        setLoading(true);


        getWebservices()
            .then(data => {
                setWebservices(data.data);
            })

        getFtps(params.id)
            .then(data => {
                setFtps(data.data);
            })


        getCompanyById(params.id)
            .then((data) => {

                if (data.success) {

                    set_company_ftp(data.data.ftp_id); 
                    set_name(data.data.name);
                    set_url(data.data.url);
                    set_company_email(data.data.company_email);
                    set_url_name(data.data.url_name);
                    set_street(data.data.street);
                    set_street_number(data.data.street_number);
                    set_country(data.data.country);
                    set_city(data.data.city);
                    set_zip(data.data.zip);
                    set_tin_number(data.data.tin_number);
                    set_phone_number(data.data.phone_number);
                    // set_avatar(data.data.avatar);
                    set_company_name(data.data.company_name);






                    if(data.data.token!=""){

                        set_token(data.data.token);
                    }else{
                        set_token("save company and klick generate token")
                    }
                    
                    set_source(data.data.source);
                    setFifo(data.data.fifo);
                    setSerial(data.data.serial);
                    setBest_before_date(data.data.best_before_date);
                    setCommit_zero_stock(data.data.commit_zero_stock);
                    setSync_order_duration(data.data.sync_order_duration);
                    setAddress_correction(data.data.address_correction);
                    setCompany_username(data.data.username);
                    setCompany_password(data.data.password);

                    if(data.data.source_id){
                        let settings_data_obj = "";
                        getSettings(params.id)
                        .then(settings_data=>{
                            if(settings_data.data){
                            settings_data_obj =settings_data.data; 
                            }
                            
                        });
                        getFieldKeys(data.data.source.id)
                        .then(data_source=>{
                            set_source_fields(data_source.data);

                           
                         
                           
                                data_source.data.map(item=>{
                                    if(!settings_data_obj){
                                        settings_data_obj = data_source.data;
                                        settings_data_obj.map(item=>{
                                            item.id = undefined;
                                            item.values = "";
                                        })
                                    }
                                    // //console.log(data_source);

                                    // //console.log(settings_data_obj);
                                    settings_data_obj.map(item_settings=>{
                                        
                                        if(item_settings.key == item.key){
                                            
                                            item.values=item_settings.values;
                                            // //console.log(document.querySelector(`input[name=${item.key}]`));
                                            if(item.values!==undefined && document.querySelector(`input[name=${item.key}]`)){
                                            document.querySelector(`input[name=${item.key}]`).value=item.values;
                                            }
                                            // //console.log(item_dom);
                                            // item_dom.value(item.values)
                                        }else{
                                            item.values = "";
                                        }
                                    });
                                    item.values = "";
                                    item.company_id=params.id;
                                    
                                })
                            
                            
                        })
                    }
                    


                    setLoading(false);
                  
                } else {
                    // toast.error(data.message);
                    navigate('/')
                }
            })
          

        getCronlogs(params.id,1)
        .then((data)=>{
            if(data.success){
                set_logs(data.data.data);
                set_logs_data(data.data);
            }else{
                navigate('/');
            }
            setLoading(false);


        })

    }, []);

    const syncronizeOrdersXentral = () => {
        setLoading(true);
        let order_ids = [];
        let data = {
            company_id: params.id
        }
        getAllOrders(data)
            .then(data => {

                setOrders(data.data)
                orders.map(item => {
                    order_ids.push(item.id)
                })

                let data_payload = {
                    order_ids: order_ids
                }
                syncOrdersWithPakaneo(data_payload)
                    .then(data => {
                        setLoading(false);

                        toast.success(data.message);
                    })

            })




    }


    const handleOpenModel=()=>{
        setTime("");
        setDelay("");
        setCronType("");
        setTimes("");
        setOpen(true);
    }

    const handleChange = (event) => {
        event.preventDefault();

        switch (event.target.name) {
            case "name":
                set_name(event.target.value);
                break;
            case "token":
                set_token(event.target.value);
                break;
            case "url":
                set_url(event.target.value);
                break;
            case "company_username":
                setCompany_username(event.target.value);
                break;

            case "company_password":
                setCompany_password(event.target.value);
                break;
            case "company_email":
                set_company_email(event.target.value);
                break;
            case "street":
                set_street(event.target.value);
                break;
            case "street_number":
                set_street_number(event.target.value);
                break;
            case "city":
                set_city(event.target.value);
                break;
            case "country":
                set_country(event.target.value);
                break;
            case "zip":
                set_zip(event.target.value);
                break;
            case "tin_number":
                set_tin_number(event.target.value);
                break;
            case "phone_number":
                set_phone_number(event.target.value);
                break;
            case "company_name":
                set_company_name(event.target.value);
                break;
            case "avatar":
                set_avatar(event.target.files[0]);
                 // //console.log(avatar);
                break;
            case "ftp":
                setFtp(event.target.value);
                setPassword("");

                ftps.map(item => {
                    if (item.id == event.target.value) {
                        let string = item.password;
                        // //console.log(item.password);
                        if (string) {
                            let bufferObj = Buffer.from(string, "base64");

                            // Encode the Buffer as a utf8 string
                            let decodedString = bufferObj.toString("utf8");
                            setPassword(decodedString);
                        }
                    }
                })
                break;
            case "company_ftp":
                set_company_ftp(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;

            case "webservice":
                setWebservice(event.target.value);
                break;
            case "url_name":
                set_url_name(event.target.value);
                set_url("https://"+event.target.value+".pakaneo.com")
                break;
            case "fifo":
                if(fifo){
                    setFifo(0);
                }else{
                    setFifo(1);
                }
                break;
            case "serial":
                if(serial){
                    setSerial(0);
                }else{
                    setSerial(1);
                }
                break;
            case "best_before_date":
                if(best_before_date){
                    setBest_before_date(0);
                }else{
                    setBest_before_date(1);
                }
                break;
            case "commit_zero_stock":
                if(commit_zero_stock){
                    setCommit_zero_stock(0);
                }else{
                    setCommit_zero_stock(1);
                }
                break;
            case "sync_order_duration":
                setSync_order_duration(event.target.value);
                break;
            case "address_correction":
                if(address_correction){
                    setAddress_correction(0);
                }else{
                    setAddress_correction(1);
                }
                break;
            default: break;

        }
    }


    const handleSubmit = (event) => {
        setErrors("");
        setLoading(true);

        let data = {
            name: name,
            url: url,
            url_name:url_name,
            token: token,
            fifo:fifo,
            serial:serial,
            best_before_date:best_before_date,
            commit_zero_stock:commit_zero_stock,
            sync_order_duration:sync_order_duration,
            address_correction:address_correction,
            username:company_username,
            password:company_password,
            company_email:company_email,
            street:street,
            street_number:street_number,
            city:city,
            country:country,
            zip:zip,
            tin_number:tin_number,
            phone_number:phone_number,
        }

        var data_form = new FormData();
        data_form.append('name',name);
        data_form.append('url',url);
        data_form.append('url_name',url_name);
        data_form.append('token',token);
        data_form.append('fifo',fifo);
        data_form.append('serial',serial);
        data_form.append('best_before_date',best_before_date);
        data_form.append('commit_zero_stock',commit_zero_stock);
        data_form.append('sync_order_duration',sync_order_duration);
        data_form.append('address_correction',address_correction);
        data_form.append('username',company_username);
        data_form.append('password',company_password);
        data_form.append('company_email',company_email);
        data_form.append('street',street);
        data_form.append('street_number',street_number);
        data_form.append('city',city);
        data_form.append('country',country);
        data_form.append('zip',zip);
        data_form.append('tin_number',tin_number);
        data_form.append('phone_number',phone_number);
        data_form.append('avatar',avatar);
        data_form.append('company_name',company_name);






        

















        updateCompany(data_form, params.id)
            .then((data) => {

                setLoading(false);
                if (data.success) {
                    toast.success(data.message);
                    navigate("/company/edit/" + data.data.id)

                } else {

                    if (data.data != undefined) {
                        setErrors(data.data);
            

                            toast.error("Some fields are required please see other tabs!!");
                    
                    } else {
                        toast.error(data.message);
                    }

                }
            })


    }

    const handleImportFtpSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (ftp && webservice && password) {

            let data = {
                ftp_id: ftp,
                webservice_id: webservice,
                password: password,
                company_id: params.id
            }
            getFilesFromFtp(data)
                .then(data => {
                    setLoading(false);

                    if (data.success) {
                        toast.success(data.message);
                    } else {
                        toast.error(data.message);
                    }
                })

        } else {
            toast.error("For import all fields are required");
        }
    }
    const handleUpdateStock= ()=>{
        setLoading(true);
        updateStock(params.id)
        .then(data=>{
            if(data.success){
                toast.success("Successfully updated stock");
            }else{
                toast.error("Failed to update stock");
            }
            setLoading(false);
        })
    }

    const setFtpForCron=()=>{
        setLoading(true);
        let payload ={
            ftp_id:company_ftp,
            company_id:params.id
        }
        saveFtpCron(payload)
        .then(data=>{
            setLoading(false);

            if(data.success){
                toast.success(data.message);
            }else{
                toast.error("Something went wrong");
            }
        })
    }
    const handleGenerateToken =()=>{
        setLoading(true);

        getPakaneoToken(params.id)
            .then(data=>{
                setLoading(false);
                if(data.success){
                    set_token(data.data.access_token);
                    toast.success("Successfully generated token")
                }else{
                    toast.error("Error in generating token");
                }
            })
    }

    const checkConnectionWithPakaneo =()=>{
        setLoading(true);
        checkPakaneoConnection(params.id)
        .then(data=>{
            setLoading(false);
           if(data.success){
            // console.log(data.data.message);
                if(data.data.message && data.data.message=="Unauthenticated."){
                    toast.error(data.data.message);
                }else{
                    toast.success("Connection successfully established");
                }

           }else{
            toast.error(data.data.message);
           }
        })
    }

    const handleProcessOrder=()=>{
        setLoading(true);
        processOrder(params.id)
        .then(data=>{
           setLoading(false)
            if(data.success) {
                toast.success(data.message);
            }else{
                toast.error(data.message);
            }
        })
    }
    const handleProcessProducts=()=>{
        setLoading(true);
        processProducts(params.id)
        .then(data=>{
            setLoading(false);
            if(data.success) {
                toast.success(data.message)
            }else{
                toast.error(data.message)

            }
        })
    }



    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function getSettingField(item) {

        switch (item.type) {

            case "text":

                if(item.values=="" && document.querySelector(`input[name=${item.key}]`)){


                    document.querySelector(`input[name=${item.key}]`).value=item.default_values;

                }

                return (
                    <>
                        <TextField  disabled={lock} required label={item.name} variant="outlined" InputLabelProps={{ shrink: true }} name={item.key} type={item.type} onChange={handleChangeSettings}
                                    error={errors.name != undefined ? true : false}
                                    helperText={errors.name !== undefined ? errors.name : ''}
                        />
                    </>
                );

                break;

            case "email":

                if(item.values=="" && document.querySelector(`input[name=${item.key}]`)){


                    document.querySelector(`input[name=${item.key}]`).value=item.default_values;

                }

                return (
                    <>
                        <TextField  disabled={lock} required label={item.name} variant="outlined" InputLabelProps={{ shrink: true }} name={item.key} type={item.type} onChange={handleChangeSettings}
                                    error={errors.name != undefined ? true : false}
                                    helperText={errors.name !== undefined ? errors.name : ''}
                        />
                    </>
                );

                break;
            case "password":
                if(item.values=="" && document.querySelector(`input[name=${item.key}]`)){


                    document.querySelector(`input[name=${item.key}]`).value=item.default_values;

                }
                return (
                    <>
                        <TextField  disabled={lock} required label={item.name} variant="outlined" InputLabelProps={{ shrink: true }} name={item.key} type={item.type} onChange={handleChangeSettings}
                                    error={errors.name != undefined ? true : false}
                                    helperText={errors.name !== undefined ? errors.name : ''}
                        />
                    </>
                );
                break;
            case "number":
                if(item.values=="" && document.querySelector(`input[name=${item.key}]`)){


                    document.querySelector(`input[name=${item.key}]`).value=item.default_values;

                }
                return (
                    <>
                        <TextField  disabled={lock} required label={item.name} variant="outlined" InputLabelProps={{ shrink: true }} name={item.key} type={item.type} onChange={handleChangeSettings}
                                    error={errors.name != undefined ? true : false}
                                    helperText={errors.name !== undefined ? errors.name : ''}
                        />
                    </>
                );
                break;
            case "date":
                if(item.values=="" && document.querySelector(`input[name=${item.key}]`)){


                    document.querySelector(`input[name=${item.key}]`).value=item.default_values;

                }
                return (<>
                    <TextField  disabled={lock} required label={item.name} variant="outlined" InputLabelProps={{ shrink: true }} name={item.key} type={item.type} onChange={handleChangeSettings}
                                error={errors.name != undefined ? true : false}
                                helperText={errors.name !== undefined ? errors.name : ''}
                    />
                </>)
                break;
            case "multiselect":

                let select_values = JSON.parse(item.default_values);
                let selected_values =[];
                if(item.key=="ORDER_DOCUMENT_CHOOSE"){
                    if(item.values){
                        selected_values = JSON.parse(item.values)
                    }else{
                        selected_values = [{key:2,name:"DELIVERY NOTE"}];
                    }
                }
                if(item.key=="ORDER_STATE_ID"){
                    if(item.values){
                        selected_values = JSON.parse(item.values)
                    }else{
                        selected_values = [{key:3,name:"PAID"}];
                    }
                }






                return (<>
                    <label className="form-label" disabled={lock} >{item.name}</label >
                    <Multiselect
                        disable={lock}
                        options={select_values} // Options to display in the dropdown
                        selectedValues={selected_values} // Preselected value to persist in dropdown
                        onSelect={(e)=>{
                            selected_values = e;
                            item.values = JSON.stringify(selected_values);
                            console.log(selected_values);
                        }
                        } // Function will trigger on select event
                        onRemove={(e)=>{
                            selected_values = e;
                            item.values = JSON.stringify(selected_values);
                        }
                        } // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                    />
                </>);
                break;


            default:
                break;

        }

    }

    return (
        <DashboardContent title="Company">
                     
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper elevation={2} >
                        <Box p={2}>
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={4}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                {name}  <IconButton onClick={()=>{set_lock(!lock)}}>{lock==1?(<><LockIcon/></>):(<><LockOpenIcon/></>)}</IconButton>
                            </Typography>




                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                            {source.code =='BILLBEE' &&(

                                <Grid item xs={12} sm={12} md={12}>
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={value}  variant="scrollable" scrollButtons="auto" onChange={handleChangeTabs} aria-label="basic tabs example">
                                                <Tab label="General" {...a11yProps(0)} />
                                                <Tab label="Pakaneo Settings" {...a11yProps(1)} />
                                                <Tab label="Billbee Settings" {...a11yProps(2)} />
                                                <Tab label="Crons" {...a11yProps(3)} />
                                                <Tab label="Crons LOGS" {...a11yProps(4)} />
                                                <Tab label="Users" {...a11yProps(5)} />
                                              

                                            </Tabs>
                                        </Box>
                                        <TabPanel value={value} index={0}>
                                             {/* {Additional Fields} */}
                                             <FormControl fullWidth>

                                                <TextField disabled={lock} required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name}
                                                    error={errors.name != undefined ? true : false}
                                                    helperText={errors.name !== undefined ? errors.name : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Company" variant="outlined" InputLabelProps={{ shrink: true }} name="company_name" onChange={handleChange} value={company_name}
                                                    error={errors.company_name != undefined ? true : false}
                                                    helperText={errors.company_name !== undefined ? errors.company_name : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                             <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Street" variant="outlined" InputLabelProps={{ shrink: true }} name="street" onChange={handleChange} value={street}
                                                    error={errors.street != undefined ? true : false}
                                                    helperText={errors.street !== undefined ? errors.street : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Street Number" variant="outlined" InputLabelProps={{ shrink: true }} name="street_number" onChange={handleChange} value={street_number}
                                                    error={errors.street_number != undefined ? true : false}
                                                    helperText={errors.street_number !== undefined ? errors.street_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="City" variant="outlined" InputLabelProps={{ shrink: true }} name="city" onChange={handleChange} value={city}
                                                    error={errors.city != undefined ? true : false}
                                                    helperText={errors.city !== undefined ? errors.city : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Country" variant="outlined" InputLabelProps={{ shrink: true }} name="country" onChange={handleChange} value={country}
                                                    error={errors.country != undefined ? true : false}
                                                    helperText={errors.country !== undefined ? errors.country : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Zip" variant="outlined" InputLabelProps={{ shrink: true }} name="zip" onChange={handleChange} value={zip}
                                                    error={errors.zip != undefined ? true : false}
                                                    helperText={errors.zip !== undefined ? errors.zip : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Tax Identification Number" variant="outlined" InputLabelProps={{ shrink: true }} name="tin_number" onChange={handleChange} value={tin_number}
                                                    error={errors.tin_number != undefined ? true : false}
                                                    helperText={errors.tin_number !== undefined ? errors.tin_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Phone Number" variant="outlined" InputLabelProps={{ shrink: true }} name="phone_number" onChange={handleChange} value={phone_number}
                                                    error={errors.phone_number != undefined ? true : false}
                                                    helperText={errors.phone_number !== undefined ? errors.phone_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                            <FormControl>
                                                <Button variant="contained" disabled={lock}  onChange={handleChange} component="label">
                                                    Upload Company Avatar
                                                <input hidden accept="image/*"  name="avatar" type="file" />
                                                </Button>
                                            </FormControl>
                                            <FormHelperText  error={true}>{errors.avatar!==undefined?errors.avatar:''}</FormHelperText>
                                            <Box mb={2} />

                                            {/* {Additional Fields} */}

                                            <FormControl >
                                                        <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update General Settings</Button>
                                                    </FormControl>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                                <FormControl fullWidth>
                                                    <TextField disabled={lock} required type="text" label="URL NAME" variant="outlined" InputLabelProps={{ shrink: true }} name="url_name" onChange={handleChange} value={url_name}
                                                            error={errors.url_name != undefined ? true : false}
                                                            helperText={errors.url_name !== undefined ? errors.url_name : ''}
                                                        />
                                                    </FormControl>
                                                    <Box mb={2} />

                                                    <FormControl fullWidth>
                                                        <TextField disabled={lock} required type="text"  disabled label="URL" variant="outlined" InputLabelProps={{ shrink: true }} name="url" onChange={handleChange} value={url}
                                                            error={errors.url != undefined ? true : false}
                                                            helperText={errors.url !== undefined ? errors.url : ''}
                                                        />

                                                    </FormControl>

                                                    <Box mb={2} />
                                                    <FormControl fullWidth>

                                                        <TextField disabled={lock} required type="text" label="Username" variant="outlined" InputLabelProps={{ shrink: true }} name="company_username" onChange={handleChange} value={company_username}
                                                                error={errors.company_username != undefined ? true : false}
                                                                helperText={errors.company_username !== undefined ? errors.token : ''}
                                                        />

                                                    </FormControl>
                                                    <Box mb={2} />
                                                    <FormControl fullWidth>

                                                        <TextField disabled={lock} required type="password" label="Password" variant="outlined" InputLabelProps={{ shrink: true }} name="company_password" onChange={handleChange} value={company_password}
                                                                error={errors.company_username != undefined ? true : false}
                                                                helperText={errors.company_username !== undefined ? errors.token : ''}
                                                        />

                                                    </FormControl>

                                                    <Box mb={2} />
                                                    <FormControl fullWidth>

                                                        <TextField disabled={lock} required type="text" label="Token" variant="outlined" InputLabelProps={{ shrink: true }} name="token" onChange={handleChange} value={token}
                                                            error={errors.token != undefined ? true : false}
                                                            helperText={errors.token !== undefined ? errors.token : ''}
                                                        />

                                                    </FormControl>
                                                    <Box mb={2} />
                                                    <FormControl >
                                                        <Button disabled={lock} variant="outlined" onClick={checkConnectionWithPakaneo} >Check Connection</Button>
                                                    </FormControl>
                                                    <Box mb={2} />


                                                    <FormControl fullWidth>

                                                        <TextField disabled={lock} required type="number" label="Sync Order Duration" variant="outlined" InputLabelProps={{ shrink: true }} name="sync_order_duration" onChange={handleChange} value={sync_order_duration}
                                                                error={errors.sync_order_duration != undefined ? true : false}
                                                                helperText={errors.sync_order_duration !== undefined ? errors.sync_order_duration : ''}
                                                        />

                                                    </FormControl>


                                                    <Box mb={2} />
                                                    
                                                    <FormControl fullWidth>

                                                    <TextField disabled={lock} required label="Notification Email" variant="outlined" InputLabelProps={{ shrink: true }} name="company_email" onChange={handleChange} value={company_email}
                                                        error={errors.company_email != undefined ? true : false}
                                                        helperText={errors.company_email !== undefined ? errors.company_email : ''}
                                                    />

                                                    </FormControl>
                                                    <Box mb={2} />

                                                   


                                                    <FormGroup>

                                                        <FormControlLabel disabled={lock} control={<Switch  defaultChecked={fifo}  onChange={handleChange}  name="fifo" value={fifo} />} label="FIFO" />
                                                        <FormControlLabel disabled={lock} control={<Switch  defaultChecked={serial}  onChange={handleChange} name="serial" value={serial}/>} label="SERIAL" />
                                                        <FormControlLabel disabled={lock} control={<Switch  defaultChecked={best_before_date}  onChange={handleChange} name="best_before_date"  value={best_before_date}/>} label="BEST BEFORE DATE" />
                                                        <FormControlLabel disabled={lock} control={<Switch  defaultChecked={commit_zero_stock}  onChange={handleChange} name="commit_zero_stock"  value={commit_zero_stock}/>} label="COMMIT ZERO STOCK" />
                                                        <FormControlLabel disabled={lock} control={<Switch  defaultChecked={address_correction}  onChange={handleChange}  name="address_correction" value={fifo} />} label="ADDRESS CORRECTION" />

                                                    </FormGroup>
                                                    <Box mb={2} />
                                                    <FormControl >
                                                        <Button disabled={lock} variant="outlined" onClick={handleGenerateToken} >Generate Token</Button>
                                                    </FormControl>
                                                    <Box mb={2} />

                                                    <FormControl >
                                                        <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update Company</Button>
                                                    </FormControl>

                                                </TabPanel>
                                      
                                                <TabPanel value={value} index={2}>
                                                <Box p={2}>
                                                    {
                                                    source_fields && source_fields.map(item=>(
                                                      <div>
                                                        <FormControl fullWidth >
                                                            {
                                                                getSettingField(item)
                                                            }

        
                                                    </FormControl>
                                                    <Box p={2}/>
                                                    </div>
                                                    ))
                                                    }  

                                                <Button disabled={lock} variant="outlined" onClick={handleCheckBillbeeConnection}>Check Connection </Button>
                                                <br/>
                                                <br/>  
                                                <Button disabled={lock} variant="outlined" onClick={handleSaveSourceSetting}>Save Settings </Button>
                                                <br/>
                                                <br/>
                                                {/* <Button variant="outlined" onClick={handleProcessOrder}>Import Orders</Button>
                                                <br/>
                                                <br/>
                                                <Button variant="outlined" onClick={handleProcessProducts}>Import Products</Button>
                                                <br/>
                                                <br/>
                                                <Button variant="outlined" onClick={handleUpdateStock}>Update Stock</Button>
     */}
    
    
    
                                                </Box>  
                                                </TabPanel>
                                      
                               
                                        

                                      
                                    
                                        <TabPanel value={value} index={3}>
                                                <CronTable lock={lock} id={params.id}/>
                                                
                                        </TabPanel>
                                    
                                        <TabPanel value={value} index={4}>
                                                <CronLogTable handleChangePage={handleChangePage} logs={logs} logs_data={logs_data} />
                                                
                                        </TabPanel>
                                       
                                        <TabPanel value={value} index={5}>
                                            <ClientUserTable lock={lock} company={params.id} />
                                                
                                        </TabPanel>
                                       

                                    </Box>
                                </Grid>
                            )}
                            {
                                source.code == 'XENTRAL' && (
                                    <Grid item xs={12} sm={12} md={12}>
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={value}  variant="scrollable" scrollButtons="auto" onChange={handleChangeTabs} aria-label="basic tabs example">
                                                <Tab label="General" {...a11yProps(0)} />
                                                <Tab label="Pakaneo Settings" {...a11yProps(1)} />

                                                <Tab label="Syncronization" {...a11yProps(2)} />
                                                <Tab label="FTPS" {...a11yProps(3)} />
                                                {/* <Tab label="Import XML(s)" {...a11yProps(3)} /> */}
                                                {/* <Tab label="Stocks" {...a11yProps(4)} /> */}
                                                <Tab label="Crons" {...a11yProps(4)} />
                                                <Tab label="Crons FTP" {...a11yProps(5)} />
                                                <Tab label="Crons LOGS" {...a11yProps(6)} />
                                                <Tab label="Users" {...a11yProps(7)} />
                                              

                                            </Tabs>
                                        </Box>
                                        <TabPanel value={value} index={0}>
                                             {/* {Additional Fields} */}
                                             <FormControl fullWidth>

                                                <TextField disabled={lock} required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name}
                                                    error={errors.name != undefined ? true : false}
                                                    helperText={errors.name !== undefined ? errors.name : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Company" variant="outlined" InputLabelProps={{ shrink: true }} name="company_name" onChange={handleChange} value={company_name}
                                                    error={errors.company_name != undefined ? true : false}
                                                    helperText={errors.company_name !== undefined ? errors.company_name : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                             <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Street" variant="outlined" InputLabelProps={{ shrink: true }} name="street" onChange={handleChange} value={street}
                                                    error={errors.street != undefined ? true : false}
                                                    helperText={errors.street !== undefined ? errors.street : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Street Number" variant="outlined" InputLabelProps={{ shrink: true }} name="street_number" onChange={handleChange} value={street_number}
                                                    error={errors.street_number != undefined ? true : false}
                                                    helperText={errors.street_number !== undefined ? errors.street_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="City" variant="outlined" InputLabelProps={{ shrink: true }} name="city" onChange={handleChange} value={city}
                                                    error={errors.city != undefined ? true : false}
                                                    helperText={errors.city !== undefined ? errors.city : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Country" variant="outlined" InputLabelProps={{ shrink: true }} name="country" onChange={handleChange} value={country}
                                                    error={errors.country != undefined ? true : false}
                                                    helperText={errors.country !== undefined ? errors.country : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Zip" variant="outlined" InputLabelProps={{ shrink: true }} name="zip" onChange={handleChange} value={zip}
                                                    error={errors.zip != undefined ? true : false}
                                                    helperText={errors.zip !== undefined ? errors.zip : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Tax Identification Number" variant="outlined" InputLabelProps={{ shrink: true }} name="tin_number" onChange={handleChange} value={tin_number}
                                                    error={errors.tin_number != undefined ? true : false}
                                                    helperText={errors.tin_number !== undefined ? errors.tin_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Phone Number" variant="outlined" InputLabelProps={{ shrink: true }} name="phone_number" onChange={handleChange} value={phone_number}
                                                    error={errors.phone_number != undefined ? true : false}
                                                    helperText={errors.phone_number !== undefined ? errors.phone_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                            <FormControl>
                                                <Button variant="contained" disabled={lock}  onChange={handleChange} component="label">
                                                    Upload Company Avatar
                                                <input hidden accept="image/*"  name="avatar" type="file" />
                                                </Button>
                                            </FormControl>
                                            <FormHelperText  error={true}>{errors.avatar!==undefined?errors.avatar:''}</FormHelperText>
                                            <Box mb={2} />

                                            {/* {Additional Fields} */}

                                            <FormControl >
                                                        <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update General Settings</Button>
                                                    </FormControl>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                           <FormControl fullWidth>
                                            <TextField disabled={lock} required type="text" label="URL NAME" variant="outlined" InputLabelProps={{ shrink: true }} name="url_name" onChange={handleChange} value={url_name}
                                                    error={errors.url_name != undefined ? true : false}
                                                    helperText={errors.url_name !== undefined ? errors.url_name : ''}
                                                />
                                            </FormControl>
                                            <Box mb={2} />

                                            <FormControl fullWidth>
                                                <TextField disabled={lock} required type="text"  disabled label="URL" variant="outlined" InputLabelProps={{ shrink: true }} name="url" onChange={handleChange} value={url}
                                                    error={errors.url != undefined ? true : false}
                                                    helperText={errors.url !== undefined ? errors.url : ''}
                                                />

                                            </FormControl>
                                            <Box mb={2} />
                                            <FormControl fullWidth>

                                                <TextField disabled={lock} required type="text" label="Username" variant="outlined" InputLabelProps={{ shrink: true }} name="company_username" onChange={handleChange} value={company_username}
                                                           error={errors.company_username != undefined ? true : false}
                                                           helperText={errors.company_username !== undefined ? errors.token : ''}
                                                />

                                            </FormControl>
                                            <Box mb={2} />
                                            <FormControl fullWidth>

                                                <TextField disabled={lock} required type="password" label="Password" variant="outlined" InputLabelProps={{ shrink: true }} name="company_password" onChange={handleChange} value={company_password}
                                                           error={errors.company_username != undefined ? true : false}
                                                           helperText={errors.company_username !== undefined ? errors.token : ''}
                                                />

                                            </FormControl>

                                            <Box mb={2} />
                                            <FormControl fullWidth>

                                                <TextField disabled={lock} required type="text" label="Token" variant="outlined" InputLabelProps={{ shrink: true }} name="token" onChange={handleChange} value={token}
                                                    error={errors.token != undefined ? true : false}
                                                    helperText={errors.token !== undefined ? errors.token : ''}
                                                />

                                            </FormControl>
                                            <Box mb={2} />
                                            <FormControl >
                                                <Button disabled={lock} variant="outlined" onClick={checkConnectionWithPakaneo} >Check Connection</Button>
                                            </FormControl>
                                            <Box mb={2} />

                                            <FormControl fullWidth>

                                                <TextField disabled={lock} required type="number" label="Sync Order Duration" variant="outlined" InputLabelProps={{ shrink: true }} name="sync_order_duration" onChange={handleChange} value={sync_order_duration}
                                                           error={errors.sync_order_duration != undefined ? true : false}
                                                           helperText={errors.sync_order_duration !== undefined ? errors.sync_order_duration : ''}
                                                />

                                            </FormControl>
                                            <Box mb={2} />
                                          
                                                <FormControl fullWidth>

                                                <TextField disabled={lock} required label="Email" variant="outlined" InputLabelProps={{ shrink: true }} name="company_email" onChange={handleChange} value={company_email}
                                                error={errors.company_email != undefined ? true : false}
                                                helperText={errors.company_email !== undefined ? errors.company_email : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                            

                                            <FormGroup>
                                                <FormControlLabel disabled={lock} control={<Switch  defaultChecked={fifo}  onChange={handleChange}  name="fifo" value={fifo} />} label="FIFO" />
                                                <FormControlLabel disabled={lock} control={<Switch  defaultChecked={serial}  onChange={handleChange} name="serial" value={serial}/>} label="SERIAL" />
                                                <FormControlLabel disabled={lock} control={<Switch  defaultChecked={best_before_date}  onChange={handleChange} name="best_before_date"  value={best_before_date}/>} label="BEST BEFORE DATE" />
                                                <FormControlLabel disabled={lock} control={<Switch  defaultChecked={commit_zero_stock}  onChange={handleChange} name="commit_zero_stock"  value={commit_zero_stock}/>} label="COMMIT ZERO STOCK" />
                                                <FormControlLabel disabled={lock} control={<Switch  defaultChecked={address_correction}  onChange={handleChange}  name="address_correction" value={fifo} />} label="ADDRESS CORRECTION" />

                                            </FormGroup>
                                            <Box mb={2} />
                                            <FormControl >
                                                <Button disabled={lock} variant="outlined" onClick={handleGenerateToken} >Generate Token</Button>
                                            </FormControl>
                                            <Box mb={2} />
                                            <FormControl >
                                                <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update Company</Button>
                                            </FormControl>
                                        </TabPanel>
                                    
                                       
                                        <TabPanel value={value} index={2}>
                                            <Box mb={2} />
                                            <FormControl >
                                                <Button variant="outlined" onClick={syncronizeOrdersXentral} >Syncronize Orders (Xetral)</Button>
                                            </FormControl>
                                        </TabPanel>
                                        <TabPanel value={value} index={3}>
                                            <FtpTable company={params.id} />
                                        </TabPanel>

                                        {/* <TabPanel value={value} index={3}>
                                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }} alignItems="center" >
                                                <Grid item xs={12} sm={12} md={3}>
                                                    <FormControl fullWidth>

                                                        <InputLabel id="demo-simple-select-label">FTP</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={ftp}
                                                            name="ftp"
                                                            onChange={handleChange}
                                                            label="FTP"
                                                        >
                                                            {
                                                                ftps && ftps.map((item) => {

                                                                    return (<MenuItem value={item.id}>{item.host} - {item.username} </MenuItem>)
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={3}>

                                                    <TextField label="Password"  disabled={lock} type="password" variant="outlined" InputLabelProps={{ shrink: true }} name="password" onChange={handleChange} value={password} />

                                                </Grid>

                                                <Grid item xs={12} sm={12} md={3}>
                                                    <FormControl fullWidth>

                                                        <InputLabel id="demo-simple-select-label2">WebService</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={webservice}
                                                            name="webservice"
                                                            onChange={handleChange}
                                                            label="WebService"
                                                        >
                                                            {
                                                                webservices && webservices.map((item) => {

                                                                    return (<MenuItem value={item.id}>{item.name} </MenuItem>)
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={2}>

                                                    <Button variant="outlined" onClick={handleImportFtpSubmit}>IMPORT FROM FTP</Button>
                                                </Grid>

                                            </Grid>
                                        </TabPanel> */}
                                        {/* <TabPanel value={value} index={4}>
                                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }} alignItems="center" >
                                                <Grid item xs={12} sm={12} md={3}>
                                                    <FormControl fullWidth>

                                                        <InputLabel id="demo-simple-select-label">FTP</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={ftp}
                                                            name="ftp"
                                                            onChange={handleChange}
                                                            label="FTP"
                                                        >
                                                            {
                                                                ftps && ftps.map((item) => {

                                                                    return (<MenuItem value={item.id}>{item.host} - {item.username} </MenuItem>)
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={3}>

                                                    <TextField disabled={lock} label="Password" type="password" variant="outlined" InputLabelProps={{ shrink: true }} name="password" onChange={handleChange} value={password} />

                                                </Grid>


                                                <Grid item xs={12} sm={6} md={2}>

                                                    <Button variant="outlined" onClick={handleExportStockFtp}>EXPORT FTP</Button>

                                                </Grid>
                                                <Grid item xs={12} sm={6} md={2}>
                                                    <Button variant="outlined" onClick={handleDownloadStock}>DOWNLOAD JSON</Button>

                                                </Grid>

                                            </Grid>
                                        </TabPanel> */}
                                        <TabPanel value={value} index={4}>
                                                <CronTable  lock={lock} id={params.id}/>
                                                
                                        </TabPanel>
                                        <TabPanel value={value} index={5}>
                                            <Grid item xs={12} sm={12} md={6}>

                                            <FormControl fullWidth>

                                            <InputLabel id="demo-simple-select-label">FTP</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={company_ftp}
                                                name="company_ftp"
                                                disabled={lock}
                                                onChange={handleChange}
                                                label="FTP"
                                            >
                                                {
                                                    ftps && ftps.map((item) => {

                                                        return (<MenuItem value={item.id}>{item.host} - {item.username} </MenuItem>)
                                                    })
                                                }
                                            </Select>
                                            </FormControl>
                                                </Grid>

                                                <Box p={2}/>
                                                <Grid item xs={12} sm={6} md={2}>

                                                <Button disabled={lock} variant="outlined" onClick={setFtpForCron}>SET FTP FOR CRON</Button>

                                                </Grid>           
                                        </TabPanel>
                                        <TabPanel value={value} index={6}>
                                                <CronLogTable lock={lock} handleChangePage={handleChangePage} logs={logs} logs_data={logs_data} />
                                                
                                        </TabPanel>
                                       
                                        <TabPanel value={value} index={7}>
                                            <ClientUserTable lock={lock} company={params.id} />
                                                
                                        </TabPanel>
                                       

                                    </Box>
                                </Grid>
                                )
                            }

                            

                            {source.code =='JSON' &&(

                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value}  variant="scrollable" scrollButtons="auto" onChange={handleChangeTabs} aria-label="basic tabs example">
                                        <Tab label="General" {...a11yProps(0)} />

                                            <Tab label="Pakaneo Settings" {...a11yProps(1)} />
                                            <Tab label="FTPS" {...a11yProps(2)} />
                                            <Tab label="Crons FTP" {...a11yProps(3)} />
                                            <Tab label="Crons" {...a11yProps(4)} />
                                        </Tabs>
                                    </Box>
                            
                            
                                    <TabPanel value={value} index={0}>
                                             {/* {Additional Fields} */}
                                             <FormControl fullWidth>

                                                <TextField disabled={lock} required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name}
                                                    error={errors.name != undefined ? true : false}
                                                    helperText={errors.name !== undefined ? errors.name : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Company" variant="outlined" InputLabelProps={{ shrink: true }} name="company_name" onChange={handleChange} value={company_name}
                                                    error={errors.company_name != undefined ? true : false}
                                                    helperText={errors.company_name !== undefined ? errors.company_name : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                             <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Street" variant="outlined" InputLabelProps={{ shrink: true }} name="street" onChange={handleChange} value={street}
                                                    error={errors.street != undefined ? true : false}
                                                    helperText={errors.street !== undefined ? errors.street : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Street Number" variant="outlined" InputLabelProps={{ shrink: true }} name="street_number" onChange={handleChange} value={street_number}
                                                    error={errors.street_number != undefined ? true : false}
                                                    helperText={errors.street_number !== undefined ? errors.street_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="City" variant="outlined" InputLabelProps={{ shrink: true }} name="city" onChange={handleChange} value={city}
                                                    error={errors.city != undefined ? true : false}
                                                    helperText={errors.city !== undefined ? errors.city : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Country" variant="outlined" InputLabelProps={{ shrink: true }} name="country" onChange={handleChange} value={country}
                                                    error={errors.country != undefined ? true : false}
                                                    helperText={errors.country !== undefined ? errors.country : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Zip" variant="outlined" InputLabelProps={{ shrink: true }} name="zip" onChange={handleChange} value={zip}
                                                    error={errors.zip != undefined ? true : false}
                                                    helperText={errors.zip !== undefined ? errors.zip : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Tax Identification Number" variant="outlined" InputLabelProps={{ shrink: true }} name="tin_number" onChange={handleChange} value={tin_number}
                                                    error={errors.tin_number != undefined ? true : false}
                                                    helperText={errors.tin_number !== undefined ? errors.tin_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Phone Number" variant="outlined" InputLabelProps={{ shrink: true }} name="phone_number" onChange={handleChange} value={phone_number}
                                                    error={errors.phone_number != undefined ? true : false}
                                                    helperText={errors.phone_number !== undefined ? errors.phone_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                            <FormControl>
                                                <Button variant="contained" disabled={lock}  onChange={handleChange} component="label">
                                                    Upload Company Avatar
                                                <input hidden accept="image/*"  name="avatar" type="file" />
                                                </Button>
                                            </FormControl>
                                            <FormHelperText  error={true}>{errors.avatar!==undefined?errors.avatar:''}</FormHelperText>
                                            <Box mb={2} />

                                            {/* {Additional Fields} */}

                                            <FormControl >
                                                        <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update General Settings</Button>
                                                    </FormControl>
                                        </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <FormControl fullWidth>
                                        <TextField disabled={lock} required type="text" label="URL NAME" variant="outlined" InputLabelProps={{ shrink: true }} name="url_name" onChange={handleChange} value={url_name}
                                                error={errors.url_name != undefined ? true : false}
                                                helperText={errors.url_name !== undefined ? errors.url_name : ''}
                                            />
                                        </FormControl>
                                        <Box mb={2} />

                                        <FormControl fullWidth>
                                            <TextField disabled={lock} required type="text"  disabled label="URL" variant="outlined" InputLabelProps={{ shrink: true }} name="url" onChange={handleChange} value={url}
                                                error={errors.url != undefined ? true : false}
                                                helperText={errors.url !== undefined ? errors.url : ''}
                                            />

                                        </FormControl>

                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="text" label="Username" variant="outlined" InputLabelProps={{ shrink: true }} name="company_username" onChange={handleChange} value={company_username}
                                                    error={errors.company_username != undefined ? true : false}
                                                    helperText={errors.company_username !== undefined ? errors.token : ''}
                                            />

                                        </FormControl>
                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="password" label="Password" variant="outlined" InputLabelProps={{ shrink: true }} name="company_password" onChange={handleChange} value={company_password}
                                                    error={errors.company_username != undefined ? true : false}
                                                    helperText={errors.company_username !== undefined ? errors.token : ''}
                                            />

                                        </FormControl>

                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="text" label="Token" variant="outlined" InputLabelProps={{ shrink: true }} name="token" onChange={handleChange} value={token}
                                                error={errors.token != undefined ? true : false}
                                                helperText={errors.token !== undefined ? errors.token : ''}
                                            />

                                        </FormControl>
                                        <Box mb={2} />
                                            <FormControl >
                                                <Button disabled={lock} variant="outlined" onClick={checkConnectionWithPakaneo} >Check Connection</Button>
                                            </FormControl>
                                        <Box mb={2} />


                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="number" label="Sync Order Duration" variant="outlined" InputLabelProps={{ shrink: true }} name="sync_order_duration" onChange={handleChange} value={sync_order_duration}
                                                    error={errors.sync_order_duration != undefined ? true : false}
                                                    helperText={errors.sync_order_duration !== undefined ? errors.sync_order_duration : ''}
                                            />

                                        </FormControl>


                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name}
                                                error={errors.name != undefined ? true : false}
                                                helperText={errors.name !== undefined ? errors.name : ''}
                                            />

                                        </FormControl>
                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                        <TextField disabled={lock} required label="Notification Email" variant="outlined" InputLabelProps={{ shrink: true }} name="company_email" onChange={handleChange} value={company_email}
                                            error={errors.company_email != undefined ? true : false}
                                            helperText={errors.company_email !== undefined ? errors.company_email : ''}
                                        />

                                        </FormControl>
                                        <Box mb={2} />
                                        

                                        {/* <FormGroup>

                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={fifo}  onChange={handleChange}  name="fifo" value={fifo} />} label="FIFO" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={serial}  onChange={handleChange} name="serial" value={serial}/>} label="SERIAL" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={best_before_date}  onChange={handleChange} name="best_before_date"  value={best_before_date}/>} label="BEST BEFORE DATE" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={commit_zero_stock}  onChange={handleChange} name="commit_zero_stock"  value={commit_zero_stock}/>} label="COMMIT ZERO STOCK" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={address_correction}  onChange={handleChange}  name="address_correction" value={fifo} />} label="ADDRESS CORRECTION" />

                                        </FormGroup>
                                        <Box mb={2} /> */}
                                        <FormControl >
                                            <Button disabled={lock} variant="outlined" onClick={handleGenerateToken} >Generate Token</Button>
                                        </FormControl>
                                        <Box mb={2} />

                                        <FormControl >
                                            <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update Company</Button>
                                        </FormControl>

                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                            <FtpTable company={params.id} />
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                            <Grid item xs={12} sm={12} md={6}>

                                            <FormControl fullWidth>

                                            <InputLabel id="demo-simple-select-label">FTP</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={company_ftp}
                                                name="company_ftp"
                                                disabled={lock}
                                                onChange={handleChange}
                                                label="FTP"
                                            >
                                                {
                                                    ftps && ftps.map((item) => {

                                                        return (<MenuItem value={item.id}>{item.host} - {item.username} </MenuItem>)
                                                    })
                                                }
                                            </Select>
                                            </FormControl>
                                                </Grid>

                                                <Box p={2}/>
                                                <Grid item xs={12} sm={6} md={2}>

                                                <Button disabled={lock} variant="outlined" onClick={setFtpForCron}>SET FTP FOR CRON</Button>

                                                </Grid>           
                                        </TabPanel>
                                        <TabPanel value={value} index={4}>
                                                <CronTable  lock={lock} id={params.id}/>
                                                
                                        </TabPanel>
                                           
                                

                                </Box>
                            </Grid>
                            )}

                            {source.code =='HAMBURGER' &&(

                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value}  variant="scrollable" scrollButtons="auto" onChange={handleChangeTabs} aria-label="basic tabs example">
                                            <Tab label="General" {...a11yProps(0)} />
                                            <Tab label="Pakaneo Settings" {...a11yProps(1)} />

                                            <Tab label="Hamburger Settings" {...a11yProps(2)} />
                                            <Tab label="Crons" {...a11yProps(3)} />
                                            <Tab label="Crons LOGS" {...a11yProps(4)} />
                                            <Tab label="Users" {...a11yProps(5)} />
                                        

                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                             {/* {Additional Fields} */}
                                             <FormControl fullWidth>

                                                <TextField disabled={lock} required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name}
                                                    error={errors.name != undefined ? true : false}
                                                    helperText={errors.name !== undefined ? errors.name : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Company" variant="outlined" InputLabelProps={{ shrink: true }} name="company_name" onChange={handleChange} value={company_name}
                                                    error={errors.company_name != undefined ? true : false}
                                                    helperText={errors.company_name !== undefined ? errors.company_name : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                             <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Street" variant="outlined" InputLabelProps={{ shrink: true }} name="street" onChange={handleChange} value={street}
                                                    error={errors.street != undefined ? true : false}
                                                    helperText={errors.street !== undefined ? errors.street : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Street Number" variant="outlined" InputLabelProps={{ shrink: true }} name="street_number" onChange={handleChange} value={street_number}
                                                    error={errors.street_number != undefined ? true : false}
                                                    helperText={errors.street_number !== undefined ? errors.street_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="City" variant="outlined" InputLabelProps={{ shrink: true }} name="city" onChange={handleChange} value={city}
                                                    error={errors.city != undefined ? true : false}
                                                    helperText={errors.city !== undefined ? errors.city : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Country" variant="outlined" InputLabelProps={{ shrink: true }} name="country" onChange={handleChange} value={country}
                                                    error={errors.country != undefined ? true : false}
                                                    helperText={errors.country !== undefined ? errors.country : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Zip" variant="outlined" InputLabelProps={{ shrink: true }} name="zip" onChange={handleChange} value={zip}
                                                    error={errors.zip != undefined ? true : false}
                                                    helperText={errors.zip !== undefined ? errors.zip : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Tax Identification Number" variant="outlined" InputLabelProps={{ shrink: true }} name="tin_number" onChange={handleChange} value={tin_number}
                                                    error={errors.tin_number != undefined ? true : false}
                                                    helperText={errors.tin_number !== undefined ? errors.tin_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Phone Number" variant="outlined" InputLabelProps={{ shrink: true }} name="phone_number" onChange={handleChange} value={phone_number}
                                                    error={errors.phone_number != undefined ? true : false}
                                                    helperText={errors.phone_number !== undefined ? errors.phone_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                            <FormControl>
                                                <Button variant="contained" disabled={lock}  onChange={handleChange} component="label">
                                                    Upload Company Avatar
                                                <input hidden accept="image/*"  name="avatar" type="file" />
                                                </Button>
                                            </FormControl>
                                            <FormHelperText  error={true}>{errors.avatar!==undefined?errors.avatar:''}</FormHelperText>
                                            <Box mb={2} />

                                            {/* {Additional Fields} */}

                                            <FormControl >
                                                        <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update General Settings</Button>
                                                    </FormControl>
                                        </TabPanel>
                                    <TabPanel value={value} index={1}>
                                    <   FormControl fullWidth>
                                        <TextField disabled={lock} required type="text" label="URL NAME" variant="outlined" InputLabelProps={{ shrink: true }} name="url_name" onChange={handleChange} value={url_name}
                                                error={errors.url_name != undefined ? true : false}
                                                helperText={errors.url_name !== undefined ? errors.url_name : ''}
                                            />
                                        </FormControl>
                                        <Box mb={2} />

                                        <FormControl fullWidth>
                                            <TextField disabled={lock} required type="text"  disabled label="URL" variant="outlined" InputLabelProps={{ shrink: true }} name="url" onChange={handleChange} value={url}
                                                error={errors.url != undefined ? true : false}
                                                helperText={errors.url !== undefined ? errors.url : ''}
                                            />

                                        </FormControl>

                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="text" label="Username" variant="outlined" InputLabelProps={{ shrink: true }} name="company_username" onChange={handleChange} value={company_username}
                                                    error={errors.company_username != undefined ? true : false}
                                                    helperText={errors.company_username !== undefined ? errors.token : ''}
                                            />

                                        </FormControl>
                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="password" label="Password" variant="outlined" InputLabelProps={{ shrink: true }} name="company_password" onChange={handleChange} value={company_password}
                                                    error={errors.company_username != undefined ? true : false}
                                                    helperText={errors.company_username !== undefined ? errors.token : ''}
                                            />

                                        </FormControl>

                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="text" label="Token" variant="outlined" InputLabelProps={{ shrink: true }} name="token" onChange={handleChange} value={token}
                                                error={errors.token != undefined ? true : false}
                                                helperText={errors.token !== undefined ? errors.token : ''}
                                            />

                                        </FormControl>
                                        <Box mb={2} />
                                            <FormControl >
                                                <Button disabled={lock} variant="outlined" onClick={checkConnectionWithPakaneo} >Check Connection</Button>
                                            </FormControl>
                                            <Box mb={2} />


                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="number" label="Sync Order Duration" variant="outlined" InputLabelProps={{ shrink: true }} name="sync_order_duration" onChange={handleChange} value={sync_order_duration}
                                                    error={errors.sync_order_duration != undefined ? true : false}
                                                    helperText={errors.sync_order_duration !== undefined ? errors.sync_order_duration : ''}
                                            />

                                        </FormControl>


                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name}
                                                error={errors.name != undefined ? true : false}
                                                helperText={errors.name !== undefined ? errors.name : ''}
                                            />

                                        </FormControl>
                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                        <TextField disabled={lock} required label="Notification Email" variant="outlined" InputLabelProps={{ shrink: true }} name="company_email" onChange={handleChange} value={company_email}
                                            error={errors.company_email != undefined ? true : false}
                                            helperText={errors.company_email !== undefined ? errors.company_email : ''}
                                        />

                                        </FormControl>
                                        <Box mb={2} />
                                       

                                        <FormGroup>

                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={fifo}  onChange={handleChange}  name="fifo" value={fifo} />} label="FIFO" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={serial}  onChange={handleChange} name="serial" value={serial}/>} label="SERIAL" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={best_before_date}  onChange={handleChange} name="best_before_date"  value={best_before_date}/>} label="BEST BEFORE DATE" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={commit_zero_stock}  onChange={handleChange} name="commit_zero_stock"  value={commit_zero_stock}/>} label="COMMIT ZERO STOCK" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={address_correction}  onChange={handleChange}  name="address_correction" value={fifo} />} label="ADDRESS CORRECTION" />

                                        </FormGroup>
                                        <Box mb={2} />
                                        <FormControl >
                                            <Button disabled={lock} variant="outlined" onClick={handleGenerateToken} >Generate Token</Button>
                                        </FormControl>
                                        <Box mb={2} />

                                        <FormControl >
                                            <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update Company</Button>
                                        </FormControl>

                                    </TabPanel>
                                
                                            <TabPanel value={value} index={2}>
                                            <Box p={2}>
                                                {
                                                source_fields && source_fields.map(item=>(
                                                <div>
                                                    <FormControl fullWidth >
                                                        {
                                                            getSettingField(item)
                                                        }


                                                </FormControl>
                                                <Box p={2}/>
                                                </div>
                                                ))
                                                }    
                                            <Button disabled={lock} variant="outlined" onClick={handleSaveSourceSetting}>Save Settings </Button>
                                            <br/>
                                            <br/>
                                            {/* <Button variant="outlined" onClick={handleProcessOrder}>Import Orders</Button>
                                            <br/>
                                            <br/>
                                            <Button variant="outlined" onClick={handleProcessProducts}>Import Products</Button>
                                            <br/>
                                            <br/>
                                            <Button variant="outlined" onClick={handleUpdateStock}>Update Stock</Button> */}




                                            </Box>  
                                            </TabPanel>
                                

                                    

                                
                                
                                    <TabPanel value={value} index={3}>
                                            <CronTable lock={lock} id={params.id}/>
                                            
                                    </TabPanel>
                                
                                    <TabPanel value={value} index={4}>
                                            <CronLogTable handleChangePage={handleChangePage} logs={logs} logs_data={logs_data} />
                                            
                                    </TabPanel>
                                
                                    <TabPanel value={value} index={5}>
                                        <ClientUserTable lock={lock} company={params.id} />
                                            
                                    </TabPanel>
                                

                                </Box>
                            </Grid>
                            )}

                            {source.code =='ODOO' &&(

                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value}  variant="scrollable" scrollButtons="auto" onChange={handleChangeTabs} aria-label="basic tabs example">
                                            <Tab label="General" {...a11yProps(0)} />
                                            <Tab label="Pakaneo Settings" {...a11yProps(1)} />

                                            <Tab label="Odoo Settings" {...a11yProps(1)} />
                                            <Tab label="Crons" {...a11yProps(2)} />
                                            <Tab label="Crons LOGS" {...a11yProps(3)} />
                                            <Tab label="Users" {...a11yProps(4)} />
                                        

                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                             {/* {Additional Fields} */}
                                             <FormControl fullWidth>

                                                <TextField disabled={lock} required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name}
                                                    error={errors.name != undefined ? true : false}
                                                    helperText={errors.name !== undefined ? errors.name : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Company" variant="outlined" InputLabelProps={{ shrink: true }} name="company_name" onChange={handleChange} value={company_name}
                                                    error={errors.company_name != undefined ? true : false}
                                                    helperText={errors.company_name !== undefined ? errors.company_name : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                             <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Street" variant="outlined" InputLabelProps={{ shrink: true }} name="street" onChange={handleChange} value={street}
                                                    error={errors.street != undefined ? true : false}
                                                    helperText={errors.street !== undefined ? errors.street : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Street Number" variant="outlined" InputLabelProps={{ shrink: true }} name="street_number" onChange={handleChange} value={street_number}
                                                    error={errors.street_number != undefined ? true : false}
                                                    helperText={errors.street_number !== undefined ? errors.street_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="City" variant="outlined" InputLabelProps={{ shrink: true }} name="city" onChange={handleChange} value={city}
                                                    error={errors.city != undefined ? true : false}
                                                    helperText={errors.city !== undefined ? errors.city : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Country" variant="outlined" InputLabelProps={{ shrink: true }} name="country" onChange={handleChange} value={country}
                                                    error={errors.country != undefined ? true : false}
                                                    helperText={errors.country !== undefined ? errors.country : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Zip" variant="outlined" InputLabelProps={{ shrink: true }} name="zip" onChange={handleChange} value={zip}
                                                    error={errors.zip != undefined ? true : false}
                                                    helperText={errors.zip !== undefined ? errors.zip : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Tax Identification Number" variant="outlined" InputLabelProps={{ shrink: true }} name="tin_number" onChange={handleChange} value={tin_number}
                                                    error={errors.tin_number != undefined ? true : false}
                                                    helperText={errors.tin_number !== undefined ? errors.tin_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                <TextField disabled={lock}  label="Phone Number" variant="outlined" InputLabelProps={{ shrink: true }} name="phone_number" onChange={handleChange} value={phone_number}
                                                    error={errors.phone_number != undefined ? true : false}
                                                    helperText={errors.phone_number !== undefined ? errors.phone_number : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                            <FormControl>
                                                <Button variant="contained" disabled={lock}  onChange={handleChange} component="label">
                                                    Upload Company Avatar
                                                <input hidden accept="image/*"  name="avatar" type="file" />
                                                </Button>
                                            </FormControl>
                                            <FormHelperText  error={true}>{errors.avatar!==undefined?errors.avatar:''}</FormHelperText>
                                            <Box mb={2} />

                                            {/* {Additional Fields} */}

                                            <FormControl >
                                                        <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update General Settings</Button>
                                                    </FormControl>
                                        </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <FormControl fullWidth>
                                        <TextField disabled={lock} required type="text" label="URL NAME" variant="outlined" InputLabelProps={{ shrink: true }} name="url_name" onChange={handleChange} value={url_name}
                                                error={errors.url_name != undefined ? true : false}
                                                helperText={errors.url_name !== undefined ? errors.url_name : ''}
                                            />
                                        </FormControl>
                                        <Box mb={2} />

                                        <FormControl fullWidth>
                                            <TextField disabled={lock} required type="text"  disabled label="URL" variant="outlined" InputLabelProps={{ shrink: true }} name="url" onChange={handleChange} value={url}
                                                error={errors.url != undefined ? true : false}
                                                helperText={errors.url !== undefined ? errors.url : ''}
                                            />

                                        </FormControl>

                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="text" label="Username" variant="outlined" InputLabelProps={{ shrink: true }} name="company_username" onChange={handleChange} value={company_username}
                                                    error={errors.company_username != undefined ? true : false}
                                                    helperText={errors.company_username !== undefined ? errors.token : ''}
                                            />

                                        </FormControl>
                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="password" label="Password" variant="outlined" InputLabelProps={{ shrink: true }} name="company_password" onChange={handleChange} value={company_password}
                                                    error={errors.company_username != undefined ? true : false}
                                                    helperText={errors.company_username !== undefined ? errors.token : ''}
                                            />

                                        </FormControl>

                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="text" label="Token" variant="outlined" InputLabelProps={{ shrink: true }} name="token" onChange={handleChange} value={token}
                                                error={errors.token != undefined ? true : false}
                                                helperText={errors.token !== undefined ? errors.token : ''}
                                            />

                                        </FormControl>
                                        <Box mb={2} />
                                            <FormControl >
                                                <Button disabled={lock} variant="outlined" onClick={checkConnectionWithPakaneo} >Check Connection</Button>
                                            </FormControl>
                                            <Box mb={2} />


                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required type="number" label="Sync Order Duration" variant="outlined" InputLabelProps={{ shrink: true }} name="sync_order_duration" onChange={handleChange} value={sync_order_duration}
                                                    error={errors.sync_order_duration != undefined ? true : false}
                                                    helperText={errors.sync_order_duration !== undefined ? errors.sync_order_duration : ''}
                                            />

                                        </FormControl>


                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name}
                                                error={errors.name != undefined ? true : false}
                                                helperText={errors.name !== undefined ? errors.name : ''}
                                            />

                                        </FormControl>
                                        <Box mb={2} />
                                        <FormControl fullWidth>

                                        <TextField disabled={lock} required label="Notification Email" variant="outlined" InputLabelProps={{ shrink: true }} name="company_email" onChange={handleChange} value={company_email}
                                            error={errors.company_email != undefined ? true : false}
                                            helperText={errors.company_email !== undefined ? errors.company_email : ''}
                                        />

                                        </FormControl>
                                        <Box mb={2} />

                                        
                                        <FormGroup>

                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={fifo}  onChange={handleChange}  name="fifo" value={fifo} />} label="FIFO" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={serial}  onChange={handleChange} name="serial" value={serial}/>} label="SERIAL" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={best_before_date}  onChange={handleChange} name="best_before_date"  value={best_before_date}/>} label="BEST BEFORE DATE" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={commit_zero_stock}  onChange={handleChange} name="commit_zero_stock"  value={commit_zero_stock}/>} label="COMMIT ZERO STOCK" />
                                            <FormControlLabel disabled={lock} control={<Switch  defaultChecked={address_correction}  onChange={handleChange}  name="address_correction" value={fifo} />} label="ADDRESS CORRECTION" />

                                        </FormGroup>
                                        <Box mb={2} />
                                        <FormControl >
                                            <Button disabled={lock} variant="outlined" onClick={handleGenerateToken} >Generate Token</Button>
                                        </FormControl>
                                        <Box mb={2} />

                                        <FormControl >
                                            <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update Company</Button>
                                        </FormControl>

                                    </TabPanel>
                                
                                            <TabPanel value={value} index={2}>
                                            <Box p={2}>
                                                {
                                                source_fields && source_fields.map(item=>(
                                                <div>
                                                    <FormControl fullWidth >
                                                        {
                                                            getSettingField(item)
                                                        }


                                                </FormControl>
                                                <Box p={2}/>
                                                </div>
                                                ))
                                                }    
                                            <Button disabled={lock} variant="outlined" onClick={handleSaveSourceSetting}>Save Settings </Button>
                                            <br/>
                                            <br/>
                                            {/* <Button variant="outlined" onClick={handleProcessOrder}>Import Orders</Button>
                                            <br/>
                                            <br/>
                                            <Button variant="outlined" onClick={handleProcessProducts}>Import Products</Button>
                                            <br/>
                                            <br/>
                                            <Button variant="outlined" onClick={handleUpdateStock}>Update Stock</Button> */}




                                            </Box>  
                                            </TabPanel>
                                

                                    

                                
                                
                                    <TabPanel value={value} index={3}>
                                            <CronTable lock={lock} id={params.id}/>
                                            
                                    </TabPanel>
                                
                                    <TabPanel value={value} index={4}>
                                            <CronLogTable handleChangePage={handleChangePage} logs={logs} logs_data={logs_data} />
                                            
                                    </TabPanel>
                                
                                    <TabPanel value={value} index={5}>
                                        <ClientUserTable lock={lock} company={params.id} />
                                            
                                    </TabPanel>
                                

                                </Box>
                            </Grid>
                            )}

                            {source.code =='WH1' &&(

                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value}  variant="scrollable" scrollButtons="auto" onChange={handleChangeTabs} aria-label="basic tabs example">
                                            <Tab label="General" {...a11yProps(0)} />
                                            <Tab label="Pakaneo Settings" {...a11yProps(1)} />
                                            <Tab label="WH1+ Settings" {...a11yProps(2)} />
                                            <Tab label="Crons" {...a11yProps(3)} />
                                            <Tab label="Crons LOGS" {...a11yProps(4)} />
                                            <Tab label="Users" {...a11yProps(5)} />
                                        

                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        {/* {Additional Fields} */}
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name}
                                                error={errors.name != undefined ? true : false}
                                                helperText={errors.name !== undefined ? errors.name : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />
                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Company" variant="outlined" InputLabelProps={{ shrink: true }} name="company_name" onChange={handleChange} value={company_name}
                                                error={errors.company_name != undefined ? true : false}
                                                helperText={errors.company_name !== undefined ? errors.company_name : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Street" variant="outlined" InputLabelProps={{ shrink: true }} name="street" onChange={handleChange} value={street}
                                                error={errors.street != undefined ? true : false}
                                                helperText={errors.street !== undefined ? errors.street : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />
                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Street Number" variant="outlined" InputLabelProps={{ shrink: true }} name="street_number" onChange={handleChange} value={street_number}
                                                error={errors.street_number != undefined ? true : false}
                                                helperText={errors.street_number !== undefined ? errors.street_number : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />

                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="City" variant="outlined" InputLabelProps={{ shrink: true }} name="city" onChange={handleChange} value={city}
                                                error={errors.city != undefined ? true : false}
                                                helperText={errors.city !== undefined ? errors.city : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />

                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Country" variant="outlined" InputLabelProps={{ shrink: true }} name="country" onChange={handleChange} value={country}
                                                error={errors.country != undefined ? true : false}
                                                helperText={errors.country !== undefined ? errors.country : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />


                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Zip" variant="outlined" InputLabelProps={{ shrink: true }} name="zip" onChange={handleChange} value={zip}
                                                error={errors.zip != undefined ? true : false}
                                                helperText={errors.zip !== undefined ? errors.zip : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />

                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Tax Identification Number" variant="outlined" InputLabelProps={{ shrink: true }} name="tin_number" onChange={handleChange} value={tin_number}
                                                error={errors.tin_number != undefined ? true : false}
                                                helperText={errors.tin_number !== undefined ? errors.tin_number : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />


                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Phone Number" variant="outlined" InputLabelProps={{ shrink: true }} name="phone_number" onChange={handleChange} value={phone_number}
                                                error={errors.phone_number != undefined ? true : false}
                                                helperText={errors.phone_number !== undefined ? errors.phone_number : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />

                                        <FormControl>
                                            <Button variant="contained" disabled={lock}  onChange={handleChange} component="label">
                                                Upload Company Avatar
                                            <input hidden accept="image/*"  name="avatar" type="file" />
                                            </Button>
                                        </FormControl>
                                        <FormHelperText  error={true}>{errors.avatar!==undefined?errors.avatar:''}</FormHelperText>
                                        <Box mb={2} />

                                        {/* {Additional Fields} */}

                                        <FormControl >
                                                    <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update General Settings</Button>
                                                </FormControl>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                            <FormControl fullWidth>
                                                <TextField disabled={lock} required type="text" label="URL NAME" variant="outlined" InputLabelProps={{ shrink: true }} name="url_name" onChange={handleChange} value={url_name}
                                                        error={errors.url_name != undefined ? true : false}
                                                        helperText={errors.url_name !== undefined ? errors.url_name : ''}
                                                    />
                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>
                                                    <TextField disabled={lock} required type="text"  disabled label="URL" variant="outlined" InputLabelProps={{ shrink: true }} name="url" onChange={handleChange} value={url}
                                                        error={errors.url != undefined ? true : false}
                                                        helperText={errors.url !== undefined ? errors.url : ''}
                                                    />

                                                </FormControl>

                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                    <TextField disabled={lock} required type="text" label="Username" variant="outlined" InputLabelProps={{ shrink: true }} name="company_username" onChange={handleChange} value={company_username}
                                                            error={errors.company_username != undefined ? true : false}
                                                            helperText={errors.company_username !== undefined ? errors.token : ''}
                                                    />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                    <TextField disabled={lock} required type="password" label="Password" variant="outlined" InputLabelProps={{ shrink: true }} name="company_password" onChange={handleChange} value={company_password}
                                                            error={errors.company_username != undefined ? true : false}
                                                            helperText={errors.company_username !== undefined ? errors.token : ''}
                                                    />

                                                </FormControl>

                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                    <TextField disabled={lock} required type="text" label="Token" variant="outlined" InputLabelProps={{ shrink: true }} name="token" onChange={handleChange} value={token}
                                                        error={errors.token != undefined ? true : false}
                                                        helperText={errors.token !== undefined ? errors.token : ''}
                                                    />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl >
                                                    <Button disabled={lock} variant="outlined" onClick={checkConnectionWithPakaneo} >Check Connection</Button>
                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                    <TextField disabled={lock} required type="number" label="Sync Order Duration" variant="outlined" InputLabelProps={{ shrink: true }} name="sync_order_duration" onChange={handleChange} value={sync_order_duration}
                                                            error={errors.sync_order_duration != undefined ? true : false}
                                                            helperText={errors.sync_order_duration !== undefined ? errors.sync_order_duration : ''}
                                                    />

                                                </FormControl>


                                                <Box mb={2} />
                                                
                                                <FormControl fullWidth>

                                                <TextField disabled={lock} required label="Notification Email" variant="outlined" InputLabelProps={{ shrink: true }} name="company_email" onChange={handleChange} value={company_email}
                                                    error={errors.company_email != undefined ? true : false}
                                                    helperText={errors.company_email !== undefined ? errors.company_email : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                            


                                                <FormGroup>

                                                    <FormControlLabel disabled={lock} control={<Switch  defaultChecked={fifo}  onChange={handleChange}  name="fifo" value={fifo} />} label="FIFO" />
                                                    <FormControlLabel disabled={lock} control={<Switch  defaultChecked={serial}  onChange={handleChange} name="serial" value={serial}/>} label="SERIAL" />
                                                    <FormControlLabel disabled={lock} control={<Switch  defaultChecked={best_before_date}  onChange={handleChange} name="best_before_date"  value={best_before_date}/>} label="BEST BEFORE DATE" />
                                                    <FormControlLabel disabled={lock} control={<Switch  defaultChecked={commit_zero_stock}  onChange={handleChange} name="commit_zero_stock"  value={commit_zero_stock}/>} label="COMMIT ZERO STOCK" />
                                                    <FormControlLabel disabled={lock} control={<Switch  defaultChecked={address_correction}  onChange={handleChange}  name="address_correction" value={fifo} />} label="ADDRESS CORRECTION" />

                                                </FormGroup>
                                                <Box mb={2} />
                                                <FormControl >
                                                    <Button disabled={lock} variant="outlined" onClick={handleGenerateToken} >Generate Token</Button>
                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl >
                                                    <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update Company</Button>
                                                </FormControl>

                                            </TabPanel>
                                
                                            <TabPanel value={value} index={2}>
                                            <Box p={2}>
                                                {
                                                source_fields && source_fields.map(item=>(
                                                <div>
                                                    <FormControl fullWidth >
                                                        {
                                                            getSettingField(item)
                                                        }


                                                </FormControl>
                                                <Box p={2}/>
                                                </div>
                                                ))
                                                }  

                                            <Button disabled={lock} variant="outlined" onClick={handleCheckWh1Connection}>Check Connection </Button>
                                            <br/>
                                            <br/>  
                                            <Button disabled={lock} variant="outlined" onClick={handleSaveSourceSetting}>Save Settings </Button>
                                            <br/>
                                            <br/>  
                                            




                                            </Box>  
                                            </TabPanel>
                                

                                    

                                
                                
                                    <TabPanel value={value} index={3}>
                                            <CronTable lock={lock} id={params.id}/>
                                            
                                    </TabPanel>
                                
                                    <TabPanel value={value} index={4}>
                                            <CronLogTable handleChangePage={handleChangePage} logs={logs} logs_data={logs_data} />
                                            
                                    </TabPanel>
                                
                                    <TabPanel value={value} index={5}>
                                        <ClientUserTable lock={lock} company={params.id} />
                                            
                                    </TabPanel>
                                

                                </Box>
                            </Grid>
                            )}

                            {source.code =='SHOPIFY' &&(

                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value}  variant="scrollable" scrollButtons="auto" onChange={handleChangeTabs} aria-label="basic tabs example">
                                            <Tab label="General" {...a11yProps(0)} />
                                            <Tab label="Pakaneo Settings" {...a11yProps(1)} />
                                            <Tab label="Shopify Settings" {...a11yProps(2)} />
                                            <Tab label="Crons" {...a11yProps(3)} />
                                            <Tab label="Crons LOGS" {...a11yProps(4)} />
                                            <Tab label="Users" {...a11yProps(5)} />
                                        

                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        {/* {Additional Fields} */}
                                        <FormControl fullWidth>

                                            <TextField disabled={lock} required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name}
                                                error={errors.name != undefined ? true : false}
                                                helperText={errors.name !== undefined ? errors.name : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />
                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Company" variant="outlined" InputLabelProps={{ shrink: true }} name="company_name" onChange={handleChange} value={company_name}
                                                error={errors.company_name != undefined ? true : false}
                                                helperText={errors.company_name !== undefined ? errors.company_name : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />
                                        <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Street" variant="outlined" InputLabelProps={{ shrink: true }} name="street" onChange={handleChange} value={street}
                                                error={errors.street != undefined ? true : false}
                                                helperText={errors.street !== undefined ? errors.street : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />
                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Street Number" variant="outlined" InputLabelProps={{ shrink: true }} name="street_number" onChange={handleChange} value={street_number}
                                                error={errors.street_number != undefined ? true : false}
                                                helperText={errors.street_number !== undefined ? errors.street_number : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />

                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="City" variant="outlined" InputLabelProps={{ shrink: true }} name="city" onChange={handleChange} value={city}
                                                error={errors.city != undefined ? true : false}
                                                helperText={errors.city !== undefined ? errors.city : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />

                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Country" variant="outlined" InputLabelProps={{ shrink: true }} name="country" onChange={handleChange} value={country}
                                                error={errors.country != undefined ? true : false}
                                                helperText={errors.country !== undefined ? errors.country : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />


                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Zip" variant="outlined" InputLabelProps={{ shrink: true }} name="zip" onChange={handleChange} value={zip}
                                                error={errors.zip != undefined ? true : false}
                                                helperText={errors.zip !== undefined ? errors.zip : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />

                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Tax Identification Number" variant="outlined" InputLabelProps={{ shrink: true }} name="tin_number" onChange={handleChange} value={tin_number}
                                                error={errors.tin_number != undefined ? true : false}
                                                helperText={errors.tin_number !== undefined ? errors.tin_number : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />


                                            <FormControl fullWidth>

                                            <TextField disabled={lock}  label="Phone Number" variant="outlined" InputLabelProps={{ shrink: true }} name="phone_number" onChange={handleChange} value={phone_number}
                                                error={errors.phone_number != undefined ? true : false}
                                                helperText={errors.phone_number !== undefined ? errors.phone_number : ''}
                                            />

                                            </FormControl>
                                            <Box mb={2} />

                                        <FormControl>
                                            <Button variant="contained" disabled={lock}  onChange={handleChange} component="label">
                                                Upload Company Avatar
                                            <input hidden accept="image/*"  name="avatar" type="file" />
                                            </Button>
                                        </FormControl>
                                        <FormHelperText  error={true}>{errors.avatar!==undefined?errors.avatar:''}</FormHelperText>
                                        <Box mb={2} />

                                        {/* {Additional Fields} */}

                                        <FormControl >
                                                    <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update General Settings</Button>
                                                </FormControl>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                            <FormControl fullWidth>
                                                <TextField disabled={lock} required type="text" label="URL NAME" variant="outlined" InputLabelProps={{ shrink: true }} name="url_name" onChange={handleChange} value={url_name}
                                                        error={errors.url_name != undefined ? true : false}
                                                        helperText={errors.url_name !== undefined ? errors.url_name : ''}
                                                    />
                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl fullWidth>
                                                    <TextField disabled={lock} required type="text"  disabled label="URL" variant="outlined" InputLabelProps={{ shrink: true }} name="url" onChange={handleChange} value={url}
                                                        error={errors.url != undefined ? true : false}
                                                        helperText={errors.url !== undefined ? errors.url : ''}
                                                    />

                                                </FormControl>

                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                    <TextField disabled={lock} required type="text" label="Username" variant="outlined" InputLabelProps={{ shrink: true }} name="company_username" onChange={handleChange} value={company_username}
                                                            error={errors.company_username != undefined ? true : false}
                                                            helperText={errors.company_username !== undefined ? errors.token : ''}
                                                    />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                    <TextField disabled={lock} required type="password" label="Password" variant="outlined" InputLabelProps={{ shrink: true }} name="company_password" onChange={handleChange} value={company_password}
                                                            error={errors.company_username != undefined ? true : false}
                                                            helperText={errors.company_username !== undefined ? errors.token : ''}
                                                    />

                                                </FormControl>

                                                <Box mb={2} />
                                                <FormControl fullWidth>

                                                    <TextField disabled={lock} required type="text" label="Token" variant="outlined" InputLabelProps={{ shrink: true }} name="token" onChange={handleChange} value={token}
                                                        error={errors.token != undefined ? true : false}
                                                        helperText={errors.token !== undefined ? errors.token : ''}
                                                    />

                                                </FormControl>
                                                <Box mb={2} />
                                                <FormControl >
                                                    <Button disabled={lock} variant="outlined" onClick={checkConnectionWithPakaneo} >Check Connection</Button>
                                                </FormControl>
                                                <Box mb={2} />


                                                <FormControl fullWidth>

                                                    <TextField disabled={lock} required type="number" label="Sync Order Duration" variant="outlined" InputLabelProps={{ shrink: true }} name="sync_order_duration" onChange={handleChange} value={sync_order_duration}
                                                            error={errors.sync_order_duration != undefined ? true : false}
                                                            helperText={errors.sync_order_duration !== undefined ? errors.sync_order_duration : ''}
                                                    />

                                                </FormControl>


                                                <Box mb={2} />
                                                
                                                <FormControl fullWidth>

                                                <TextField disabled={lock} required label="Notification Email" variant="outlined" InputLabelProps={{ shrink: true }} name="company_email" onChange={handleChange} value={company_email}
                                                    error={errors.company_email != undefined ? true : false}
                                                    helperText={errors.company_email !== undefined ? errors.company_email : ''}
                                                />

                                                </FormControl>
                                                <Box mb={2} />

                                            


                                                <FormGroup>

                                                    <FormControlLabel disabled={lock} control={<Switch  defaultChecked={fifo}  onChange={handleChange}  name="fifo" value={fifo} />} label="FIFO" />
                                                    <FormControlLabel disabled={lock} control={<Switch  defaultChecked={serial}  onChange={handleChange} name="serial" value={serial}/>} label="SERIAL" />
                                                    <FormControlLabel disabled={lock} control={<Switch  defaultChecked={best_before_date}  onChange={handleChange} name="best_before_date"  value={best_before_date}/>} label="BEST BEFORE DATE" />
                                                    <FormControlLabel disabled={lock} control={<Switch  defaultChecked={commit_zero_stock}  onChange={handleChange} name="commit_zero_stock"  value={commit_zero_stock}/>} label="COMMIT ZERO STOCK" />
                                                    <FormControlLabel disabled={lock} control={<Switch  defaultChecked={address_correction}  onChange={handleChange}  name="address_correction" value={fifo} />} label="ADDRESS CORRECTION" />

                                                </FormGroup>
                                                <Box mb={2} />
                                                <FormControl >
                                                    <Button disabled={lock} variant="outlined" onClick={handleGenerateToken} >Generate Token</Button>
                                                </FormControl>
                                                <Box mb={2} />

                                                <FormControl >
                                                    <Button disabled={lock} variant="outlined" onClick={handleSubmit} >Update Company</Button>
                                                </FormControl>

                                            </TabPanel>
                                
                                            <TabPanel value={value} index={2}>
                                            <Box p={2}>
                                                {
                                                source_fields && source_fields.map(item=>(
                                                <div>
                                                    <FormControl fullWidth >
                                                        {
                                                            getSettingField(item)
                                                        }


                                                </FormControl>
                                                <Box p={2}/>
                                                </div>
                                                ))
                                                }  

                                            <Button disabled={lock} variant="outlined" onClick={handleCheckBillbeeConnection}>Check Connection </Button>
                                            <br/>
                                            <br/>  
                                            <Button disabled={lock} variant="outlined" onClick={handleSaveSourceSetting}>Save Settings </Button>
                                            <br/>
                                            <br/>
                                            {/* <Button variant="outlined" onClick={handleProcessOrder}>Import Orders</Button>
                                            <br/>
                                            <br/>
                                            <Button variant="outlined" onClick={handleProcessProducts}>Import Products</Button>
                                            <br/>
                                            <br/>
                                            <Button variant="outlined" onClick={handleUpdateStock}>Update Stock</Button>
 */}



                                            </Box>  
                                            </TabPanel>
                                

                                    

                                
                                
                                    <TabPanel value={value} index={3}>
                                            <CronTable lock={lock} id={params.id}/>
                                            
                                    </TabPanel>
                                
                                    <TabPanel value={value} index={4}>
                                            <CronLogTable handleChangePage={handleChangePage} logs={logs} logs_data={logs_data} />
                                            
                                    </TabPanel>
                                
                                    <TabPanel value={value} index={5}>
                                        <ClientUserTable lock={lock} company={params.id} />
                                            
                                    </TabPanel>
                                

                                </Box>
                            </Grid>
                            )}
                            </Grid>











                        </Box>
                    </Paper>
                </Grid>

            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => { return; }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </DashboardContent>
    );
}

export default EditCompany;
