import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import { Backdrop, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Pagination, Paper, Select, TableContainer, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { deleteOrder, getAllOrders, getOrders, setOrdersExportFlag, syncOrdersWithPakaneo } from '../apis/Orders';
import exportFromJSON from "export-from-json";
// import { connectFtp } from '../../ftp/FtpService';
import { useEffect } from 'react';
import { getFtps, getFtpsAll } from '../apis/Ftp';
import { Box } from '@mui/system';
import { uploadXmlToFTP } from '../apis/Xml';
import {Buffer} from 'buffer';
import { getUserRole } from '../apis/Auth';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'; 
import { getErpLogs } from '../apis/ErpLogs';
import DashboardContent from '../layouts/DashboardLayout';
import { useParams } from 'react-router-dom';



export default function ErpLogTable(props) {

    const [loading, setLoading] = useState(false);
    const [erps, setErps] = useState([]);
    const [erps_data,setErpsData]  = useState([]);
    const [page,setpage] = useState(1);
    const params = useParams();
    







    const handleChangePage=(event,value)=>{
       
        setLoading(true);
        setErps([]);
        setErpsData([]);

        setpage(value);
      
        getErpLogs(params.id,value)
        .then(data=>{
            //console.log(data.data);
            setErps(data.data.data);
            setErpsData(data.data);
            setLoading(false);
        })
        
    }

  


    useEffect(() => {
        console.log(props);
        getErpLogs(params.id)
            .then(data => {
                setErps(data.data.data);
                setErpsData(data.data);

            })
        // console.log(connectFtp())
    }, [])


    
    return (

        <React.Fragment >
               <DashboardContent title="ERP Logs">
                <Paper elevation={2} >


            <ToastContainer />
           
                <Box mt={3}/>
                <Box p={2}>
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                Erp Logs &nbsp;
               



            </Typography>

       

                <TableContainer >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Erp</TableCell>
                                <TableCell>Log</TableCell>
                                <TableCell>Date Created</TableCell>



                                





                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {erps.map((row) => {
                              

                                return (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.erp_code}</TableCell>
                                        <TableCell>{row.log}</TableCell>
                                        <TableCell>{moment(row.created_at).format("DD/MM/YYYY hh:mm:ss a")}</TableCell>


                                      
                                    </TableRow>
                                )
                            })}

                        </TableBody>

                    </Table>
                    <Grid
                        mt={3}
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center" >
                        <Grid item >
                            <Pagination
                                count={erps_data.last_page}
                                page={page}
                                onChange={(event, pageNumber) => {handleChangePage(event, pageNumber)}}

                                color="primary" />
                        </Grid>
                    </Grid>
                </TableContainer>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={() => { return; }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                </Box>
                </Paper>
                </DashboardContent>
                

        </React.Fragment>
    );
}
