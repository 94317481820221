import {  Backdrop, Button, CircularProgress, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { getXmls } from '../../apis/Xml';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import XmlsTable from '../../components/tables/XmlTable';
import { getUserCompany, getUserRole } from '../../apis/Auth';
import FalseOrderTable from '../../components/tables/FalseOrderTable';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {deleteAllFalseOrder, deleteAllFalseOrderAPI} from "../../apis/FalseOrder";
import { toast } from 'react-toastify';

const FalseOrders = (props) => {
    const params = useParams();
    
    const [page,setpage] = useState(1);
    const [loading,setLoading] = useState(false);
    const [processed,setProcessed] = useState("");
    const [filter,set_filter] = useState([]);
    const navigate=  useNavigate();

    useEffect(()=>{
        ////console.log(params.id)
    },[])


    const deleteAllFalseOrder=()=>{
        if(window.confirm("Do you want to delete all false orders?")) {
            setLoading(true);
            deleteAllFalseOrderAPI(params.id)
                .then(data => {
                    if (data.success) {

                        toast.success(data.message);
                    } else {
                        toast.error(data.message);
                    }
                    setLoading(false);
                    window.location.reload()

                })
        }
    }



 
    return (
        <DashboardContent title="Your False Orders">
            <Paper elevation={2} >
                <Box p={2}>
                 <FalseOrderTable company={params.id}/>
                </Box>
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Fab color="secondary" aria-label="edit"  onClick={deleteAllFalseOrder}
                 sx={{
                     position: "fixed",
                     bottom: (theme) => theme.spacing(2),
                     right: (theme) => theme.spacing(5)
                 }}>
                <DeleteForeverIcon />
            </Fab>
          
            
        </DashboardContent>
    );
}

export default FalseOrders;