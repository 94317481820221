import {base_url} from './baseurl';



export function getCronByCompany(id){
    
    
    return fetch(base_url+'cronsbycompany/'+id,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}



export function saveCron(data){

    
    return fetch(base_url+'crons',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:JSON.stringify(data)

      
   })
   .then(res=>res.json())
  
}

export function deleteCron(id){
    
    
    return fetch(base_url+'crons/'+id,{
       method:'DELETE',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}



export function getCron(id){
    
    
    return fetch(base_url+'crons/'+id,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}


export function updateCron(data,id){
    
    return fetch(base_url+'crons/'+id,{
     
       method:'PUT',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
       body:JSON.stringify(data)    
   })
   .then(res=>res.json())
  
}

export function runCron(id){
    
    
    return fetch(base_url+'runCron/'+id,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}
