import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Pagination,
    Paper,
    Select,
    TableContainer,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import { getApiUserSerial, getShippableTenPerPage } from '../../apis/ApiUserStaistics';
import DashboardContent from '../../layouts/DashboardLayout';
import { useParams } from 'react-router-dom';
import { getCompanyID, getUserRole } from '../../apis/Auth';


export default function SerialTable(props) {


    const [loading,setLoading] = useState(false);
    const params = useParams();
    const [id,setId]= useState();
    const [shippables,set_shippables] = useState([]);
    const [shippables_product,set_shippables_product] = useState([]);

    const[shippables_data,set_shippables_data] = useState([]);
    const [page,setPage] = useState(1);
    const [search,setSearch] = useState("");
    const [type,setType] = useState("serial");

    useEffect(()=>{
        
        if(getUserRole()==1){
            setId(getCompanyID());

            getApiUserSerial(getCompanyID(),page,search,type)
            .then(data=>{
                set_shippables(data.data);
                set_shippables_product(data.data.data);
                
            })
        }else{
            getApiUserSerial(params.id,page,search,type)
            .then(data=>{
                set_shippables(data.data);
                set_shippables_product(data.data.data);
                
            })
        }
      
    },[])

    const handleChangePage=(event,value)=>{
       
        setLoading(true);
        set_shippables([]);
        set_shippables_product([]);

        setPage(value);
      
        //console.log(filter);

        if(getUserRole()==1){
        getApiUserSerial(getCompanyID(),page,search,type)
        .then(data=>{
            set_shippables(data.data);
            set_shippables_product(data.data.data);
            
          setLoading(false);
            
        })
        }else{
            getApiUserSerial(params.id,page,search,type)
            .then(data=>{
                set_shippables(data.data);
                set_shippables_product(data.data.data);
                
            setLoading(false);
                
            })

        }
        
    }

    const handleSearch=(e)=>{
        setLoading(true);
        set_shippables([]);
        set_shippables_product([]);

        setPage(1);
      
        //console.log(filter);
        if(getUserRole()==1){

            getApiUserSerial(getCompanyID(),page,search,type)
            .then(data=>{
                set_shippables(data.data);
                set_shippables_product(data.data.data);
                
            setLoading(false);
                
            })
        }else{
            getApiUserSerial(params.id,page,search,type)
            .then(data=>{
                set_shippables(data.data);
                set_shippables_product(data.data.data);
                
            setLoading(false);
                
            })
        }
    }
    
   
    return (

        <React.Fragment >

        <DashboardContent title="Serial">
            <Paper elevation={2}  className='card-secondary' mt={2} >

            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }} alignItems="center"   justifyContent="center" mb={2}> 
            <Grid item xs={12} sm={12} md={2}>
                <Box p={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            label="Type"
                            onChange={(e)=>{setType(e.target.value)}}
                        >
                            <MenuItem value={"serial"}>Serial</MenuItem>
                            <MenuItem value={"ean"}>Ean</MenuItem>
                            <MenuItem value={"external_order_id"}>External Order ID</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Box p={2}>
                <FormControl fullWidth>

                <TextField   label="Search" type="text" variant="outlined" InputLabelProps={{ shrink: true }} name="search" onChange={e=>setSearch(e.target.value)}  />

                </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
                <Box p={2}>
                <FormControl fullWidth>

                <Button type="submit" variant="outlined"  onClick={e=>handleSearch(e)}>Search</Button>

                </FormControl>
                </Box>
            </Grid>
            </Grid>

            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }} alignItems="center"  p={2}  justifyContent="center"> 
          
            
           
                <ToastContainer/>
            
            
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                Serials List 
            </Typography>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>

                            <TableCell>EAN</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Serial</TableCell>
                            <TableCell>LOT</TableCell>
                            <TableCell>External Order ID</TableCell>
                            <TableCell>COO</TableCell>


                            


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { shippables_product.map((row) => {
                            return (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                               

                                <TableCell>{row.product.ean ?? 'NA'}</TableCell>
                                <TableCell>{row.product.name ?? 'NA'}</TableCell>
                                <TableCell>{row.serial ?? 'NA'}</TableCell>
                                <TableCell>{row.lot_number ?? 'NA'}</TableCell>
                                <TableCell>{row.orderproduct.order.external_order_id ?? 'NA'}</TableCell>

                                <TableCell>{row.product.coo ?? 'NA'}</TableCell>

                               

                            </TableRow>
                        )})}
                      
                    </TableBody>
                    
                </Table>
                <Grid
                mt={3}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center" > 
                <Grid item >
                <Pagination
                  count= {Math.round(shippables.total/shippables.per_page)}
                  page={page}
                  onChange={(event, pageNumber) => handleChangePage(event, pageNumber)}

                 color="primary" />
                </Grid>
                </Grid>
            </TableContainer>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            </Grid>
            </Paper>
            </DashboardContent>

        </React.Fragment>
    );
}