import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import FolderIcon from '@mui/icons-material/Folder';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Pagination, Select, TableContainer, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteCompany } from '../../apis/Company';
import { ToastContainer, toast } from 'react-toastify';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { deleteCron, getCron, getCronByCompany, runCron, saveCron, updateCron } from '../../apis/Crons';
import { getCronTypes } from '../../apis/CronTypes';
import { useEffect } from 'react';


export default function CronTable(props) {

    const [loading,setLoading] = useState(false);
    const [crontype, setCronType] = useState("");
    const [crons, setCrons] = useState([]);
    const [cronsProcess, setCronsProcess] = useState(0);

    const lock = props.lock;

    const [minute, setMinute] = useState("");
    const [hour, setHour] = useState("");
    const [dayOfMonth, setDayOfMonth] = useState("");
    const [month, setMonth] = useState("");
    const [dayOfWeek, setDayOfWeek] = useState("");

    const [errors, setErrors] = useState([]);
    const [open,setOpen]= useState(false);
    const [update,setUpdate]= useState(false);
    const [update_id,setUpdateID]= useState(0);


    const [maxWidth, setMaxWidth] = React.useState('sm');

    const [fullWidth, setFullWidth] = React.useState(true);

    const [crontypes, setCrontypes] = useState([]);

    const handleChange = (event) => {
        event.preventDefault();

        switch (event.target.name) {
            case "crontype":
                setCronType(event.target.value);
                break;
            case "minute":
                setMinute(event.target.value);
                break;
            case "hour":
                setHour(event.target.value);
                break;
            case "day_of_month":
                setDayOfMonth(event.target.value);
                break;
            case "month":
                setMonth(event.target.value);
                break;
            case "day_of_week":
                setDayOfWeek(event.target.value);
                break;
            default: break;
        }
    }
    const getCrons =()=>{
        getCronByCompany(props.id)
        .then(data=>{
            setCrons(data.data);
        })
    }
 
    useEffect(()=>{
        getCrons();
        

        getCronTypes()
        .then(data => {
            setCrontypes(data.data);
        })


    },[])
    const handleClickOpen = () => {
        setUpdate(false);
        setMinute("-");
        setHour("-");
        setDayOfMonth("-");
        setMonth("-");
        setDayOfWeek("-");
        setCronType("");
        setErrors([]);
        setOpen(true);
      };

    
  
    const handleClose = () => {
        setOpen(false);
        getCrons();
      };
    const handleSaveCron=()=>{
        setLoading(true);
        let payload = {
            minute:minute,
            hour:hour,
            day_of_month:dayOfMonth,
            month:month,
            day_of_week:dayOfWeek,
            company_id:props.id,
            cron_types_id: crontype

        }
        saveCron(payload)
        .then(data=>{
            setLoading(false);
            if(data.success){
                toast.success(data.message)
                handleClose();
            }else{
                if(data.data){
                setErrors(data.data);
                }else{
                    setErrors([]);
                    toast.error(data.message)
                }

        
            }
        })

    }

    
    return (

        <React.Fragment >
            
<Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
         <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        <DialogTitle>CRON</DialogTitle>
        <DialogContent>
          <DialogContentText>
      
            <Box mb={2} />
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

        <Grid item sm={12} md={12}>
                                                            <Box p={2}>
                                                                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>
                                                                    <Grid item sm={3} md={3}>
                                                                        <FormControl fullWidth>

                                                                            <TextField required label="Minutes" type="text" variant="outlined" InputLabelProps={{ shrink: true }} name="minute"  value={minute} onChange={handleChange}
                                                                                       error={errors.minute != undefined ? true : false}
                                                                                       helperText={errors.minute !== undefined ? errors.minute : ''}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item sm={3} md={3}>
                                                                        <FormControl fullWidth>

                                                                            <TextField required label="Hours"  value={hour} type="text" variant="outlined" InputLabelProps={{ shrink: true }} name="hour"  onChange={handleChange}
                                                                                       error={errors.hour != undefined ? true : false}
                                                                                       helperText={errors.hour !== undefined ? errors.hour : ''}
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                   
                                                                    <Grid item sm={6} md={6}>
                                                                        <FormControl fullWidth>

                                                                <InputLabel id="demo-simple-select-label">Days</InputLabel>
                                                                <Select
                                                                 
                                                                    value={dayOfWeek}
                                                                    name="day_of_week"
                                                                    onChange={handleChange}
                                                                    label="Days"
                                                                    error={errors.dayOfWeek!=undefined?true:false}
                                                                >
                                                                   

                                                                            <MenuItem value="*">All Days</MenuItem>
                                                                            <MenuItem value="1-6">Monday To Saturday</MenuItem>
                                                                            <MenuItem value="1-5">Monday To Friday</MenuItem>
                                                                            <MenuItem value="1">Monday</MenuItem>
                                                                            <MenuItem value="2">Tuesday</MenuItem>
                                                                            <MenuItem value="3">Wednesday</MenuItem>
                                                                            <MenuItem value="4">Thursday</MenuItem>
                                                                            <MenuItem value="5">Friday</MenuItem>
                                                                            <MenuItem value="6">Saturday</MenuItem>
                                                                            <MenuItem value="0">Sunday</MenuItem>
                                                                            <MenuItem value="-">Never</MenuItem>










                                                                      
                                                                </Select>
                                                                </FormControl>

                                                                    </Grid>
                                                                </Grid>

                                                            </Box>

                                                            <Box p={2}>
                                                            <FormControl fullWidth>

                                                                <InputLabel id="demo-simple-select-label">Cron Type</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={crontype}
                                                                    name="crontype"
                                                                    onChange={handleChange}
                                                                    label="Cron Type"
                                                                    error={errors.cron_types_id!=undefined?true:false}
                                                                >
                                                                    {
                                                                        crontypes && crontypes.map((item) => {

                                                                            return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                                                                        })
                                                                    }
                                                                </Select>
                                                                <FormHelperText  error={true}>{errors.cron_types_id!==undefined?errors.cron_types_id:''}</FormHelperText>
                                                                </FormControl>

                                                            </Box>
                                                            
                                                        </Grid>
                                                        </Grid>
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
          
          </Box>
        </DialogContent>
        <DialogActions>
        {
            update==true? (
                <Button onClick={()=>{
                    setLoading(true);
                    let payload = {
                        minute:minute,
                        hour:hour,
                        day_of_month:dayOfMonth,
                        month:month,
                        day_of_week:dayOfWeek,
                        company_id:props.id,
                        cron_types_id: crontype
                    }
                    updateCron(payload,update_id)
                    .then(data=>{
                        setLoading(false);
                        if(data.success){
                            toast.success(data.message);
                            setOpen(false);
                        }else{
                            toast.error(data.message);
                        }
                        getCrons();
                    })
                }}>Update</Button>
            ):(
                <Button onClick={handleSaveCron}>Save</Button>
            )
        }
          <Button onClick={handleClose}>Close</Button>

        </DialogActions>
      </Dialog>
                <ToastContainer/>
            
            
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                Crons  <Button variant="outlined"   onClick={handleClickOpen} >ADD</Button>
            </Typography>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>

                            <TableCell>Cron Types</TableCell>
                            <TableCell>Minute</TableCell>
                            <TableCell>Hour</TableCell>
                            
                            <TableCell>Day of Weeks</TableCell>
                            <TableCell></TableCell>



                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {crons.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>

                                <TableCell>{row.cron_types.name}</TableCell>
                                <TableCell>{row.minute}</TableCell>
                                <TableCell>{row.hour}</TableCell>
                                
                                <TableCell>{row.day_of_week}</TableCell>

                                <TableCell>
                                
                                    <IconButton  onClick={()=>{
                                        setCronsProcess(row.id);

                                        runCron(row.id)
                                        .then(data=>{
                                        setCronsProcess(0);

                                            if(data.success){
                                                toast.success(data.message);
                                            }else{
                                                toast.error("Sorry! Something went wrong.")
                                            }
                                        })
                                        .catch(error=>{
                                            setCronsProcess(0);

                                            toast.error("Sorry! Something went wrong.")

                                        })
                                    }}
                                    disabled={cronsProcess==row.id}
                                    >
                                      {cronsProcess==row.id?(<HourglassBottomIcon color="primary"/>):(<PlayArrowIcon color="primary"/>)}  
                                    </IconButton>
                                    
                                    
                                    <IconButton disabled={lock} onClick={()=>{
                                        getCron(row.id)
                                        .then(data=>{
                                            if(data.success){
                                                setUpdateID(data.data.id);
                                                setMinute(data.data.minute);
                                                setHour(data.data.hour);
                                                setDayOfMonth(data.data.day_of_month);
                                                setMonth(data.data.month);
                                                setDayOfWeek(data.data.day_of_week);



                                                setCronType(data.data.cron_types_id);
                                                setUpdate(true);
                                                setOpen(true);
                                                


                                            }else{
                                                toast.error(data.message);
                                            }
                                        })
                                    }}>
                                        <EditIcon color="primary"/>
                                    </IconButton>
                                   <IconButton disabled={lock} onClick={()=>{
                                                setLoading(true);
                                                deleteCron(row.id)
                                                .then(data=>{
                                                    setLoading(false);
                                                    if(data.success){
                                                        toast.success(data.message);
                                                    }else{
                                                        toast.error(data.message);

                                                    }
                                                    getCrons();

                                                })

                                   }}>
                                        <DeleteIcon color="warning" />
                                    </IconButton>
                             

                                </TableCell>



                            </TableRow>
                        ))}
                      
                    </TableBody>
                    
                </Table>
               
            </TableContainer>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}