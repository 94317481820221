import { getCompanyID, getUserRole } from './Auth';
import {base_url} from './baseurl';



export function getFalseOrder(company_id,page=1,search=""){
    
    if(getUserRole() == 1){

        company_id = getCompanyID();
    }
    return fetch(base_url+'falseorder_search/'+company_id+'?page='+page+'&search='+search,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export function deleteFalseOrder(id){
    
    
    return fetch(base_url+'falseorder/'+id,{
       method:'DELETE',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export function getFalseOrderById(company_id,id){
    
    if(getUserRole() == 1){

        company_id = getCompanyID();
    }
    return fetch(base_url+'get_false_order/'+company_id+'/'+id,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}


export function processFalseOrder(data,company_id,id){
    
    
    if(getUserRole() == 1){

        company_id = getCompanyID();
    }
    return fetch(base_url+'process_false_order/'+company_id+'/'+id,{
       method:'POST',
       headers:{
        //    "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
       body:data
   })
   .then(res=>res.json())
  
}


export function deleteAllFalseOrderAPI(id){

    if(getUserRole() == 1){

        id = getCompanyID();
    }
    return fetch(base_url+'falseorder/deleteall/'+id,{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token"),

            //    "X-Requested-With": "XMLHttpRequest",
        },
    })
        .then(res=>res.json())

}