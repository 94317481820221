import {  Backdrop, Button, CircularProgress, Fab, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { getOrders } from '../../apis/Orders';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import OrdersTable from '../../components/tables/OrdersTable';
import moment from 'moment';
import { getAllCompanies, getCompanies } from '../../apis/Company';
import { getUserCompany, getUserRole } from '../../apis/Auth';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'; 
const OrdersSingleCompany = (props) => {
    
    const params = useParams()

    const [orders,set_orders] = useState([]);
    const [orders_data,set_orders_data] = useState([]);
    const [from_date,set_from_date] = useState("");
    const [to_date,set_to_date] = useState("");
    const [companies,set_companies] = useState([]);
    const [search,set_search] = useState("");
    const [exported,set_exported] = useState("");

    const [company,set_company] = useState([]);
    const [filter,set_filter]=useState([]);


    

    const [loading,setLoading] = useState(true);
    const [page,setpage] = useState(1);


    const handleChangePage=(event,value)=>{
       
        setLoading(true);
        set_orders([]);
        set_orders_data([]);
        set_company(params.id);

        setpage(value);

            filter.company_id = params.id;
           
            set_filter(filter);
        
        //console.log(filter);

        getOrders(value,filter)
        .then(data=>{
            //console.log(data.data);
            set_orders(data.data.data);
            set_orders_data(data.data);
            setLoading(false);
        })
        
    }
    const navigate=  useNavigate();
    useEffect(()=>{
        getAllCompanies()
        .then(data=>{
            set_companies(data.data)
        })
        let filter = {};
      
            filter = {
                company_id:params.id
            }
            
        
        getOrders(1,filter)
        .then((data)=>{
            if(data.success){
                set_orders(data.data.data);
                set_orders_data(data.data);
            }else{
                navigate('/');
            }
            setLoading(false);


        })
    },[]);

    const handleChange=(event)=>{
        event.preventDefault();
   
        switch(event.target.name){
            case "from_date":
                set_from_date(event.target.value);
                break;
            case "to_date":
                set_to_date(event.target.value);
                break;
            case "company":
                set_company(event.target.value);
                break;
            case "search":
                set_search(event.target.value);
                break;
            case "exported":
                set_exported(event.target.value);
                break;
            default: break;
        }
    }

    const handleSubmit=()=>{
        setLoading(true);
        set_orders([]);
        set_orders_data([]);
            let data = {
            from_date:from_date,
            to_date:to_date,
            company_id:params.id,
                search:search,
                exported:exported
        }
        set_filter(data);
        getOrders(1,data)
        .then(data=>{
            set_orders(data.data.data);
            set_orders_data(data.data);
            setLoading(false);

        })
    }

 
    return (
        <DashboardContent title="Orders">
            <Paper elevation={2} >
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }} alignItems="center"   justifyContent="center"> 
            <Grid item xs={12} sm={12} md={2}>
                <Box p={2}>
                <FormControl fullWidth>

                <TextField   label="From Date" type="date" variant="outlined" InputLabelProps={{ shrink: true }} name="from_date" onChange={handleChange} value={from_date}  />

                </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
                <Box p={2}>
                <FormControl fullWidth>

                <TextField   label="To Date" type="date" variant="outlined" InputLabelProps={{ shrink: true }} name="to_date" onChange={handleChange} value={to_date}  />

                </FormControl>
                </Box>
            </Grid>
                <Grid item xs={12} sm={12} md={2}>
                    <Box p={2}>
                        <FormControl fullWidth>

                            <TextField   label="Search" type="text" variant="outlined" InputLabelProps={{ shrink: true }} name="search" onChange={handleChange} value={search}  />

                        </FormControl>
                    </Box>
                </Grid>


                <Box p={2}></Box>
                <Grid item xs={12} sm={12} md={2}>
                <FormControl fullWidth>

                <InputLabel id="demo-simple-select-label">Exported</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={exported}
                                        name="exported"
                                        onChange={handleChange}
                                        label="Exported"
                                    >
                                        <MenuItem value={""}><CheckCircleIcon color='success'/><CancelIcon color='error'/></MenuItem>
                                        <MenuItem value={1}><CheckCircleIcon color='success'/></MenuItem>
                                        <MenuItem value={0}><CancelIcon color='error'/></MenuItem>

                                          
                                      </Select>
                </FormControl>
                </Grid>
                
           
            <Grid item xs={12} sm={12} md={3}>
                <Box p={2}>
                <FormControl fullWidth>

                <Button type="submit" variant="outlined" onClick={handleSubmit} >SEARCH</Button>
                </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Box p={2}>
                <FormControl fullWidth>
                
                <Button  onClick={()=>{navigate(`/orders/serial/${params.id}`)}} variant="outlined"  >Go To Serial</Button>

                </FormControl>
                </Box>
            </Grid>
            </Grid>

                <Box p={2} className="orders-tbl">
                 <OrdersTable orders={orders} orders_data={orders_data} filter_data={filter} handleChangePage={handleChangePage} />
                </Box>
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Fab className="add-company" color="secondary" aria-label="edit"  onClick={()=>{navigate(`/orderssingle/add/${params.id}`)}}
            sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(5)
            }}>
                <AddIcon />
            </Fab>
            
        </DashboardContent>
    );
}

export default OrdersSingleCompany;
