import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import {  getUserRole, isAdmin } from '../apis/Auth';
import { useState } from 'react';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';

import StorageIcon from '@mui/icons-material/Storage';

export default function  MainListItems(){
  
  const [role,set_role] = useState(3);
  useEffect(()=>{
  
      set_role(getUserRole);
    
  },[])

 return(
  <>

    {
       isAdmin()==1 && role==0 &&(

        <>
        <ListItemButton component={Link} to="/" className='nav-btn'>
            <ListItemIcon>
            <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
        </ListItemButton>


        <ListItemButton component={Link} to="/warehouse-users" className='nav-btn'>
          <ListItemIcon>
          <ApartmentIcon />
          </ListItemIcon>
          <ListItemText primary="Warehouse Accounts" />
        </ListItemButton>



      </>

      )
    }
    {
      

     
      isAdmin()==0 && role==0 &&(
        <>
        
        <ListItemButton component={Link} to="/" className='nav-btn'>
        <ListItemIcon>
        <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton component={Link} to="/company" className='nav-btn'>
        <ListItemIcon>
        <ApartmentIcon />
        </ListItemIcon>
        <ListItemText primary="Company" />
      </ListItemButton>
  
      <ListItemButton component={Link} to="/logs" className='nav-btn'>
        <ListItemIcon>
        <SyncAltIcon />
        </ListItemIcon>
        <ListItemText primary="Logs" />
      </ListItemButton>
  
      <ListItemButton component={Link} to="/orders" className='nav-btn'>
        <ListItemIcon>
          <InventoryIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItemButton>

        </>
      )
    }
      {
      role==1&&(
        <>
        <ListItemButton component={Link} to="/" className='nav-btn'>
        <ListItemIcon>
        <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
     
  
      <ListItemButton component={Link} to="/company-client" className='nav-btn'>
        <ListItemIcon>
        <ApartmentIcon />
        </ListItemIcon>
        <ListItemText primary="Company" />
      </ListItemButton>
  
  
      <ListItemButton component={Link} to="/logs" className='nav-btn'>
        <ListItemIcon>
        <SyncAltIcon />
        </ListItemIcon>
        <ListItemText primary="Logs" />
      </ListItemButton>
{
      localStorage.getItem('source') == "XENTRAL" &&(<ListItemButton component={Link} to={"/company/xml/cp/0"}>
        <ListItemIcon>
          <FileCopyIcon />
        </ListItemIcon>
        <ListItemText primary="Files" />
      </ListItemButton>)
}
      <ListItemButton component={Link} to="/orders" className='nav-btn'>
        <ListItemIcon>
          <InventoryIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItemButton>

      <ListItemButton component={Link} to="/products/0" className='nav-btn'>
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItemButton>

      <ListItemButton component={Link} to="/company/false-orders" className='nav-btn'>
        <ListItemIcon>
        <SmsFailedIcon />
        </ListItemIcon>
        <ListItemText primary="False Order" />
      </ListItemButton>
        </>
      )

      
    }
        

   
   
 </>);
};

