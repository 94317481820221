import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import { Backdrop, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Pagination, Select, TableContainer, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { deleteOrder, getAllOrders, getOrderFromPakaneo, getOrders, setOrdersExportFlag, syncOrdersWithPakaneo } from '../../apis/Orders';
import exportFromJSON from "export-from-json";
// import { connectFtp } from '../../ftp/FtpService';
import { useEffect } from 'react';
import { getFtps, getFtpsAll } from '../../apis/Ftp';
import { Box } from '@mui/system';
import { uploadXmlToFTP } from '../../apis/Xml';
import {Buffer} from 'buffer';
import { getUserRole } from '../../apis/Auth';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'; 
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { getCompanyById } from '../../apis/Company';
import { getCompanyID } from '../../apis/Auth';


export default function OrdersTable(props) {

    const [loading, setLoading] = useState(false);
    const [ftps, setFtps] = useState([]);
    const [ftp, setFtp] = useState("");
    const [password, setPassword] = useState("");
    const [open,set_open] = useState(false);
    const [fetch_order,set_fetch_order] = useState(0);
    const [url,set_url] = useState("");



    const orders = props.orders;
    const orders_data = props.orders_data;

    const page = props.page;

    const handleFetchFromPakaneo=(company_id,pakaneo_id,url)=>{
        set_url(url);
        // if(getUserRole()==1){
        //     getCompanyById(getCompanyID())
        //     .then(data=>{
        //         console.log(data);
        //         set_url(data.data.url);
        //     })
        // }
        getOrderFromPakaneo(company_id,pakaneo_id)
        .then(data=>{
            if(data.success){
            set_fetch_order(data.data);
            set_open(true);
            }
            
        }).catch(err=>{

        })
    }

    const handleChange = (event) => {
        event.preventDefault();

        switch (event.target.name) {
            case "ftp":
                setPassword("");

                ftps.map(item=>{
                    if(item.id == event.target.value){
                        let string = item.password;
                        // console.log(item.password);
                        if(string){
                            let bufferObj = Buffer.from(string, "base64");

                            // Encode the Buffer as a utf8 string
                            let decodedString = bufferObj.toString("utf8");
                            setPassword(decodedString);
                        }
                    }
                })
                setFtp(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;
            default: break;
        }
    }
    const buetify = (data) => {
        var b = JSON.stringify(data);
        return b.replace(/\\/g, '');
    }


    useEffect(() => {
        getFtpsAll()
            .then(data => {
                setFtps(data.data);
            })
        // console.log(connectFtp())
    }, [])


    const syncOrders=()=>{

        let orders_ids = [];
        orders.map((item)=>{
            orders_ids.push(item.id);
        })
        let data={
            order_ids:orders_ids
        }
        syncOrdersWithPakaneo(data)
        .then(data=>{
            toast.success(data.message);
            
        })



    }

    const exportJson = () => {
        setLoading(true);

        syncOrders();

    
        getAllOrders(props.filter_data)
            .then(data => {
                let json_exportable = [];
                data.data.map((item) => {
                    let json = "";
                    json = JSON.stringify(JSON.parse(item.json));
                    json = JSON.parse(JSON.parse((item.json))).data;
                if(json.order.trackingcode!="" && item.exported!=1){
                  
                    // json.order_info = json
                    var json_obj = new Object();
                    json_obj.order_single = json;
                    json_exportable.push(json_obj);
                }

                });
                // console.log(json_exportable);
                json_exportable = JSON.stringify(json_exportable);

                setLoading(false);

                download(json_exportable);
            })
    }

    const exportFTP = () => {
        let order_ids=[];

        setLoading(true);
        syncOrders();
        getAllOrders(props.filter_data)
            .then(data => {
                let json_exportable = [];
                data.data.map((item) => {
                    
                    let json = "";
                    json = JSON.stringify(JSON.parse(item.json));
                    json = JSON.parse(JSON.parse((item.json))).data;
                    if(json.order.trackingcode!="" && item.exported!=1){
                    order_ids.push(item.id);
                        // json.order_info = json
                    var json_obj = new Object();
                    json_obj.order_single = json;
                    json_exportable.push(json_obj);
                    }


                });
                let payload_data ={
                    'order_ids':order_ids
                }
                setOrdersExportFlag(payload_data)
                // console.log(json_exportable);
                json_exportable = JSON.stringify(json_exportable);


                exportToFtp(json_exportable);
                setLoading(false);  
            })
    }

    function download(json) {
        // console.log(json)
        const fileName = "export"
        const data = JSON.parse(json)
        const fields = []
        const exportType = 'json';

        exportFromJSON({ data, fileName, fields, exportType })
    }

    function exportToFtp(json) {
        setLoading(true)
        // console.log(json)
        const fileName = "export"
        const data = JSON.parse(json)
        const fields = []
        const exportType = 'json';

        const output = exportFromJSON({ data, fileName, fields, exportType, processor: content => content })
        // console.log(output);
        // return;
        let data_api={
            ftp_id: ftp,
            xml_content:output,
            password:password,
            is_for_orders:1

        }
        uploadXmlToFTP(JSON.stringify(data_api))
        .then(data=>{
            if(data.success){
                toast.success(data.message);
            }else{
                toast.error(data.message);
            }
        })

        setLoading(false);
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
      
      
    return (

        <React.Fragment >


            <Modal
            style={{ overflow: "auto" }}
            open={open}
            onClose={()=>{set_open(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
       
        <Box sx={style}>
        {fetch_order && (
            <>
        <Grid container rowSpacing={4} mt={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
            <Grid item xs={12} sm={6} md={6} >

            <Typography id="modal-modal-title" variant="h6" component="h2">
                ID: {fetch_order?.order?.id }
                <br/>
                External Order ID: {fetch_order?.order?.external_order_id }
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Carrier Name: {fetch_order?.order?.carrier?.name }
                <br/>
                Status: {fetch_order?.order?.status?.status }
                <br/>
                Tracking Code: {fetch_order?.order?.trackingcode }  
                <br/>
                
                Seller Platform: {fetch_order?.order?.seller_platform ? fetch_order?.order?.seller_platform : "NA"}
                <br/>
                Tags: {fetch_order?.order?.tag ? fetch_order?.order?.tag.map(item=>{  return item.tag+', '}) : "NA"}
                <br/>
                <br/>

                Address:
                <br/>
                Company: {fetch_order?.order?.company }   
                <br/>

                Name: {fetch_order?.order?.first_name }  {fetch_order?.order?.last_name }  
                <br/>
                Street: {fetch_order?.order?.street_no }  {fetch_order?.order?.street }  
                <br/>
                City: {fetch_order?.order?.city }  -  {fetch_order?.order?.zip }
                <br/>
                State: {fetch_order?.order?.state }  
                <br/>
                Country: {fetch_order?.order?.country }  
                

                <br/>
                <br/>
                Email: {fetch_order?.order?.email }  
                <br/>
                Phone: {fetch_order?.order?.phone }  
                <br/>
            </Typography>

                </Grid>
                <Grid item xs={12} sm={6} md={6} >
                <Grid container rowSpacing={4} mt={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
                {fetch_order?.order?.invoice_note && (
                <Grid item xs={12} sm={4} md={4} >
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Invoice Note:<br/>
              
                 <iframe width="100%" height="200" src={url+fetch_order?.order?.invoice_note}></iframe>
                
                </Typography>

                </Grid>
                )
            }
            {fetch_order?.order?.invoice_note && (
                <Grid item xs={12} sm={4} md={4} >
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                    Delivery Note:<br/>
                    
                    <iframe width="100%" height="200" src={url+fetch_order?.order?.delivery_note}></iframe>
                   

                    </Typography>
                </Grid>
                 )
                }
          
                </Grid>
                {fetch_order?.order?.shippinglabel && (
                <Grid container rowSpacing={4} mt={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
            
                <Grid item xs={12} sm={4} md={4} >
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                    Shipping Label:<br/>
                    
                    <iframe width="100%" height="200" src={url+fetch_order?.order?.shippinglabel}></iframe>
                   

                    </Typography>
                </Grid>
                </Grid>
                )
            }
             
            </Grid>

            </Grid>

            <TableContainer >
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>EAN</TableCell>
                        <TableCell>Name</TableCell>

                        <TableCell>QTY</TableCell>


                      





                    </TableRow>
                </TableHead>
                <TableBody>
                    {fetch_order.order?.orderproduct.map(item=>{
                        return(
                            <TableRow>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.product?.ean}</TableCell>
                                <TableCell>{item.product?.name}</TableCell>
                                <TableCell>{item.quantity}</TableCell>



                            </TableRow>
                        )
                    })
                      
                    }
                 
                </TableBody>
                </Table>
                </TableContainer>
                </>
            )}

            </Box>
        
         
                
            
            
            
            </Modal>
            <ToastContainer />
            {
            false && (
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }} alignItems="center" >
                <Grid item xs={12} sm={12} md={3}>
                    <FormControl fullWidth>

                        <InputLabel id="demo-simple-select-label">FTP</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={ftp}
                            name="ftp"
                            onChange={handleChange}
                            label="FTP"
                        >
                            {
                                ftps && ftps.map((item) => {

                                    return (<MenuItem value={item.id}>{item.host} - {item.username} </MenuItem>)
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>

                    <TextField label="Password" type="password" variant="outlined" InputLabelProps={{ shrink: true }} name="password" onChange={handleChange} value={password} />

                </Grid>
                <Grid item xs={12} sm={6} md={2}>

                    <Button variant="outlined" onClick={exportFTP} >EXPORT TO FTP</Button>
                </Grid>
                
                <Grid item xs={12} sm={6} md={2}>

                <Button variant="outlined" onClick={exportJson} >Download XML</Button>

                </Grid>
                </Grid>)
            }
                <Box mt={3}/>
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                ORDERS &nbsp;
               



            </Typography>

       

                <TableContainer >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Order ID</TableCell>
                                <TableCell>External Order ID</TableCell>

                                <TableCell>Carrier</TableCell>
                                <TableCell>Tracking Code</TableCell>
                                <TableCell>Exported</TableCell>


                                <TableCell>Company</TableCell>
                                <TableCell>Platform</TableCell>


                                <TableCell>Date Created</TableCell>
                                {getUserRole()==0 && (

                                <TableCell></TableCell>
                                )}





                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((row) => {
                                let json = "";
                                json = JSON.stringify(JSON.parse(row.json));
                                json = JSON.parse(JSON.parse((json))).data;

                                // console.log(json);

                                return (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.order_id}</TableCell>
                                        <TableCell>{row.external_order_id}</TableCell>
                                        <TableCell>{json.order.carrier.carrier}</TableCell>
                                        <TableCell>{json.order.trackingcode}
                                        
                                        {
                                        row.trackingcodes && row.trackingcodes.map(item=>{
                                            return <p>{item.tracking_code}</p>
                                        })}
                                        </TableCell>
                                        <TableCell>{row.exported ? <CheckCircleIcon color='success'/> : <CancelIcon color='error'/>}</TableCell>

                                        <TableCell>{row.company.name}</TableCell>
                                         <TableCell>{json.order.seller_platform}</TableCell>

                                        {/* <TableCell>{buetify(row.response)}</TableCell> */}
                                        <TableCell>{moment(row.created_at).format("DD/MM/YYYY hh:mm:ss a")}</TableCell>
                                        <TableCell><IconButton onClick={(e)=>handleFetchFromPakaneo(row.company.id,row.order_id,row.company.url)}><RemoveRedEyeIcon/></IconButton></TableCell>
                                        {getUserRole() == 0 && (<TableCell>{json.order.trackingcode=="" && (   <IconButton onClick={()=>{
                                        let confirm_delete  =  window.confirm("Do you want to delete this record");
                                        if(confirm_delete){
                                            setLoading(true);
                                            deleteOrder(row.id)
                                            .then((data)=>{
                                                setLoading(false);
                                                if(data.success){
                                                    window.location.reload();
                                                }else{
                                                    toast.error(data.message);
                                                }
                                                
                                            })
                                        }
                                    }}>
                                        <DeleteIcon color="warning" />

                                    </IconButton>)}</TableCell>)}



                                    </TableRow>
                                )
                            })}

                        </TableBody>

                    </Table>
                    <Grid
                        mt={3}
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center" >
                        <Grid item >
                            <Pagination
                                count={orders_data.last_page}
                                page={page}
                                onChange={(event, pageNumber) => props.handleChangePage(event, pageNumber)}

                                color="primary" />
                        </Grid>
                    </Grid>
                </TableContainer>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={() => { return; }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

        </React.Fragment>
    );
}
