import {base_url} from './baseurl';




export function wh1_checkConnection(id){
    
    
    return fetch(base_url+'wh1+/api/login/'+id,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}