import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import FolderIcon from '@mui/icons-material/Folder';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import DeleteIcon from '@mui/icons-material/Delete';
import { Backdrop, Card, CircularProgress, Grid, IconButton, Pagination, TableContainer, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteCompany } from '../../apis/Company';
import { ToastContainer, toast } from 'react-toastify';
import ErrorIcon from '@mui/icons-material/Error';
import StorageIcon from '@mui/icons-material/Storage';
import billbee from '../../assets/images/sources/billbee.png';
import odoo from '../../assets/images/sources/odoo.png';
import json from '../../assets/images/sources/json.png';
import wh1 from '../../assets/images/sources/wh1+.png';
import xentral from '../../assets/images/sources/xentral.png';
import hamburger from '../../assets/images/sources/hamburger.png';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RestoreIcon from '@mui/icons-material/Restore';
import NetworkLockedIcon from '@mui/icons-material/NetworkLocked';
import linnworks from '../../assets/images/sources/linnworks.png';
import shopify from '../../assets/images/sources/shopify.jpeg';
import { getCompanyAvatar } from '../../apis/Auth';


export default function CompaniesTable(props) {

    const [loading,setLoading] = useState(false);

    
    const companies = props.companies;
    const companiesData = props.companiesData;
    const page = props.page;

    const getSubdomain = url => {
        let domain = url;
        if (url.includes("://")) {
            domain = url.split('://')[1];
        }
        const subdomain = domain.split('.')[0];
        return subdomain;
    };
    return (

        <React.Fragment >
                <ToastContainer/>
            
            
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                Companies 
            </Typography>
            <TableContainer >
              
             <Grid container spacing={2} mb={3}>

            {companies.map((row) => {
                              let logo = '';
                              if(row.source.code == "XENTRAL"){
                                  logo = xentral;
                              }
                              else if(row.source.code =="ODOO"){
                                  logo = odoo;
                              }
                              else if(row.source.code =="BILLBEE"){
                                  logo = billbee;
                              }
                              else if(row.source.code =="JSON"){
                                  logo = json;
                              }
                              else if(row.source.code =="HAMBURGER"){
                                  logo = hamburger;
                              }
                              else if(row.source.code =="WH1"){
                                  logo = wh1;
                              }
                              else if(row.source.code =="LINWORKS"){
                                logo = linnworks;
                            }
                            else if(row.source.code =="SHOPIFY"){
                                logo = shopify;
                            }
                            let errorErpClass = "";
                            if(row.erplogs.length){
                                errorErpClass= "hasErrors";
                            }

                            let errorFalseClass = "";
                            if(row.falseorders.length){
                                errorFalseClass= "hasErrors";
                            }
                            return (
                            <Grid item xs={3} md={3} >

                            <Card key={row.id}  className='card-padding'>
                                <div class="flex-row margin-small">
                                <img src={logo} className='logo-company'/>
                                {
                                    row.avatar && (

                                        <div className='company-avatar-logo margin-left' style={{backgroundImage: `url(${getCompanyAvatar(row.avatar)})`}}>
                                        </div>
                                    
                                    )
                                }
                                </div>

                                <Typography variant="h5">{row.name.length>15?row.name.substr(0,15)+'...':row.name}</Typography>
                                <Typography variant="p">{row.url_name}</Typography>
                              

                                <div className='row-flex with-errors'>
                                <div className={`btn-with-errors ${errorFalseClass}`}>
                                <IconButton component={Link} to={"/company/false-orders/"+row.id}>
                                        {row.falseorders.length ? (<SmsFailedIcon color="warning"/>):(<SmsFailedIcon style={{ color: 'white' }} />)}
                                        
                                    </IconButton>

                                    {
                                     row.falseorders.length>0 ? (<div class="count">{row.falseorders.length}</div>):''

                                    }
                                </div>

                               
                                <div className={`btn-with-errors  ${errorErpClass}`}>
                                <IconButton component={Link} to={"/erplogs/"+row
                                    .id}>
                                        {
                                            row.erplogs.length>0 ? (
                                                <>
                                                <ErrorIcon color="warning"/> 
                                                </>
                                            ):
                                            (
                                                <ErrorIcon  style={{ color: 'white' }} /> 

                                            )
                                        }
                                        
                                    </IconButton>
                                    {
                                     row.erplogs.length>0 ? (<div class="count">{row.erplogs.length}</div>):''

                                    }
                                    </div>
                                </div>

                            </Card>

                            <div  className='row-flex'>

                                    <IconButton  component={Link} to={"/company/"+row.id}>
                                        <SearchIcon style={{ color: 'white' }}/>
                                    </IconButton>
                                    
                                
                                    <IconButton  component={Link} to={"/company/api_access/"+row.id}>
                                        <NetworkLockedIcon style={{ color: 'white' }}/>
                                    </IconButton>


                                    <IconButton component={Link} to={"/products/"+row
                                    .id}>
                                        <StorageIcon style={{ color: 'white' }}/>
                                        
                                    </IconButton>

                                    

                                    {row.source_id==1 &&(
                                        
                                        <IconButton  component={Link} to={"/company/xml/cp/"+row.id}>
                                        <FolderIcon style={{ color: 'white' }}/>
                                    </IconButton>
                                    
                                    )}
                                     {row.source_id==4 &&(

                                        <IconButton  component={Link} to={"/company/json/cp/"+row.id}>
                                        <FolderIcon style={{ color: 'white' }}/>
                                    </IconButton>

                                    
                                    )}

                                


                                    
                                    

                                    <IconButton component={Link} to={"/company/cron/"+row.id+"/"+row.name}>
                                        <ScheduleIcon style={{ color: 'white' }}/>
                                        
                                    </IconButton>



                                   
                             

                                </div>
                        </Grid>

                        )})}
                        </Grid>
                        
                <Grid
                
                mt={3}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center" > 
                <Grid item >
                <Pagination
                  count= {companiesData.last_page}
                  page={page}
                  onChange={(event, pageNumber) => props.handleChangePage(event, pageNumber)}

                 color="primary" />
                </Grid>
                </Grid>
            </TableContainer>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}