export default  function themeDataCustom(){
    return {
        palette: {
            primary: {
              light: '#d74141',
              main: '#37505d',
              dark: '#929942',
              contrastText: '#fff',
            },
            secondary: {
              light: '#be8f63',
              main: '#37505d',
              dark: '#d74141',
              contrastText: '#fff',
            },
          },
    }
}