import {base_url} from '../baseurl';



export  function getOrderCharts(data){
    
    return  fetch(base_url+'charts/orderchart',{
       method:'POST',
       body:JSON.stringify(data),
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export function getOrderShippedCharts(data){
    

    return  fetch(base_url+'charts/shippedordercharts',{
        method:'POST',
        body:JSON.stringify(data),
        headers:{
            "Content-Type":"application/json",
            "Authorization":localStorage.getItem("token"),
            
         //    "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(res=>res.json())
   
}