import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

import Logo from '../assets/images/logo-dark.png'
import { Link } from 'react-router-dom';

export default function PublicHeader() {
  return (
    
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
    
          <Box sx={{ flexGrow: 1 }}>
              <div className='brand-logo' >
                <img src={Logo} className='img-fluid'/>
              </div>
          </Box> 
          <Button component={Link}   to="/login" color="inherit">Login</Button>
          <Button  component={Link} to="/register" color="inherit">Register</Button>

        </Toolbar>
      </AppBar>
    </Box>
   

    
  );
}
