import React from 'react'
import { Outlet , Navigate } from "react-router-dom";
import { getUserRole } from '../apis/Auth';

const WarehouseRoute = () =>{
    let auth = {'token':localStorage.getItem('token'),'role':getUserRole()};
    return !auth.token || auth.role==1 ? <Navigate to="/access-denied" /> : <Outlet/>;
            
      
    
}

export default WarehouseRoute;