import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import FolderIcon from '@mui/icons-material/Folder';
import moment from 'moment/moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppBar, Backdrop, Button, Checkbox, CircularProgress, Dialog, Grid, IconButton, Pagination, TableContainer, TextareaAutosize, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteCompany } from '../../apis/Company';
import { ToastContainer, toast } from 'react-toastify';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { deleteXml, getXmlContent, processBulkXml, processXml, saveXmlContent } from '../../apis/Xml';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { getUserRole } from '../../apis/Auth';
import { CheckBox } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'; 

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function XmlsTable(props) {

    const [open, setOpen] = React.useState(false);

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [xmlcontent,setxmlcontent] = useState("");
    const [xmlID,setXmlID] = useState(0);
    const [xml_ids,set_xml_ids] = useState([]);
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const handleSaveXml =()=>{
        setLoading(true);
            let payload = {
                content : xmlcontent,
                id:xmlID
            };
            saveXmlContent(payload)
            .then(data=>{
                setLoading(false);
                if(data.success){
                    setOpen(false);
                    toast.success(data.message);
                }else{
                    toast.error(data.message);
                }
            })

        
    }
  
    const  handleContentChange=(e)=>{
        setxmlcontent(e.target.value);
    }

    const processInBulk=()=>{
        let text = xml_ids.join(",");
       
        setLoading(true); 
        let data={
            xml_ids:text
        }
        processBulkXml(data)
        .then(data=>{
            if(data.success){
                toast.success(data.data.message);
                window.location.reload();
            }else{
                toast.error(data.data.message);
            
            }
            setLoading(false); 


        })
    }

    const [loading,setLoading] = useState(false);
    const xmls = props.xmls;
    const xmls_data = props.xmls_data;
    const page = props.page;
    const [check_all,set_check_all] = useState(true);
    const checkAll=(e)=>{
        let  arrChecked = [];

        if(check_all){
             set_check_all(false);
        }else{
            set_check_all(true);
        }

        var group = document.querySelectorAll('[name="xml_id[]"]');
        
        group.forEach(item=>{
            if(check_all){
            item.checked= true; 
            arrChecked.push(item.value)
        
            }else{
            
                item.checked= false; 
               
            
            }
        })
        set_xml_ids(arrChecked);
       
        
    }
    const handleCheck=(e)=>{
        const array =xml_ids;



        const index = array.indexOf(e.target.value);
        if (index > -1) { // only splice array when item is found
            array.splice(index, 1); // 2nd parameter means remove one item only
        }else{
            array.push(e.target.value);
        }
        set_xml_ids(array);
        console.log(xml_ids);

    }

    return (

        <React.Fragment >
             <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit XML
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSaveXml}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <TextareaAutosize
                aria-label="minimum height"
                minRows={500}
                value={xmlcontent}
                onChange={handleContentChange}
                placeholder="Minimum 3 rows"
                style={{ width: "100%" }}
                />
      
         
      </Dialog>
                <ToastContainer/>
            
            
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                XMLS 
            </Typography>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                        {
                            getUserRole()==0 && (
                            <>
                            <TableCell>
                            <Grid container rowSpacing={4} alignItems="center" columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                             <Grid item xs={6} sm={6} md={6} >
                             <input type="checkbox" id="xml_all_check" onChange={checkAll} />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                            <IconButton onClick={processInBulk}>
                                            <AccountTreeTwoToneIcon color="primary"/>
                                    </IconButton>
                            </Grid>
                            </Grid>
                                
                            
                             </TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>File Path</TableCell>
                            <TableCell>File Name</TableCell>
                            <TableCell>Processed</TableCell>
                            <TableCell>Date Created</TableCell>
                            <TableCell>Process XML</TableCell>
                            <TableCell></TableCell>
                            </>
                            )

                        }
                        {
                            getUserRole()==1 && (
                            <>
                            
                            <TableCell>ID</TableCell>
                            {/* <TableCell>File Path</TableCell> */}
                            <TableCell>File Name</TableCell>
                            <TableCell>Processed</TableCell>
                            <TableCell>Date Created</TableCell>
                            <TableCell>Process XML</TableCell>
                            <TableCell></TableCell>
                            </>
                            )

                        }
                        


                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {getUserRole() == 0 && xmls.map((row) => (
                        
                            <TableRow key={row.id}>
                                <TableCell>{!row.processed && (<input type="checkbox" value={row.id} onClick={handleCheck} name="xml_id[]" />)}</TableCell>

                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.path}</TableCell>
                                <TableCell>{row.name ?? 'NA'}</TableCell>
                                <TableCell>{row.processed ? <CheckCircleIcon color='success'/> : <CancelIcon color='error'/>}</TableCell>
                              
                                <TableCell>{moment(row.created_at).format("DD/MM/YYYY hh:mm:ss a")}</TableCell>
                                <TableCell>
                                    {!row.processed ? (
                                        <IconButton  onClick={()=>{
                                            setLoading(true);
                                            let data = {id:row.id}
                                            processXml(JSON.stringify(data))
                                            .then((data)=>{
                                                setLoading(false);
                                                if(data.success){
                                                    toast.success(data.message);

                                                }else{
                                                    toast.error(data.message);


                                                }
                                            })

                                        }}>
                                            <AccountTreeTwoToneIcon color="primary"/>
                                    </IconButton>
                                    ):''}
                                    
                                </TableCell>
                                {/* <TableCell>{ row.processed ? (<CheckCircleRoundedIcon/>):''}</TableCell> */}
                                <TableCell>
                         
                                    <IconButton onClick={function(){
                                        setLoading(true);
                                        getXmlContent(row.id)
                                        .then(data=>{
                                            setLoading(false);
                                            if(data.success){

                                            // console.log(data.content);
                                            setxmlcontent(data.data.content);
                                            setXmlID(row.id);
                                            setOpen(true);
                                            }else{
                                                toast.error(data.message);
                                            }
                                        })
                                    }}>
                                        <EditIcon/>
                                    </IconButton>
                                   <IconButton onClick={()=>{
                                        let confirm_delete  =  window.confirm("Do you want to delete this record");
                                        if(confirm_delete){
                                            setLoading(true);
                                            deleteXml(row.id)
                                            .then((data)=>{
                                                setLoading(false);
                                                if(data.success){
                                                    window.location.reload();
                                                }else{
                                                    toast.error(data.message);
                                                }
                                            })
                                        }
                                    }}>
                                        <DeleteIcon color="warning" />
                                    </IconButton>
                                   
                             

                                </TableCell>



                            </TableRow>
                        ))}



{getUserRole() == 1 && xmls.map((row) => (
                        
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            {/* <TableCell>{row.path}</TableCell> */}
                            <TableCell>{row.name ?? 'NA'}</TableCell>
                            <TableCell>{row.processed ? <CheckCircleIcon color='success'/> : <CancelIcon color='error'/>}</TableCell>
                          
                            <TableCell>{moment(row.created_at).format("DD/MM/YYYY hh:mm:ss a")}</TableCell>
                            <TableCell>
                                {!row.processed ? (
                                    <IconButton  onClick={()=>{
                                        setLoading(true);
                                        let data = {id:row.id}
                                        processXml(JSON.stringify(data))
                                        .then((data)=>{
                                            setLoading(false);
                                            if(data.success){
                                                toast.success(data.message);

                                            }else{
                                                toast.error(data.message);


                                            }
                                        })

                                    }}>
                                        <AccountTreeTwoToneIcon color="primary"/>
                                </IconButton>
                                ):''}
                                
                            </TableCell>
                            {/* <TableCell>{ row.processed ? (<CheckCircleRoundedIcon/>):''}</TableCell> */}
                            <TableCell>
                         
                         <IconButton onClick={function(){
                             setLoading(true);
                             getXmlContent(row.id)
                             .then(data=>{
                                 setLoading(false);
                                 if(data.success){

                                 // console.log(data.content);
                                 setxmlcontent(data.data.content);
                                 setXmlID(row.id);
                                 setOpen(true);
                                 }else{
                                     toast.error(data.message);
                                 }
                             })
                         }}>
                             <EditIcon/>
                         </IconButton>
                    
                        
                  

                     </TableCell>



                        </TableRow>
                    ))}
                      
                    </TableBody>
                    
                </Table>
                <Grid
                mt={3}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center" > 
                <Grid item >
                <Pagination
                  count= {xmls_data.last_page}
                  page={page}
                  onChange={(event, pageNumber) => props.handleChangePage(event, pageNumber)}

                 color="primary" />
                </Grid>
                </Grid>
            </TableContainer>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}
