import {base_url} from './baseurl';

export function getFtpsAll(){
    
    
    return fetch(base_url+'ftp',{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export function getFtps(id){
    
    
    return fetch(base_url+'ftpByCompany/'+id,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export function saveFtp(data){

    
    return fetch(base_url+'ftp',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:data

      
   })
   .then(res=>res.json())
  
}

export function updateFtp(data,id){

    
    return fetch(base_url+'ftp/'+id,{
       method:'PUT',
       headers:{
        //    "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:data

      
   })
   .then(res=>res.json())
  
}
export function deleteFtp(id){

    
    return fetch(base_url+'ftp/'+id,{
       method:'DELETE',
       headers:{
        //    "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       }

      
   })
   .then(res=>res.json())
  
}



