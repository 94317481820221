import {  Backdrop, CircularProgress, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { getCompanies } from '../../apis/Company';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CompaniesTable from '../../components/tables/CompaniesTable';
import WarehouseUserTable from '../../components/tables/WarehouseUserTable';

const WarehouseUser = (props) => {

    
    const [loading,setLoading] = useState(false);
    const [page,setpage] = useState(1);


    const handleChangePage=(event,value)=>{
       
        setLoading(true);
        
        
    }
    const navigate=  useNavigate();
    
 
    return (
        <DashboardContent title="Your Companies">
            <Paper elevation={2} >
                <Box p={2}>
                 <WarehouseUserTable/>
                </Box>
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
           
        </DashboardContent>
    );
}

export default WarehouseUser;