import { getUserCompany, getUserRole } from './Auth';
import {base_url} from './baseurl';



export function getJson(page,id,data=""){
    let company_id = id;
    if(getUserRole() == 3){
        company_id = getUserCompany(); 
    }
    
    return fetch(base_url+'json/'+company_id+'?page='+page+'&'+new URLSearchParams(data),{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export function saveJson(data){

    
    return fetch(base_url+'json',{
       method:'POST',
       headers:{
        //    "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:data

      
   })
   .then(res=>res.json())
  
}
export function deleteXml(id){

    
    return fetch(base_url+'json/'+id,{
       method:'DELETE',
       headers:{
        //    "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       }

      
   })
   .then(res=>res.json())
  
}

export function processJson(data){
    
    
    return fetch(base_url+'processJson',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
       body:data
   })
   .then(res=>res.json())
  
}



export function uploadXmlToFTP(data){
    
    
    return fetch(base_url+'uploadJson',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
       body:data
   })
   .then(res=>res.json())
  
}

// export function getFilesFromFtp(data){
    
    
//     return fetch(base_url+'getFilesFromFtp',{
//        method:'POST',
//        headers:{
//            "Content-Type":"application/json",
//            "Authorization":localStorage.getItem("token"),
           
//         //    "X-Requested-With": "XMLHttpRequest",
//        },
//        body:JSON.stringify(data)
//    })
//    .then(res=>res.json())
  
// }


// export function getFulfillables(data){
    
    
//     return fetch(base_url+'getFulfillableXml',{
//        method:'POST',
//        headers:{
//            "Content-Type":"application/json",
//            "Authorization":localStorage.getItem("token"),
           
//         //    "X-Requested-With": "XMLHttpRequest",
//        },
//        body:JSON.stringify(data)
//    })
//    .then(res=>res.json())
  
// }




export function getJsonContent(id){

    
    return fetch(base_url+'getjsoncontent/'+id,{
       method:'GET',
       headers:{
        //    "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       }

      
   })
   .then(res=>res.json())
  
}


export function saveJsonContent(data){

    // //console.log(data)
    return fetch(base_url+'savejsoncontent',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:JSON.stringify(data)

      
   })
   .then(res=>res.json())
  
}

export function processBulkJson(data){

    // //console.log(data)
    return fetch(base_url+'process-bulk-jsons',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:JSON.stringify(data)

      
   })
   .then(res=>res.json())
  
}
